import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useCurrentUser } from "services/user";
import Select from "components/Controls/Select";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useIsSyncing } from "services";
import { useAcademicYear } from "services/providers/AcademicYearProvider";
import { useNavigate } from "react-router-dom";

const Page = function Page({
  className,
  title,
  onAcademicYearChange,
  children,
}: {
  className?: string;
  title?: React.ReactNode;
  onAcademicYearChange?: string;
  children: React.ReactNode;
}) {
  const user = useCurrentUser();
  const isSyncing = useIsSyncing();
  const { options, selected, select } = useAcademicYear();
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 w-full h-full flex flex-col print:bg-white absolute inset-0">
      <div className="h-16 p-3 bg-white border-b border-gray-300 flex flex-row items-center z-10 print:hidden">
        <div className="flex-auto text-xl text-black">{title}</div>
        <Select
          value={selected}
          options={options}
          name={"year"}
          label={"Academic Year"}
          onChange={(value) => {
            if (onAcademicYearChange !== undefined) {
              navigate(onAcademicYearChange);
            }
            select(value);
          }}
          spacing={0}
          row
        />
        {isSyncing && (
          <div className="ml-3 text-2xl text-sky-700 animate-pulse">
            <MdOutlineCloudUpload />
          </div>
        )}
        <div className="ml-3">
          {user.profileImage && (
            <img
              src={user.profileImage}
              alt="your profile"
              className="h-10 w-10 border-2 border-gray-300 bg-gray-200 rounded-full"
            />
          )}
        </div>
      </div>
      <div
        className={classNames(
          "flex-1 overflow-hidden print:overflow-visible",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

Page.defaultProps = {
  className: null,
};

Page.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Page;
