import { useMemo } from "react";
import { TermData, TermDataResolved } from "types";
import { useCachedSchoolDocument } from "services/providers/FirebaseCache";
import { useResolveTerm } from "services/hooks/useResolveTerm";

const useTerms = function useTerms(
  academicYear: string,
  yearGroup: string
): [TermDataResolved[], boolean] {
  const resolveTerm = useResolveTerm();
  const [termOne, termOneLoading] = useCachedSchoolDocument<TermData>(
    `terms/${academicYear}-${yearGroup}-1`
  );
  const [termTwo, termTwoLoading] = useCachedSchoolDocument<TermData>(
    `terms/${academicYear}-${yearGroup}-2`
  );
  const [termThree, termThreeLoading] = useCachedSchoolDocument<TermData>(
    `terms/${academicYear}-${yearGroup}-3`
  );
  const isLoading = useMemo(
    () => termOneLoading || termTwoLoading || termThreeLoading,
    [termOneLoading, termTwoLoading, termThreeLoading]
  );
  return useMemo(
    () => [
      [
        resolveTerm(yearGroup, 1, termOne),
        resolveTerm(yearGroup, 2, termTwo),
        resolveTerm(yearGroup, 3, termThree),
      ],
      isLoading,
    ],
    [termOne, termTwo, termThree, isLoading, resolveTerm, yearGroup]
  );
};

export { useTerms };
