import React from "react";
import { YearGroup } from "services";
import Label from "components/Controls/Label";
import classNames from "classnames";

interface YearGroupSelectOptionProps {
  children: React.ReactNode;
  value: YearGroup[];
  onSelect: (value: YearGroup[]) => unknown;
  selected?: boolean;
  groupSelected?: boolean;
}

const YearGroupSelectOption = function YearGroupSelectOption({
  children,
  value,
  onSelect,
  selected = false,
  groupSelected = false,
}: YearGroupSelectOptionProps) {
  return (
    <div
      className={classNames(
        "py-0.5 px-2 mx-1 rounded border cursor-pointer hover:border-sky-700 hover:bg-sky-600 hover:text-white",
        selected
          ? "border-sky-700 bg-sky-600 text-white"
          : groupSelected
          ? "border-sky-400"
          : "border-gray-400"
      )}
      onClick={() => onSelect(value)}
    >
      {children}
    </div>
  );
};

interface YearGroupSelectGroupProps {
  label: React.ReactNode;
  children: React.ReactNode;
  selected?: boolean;
}

const YearGroupSelectGroup = function YearGroupSelectGroup({
  label,
  children,
  selected = false,
}: YearGroupSelectGroupProps) {
  return (
    <div
      className={classNames(
        "py-2 px-1 m-1 rounded-lg border hover:border-sky-400 hover:bg-sky-100 group",
        selected ? "border-sky-400 bg-sky-100" : "border-gray-300 bg-white"
      )}
    >
      <div
        className={classNames(
          "px-1 text-xs font-semibold pb-1 group-hover:text-sky-900",
          selected ? "text-sky-900" : "text-gray-900"
        )}
      >
        {label}
      </div>
      <div className="flex flex-row">{children}</div>
    </div>
  );
};

interface YearGroupSelectProps {
  value: YearGroup[];
  onChange: (value: YearGroup[]) => unknown;
}

// Define years
const YEAR_ONE: YearGroup[] = ["1"];
const YEAR_TWO: YearGroup[] = ["2"];
const YEAR_THREE: YearGroup[] = ["3"];
const YEAR_FOUR: YearGroup[] = ["4"];
const YEAR_FIVE: YearGroup[] = ["5"];
const YEAR_SIX: YearGroup[] = ["6"];

// Define year groups
const ALL_YEAR_GROUPS: YearGroup[] = ["1", "2", "3", "4", "5", "6"];
const KEY_STAGE_ONE_YEAR_GROUPS: YearGroup[] = ["1", "2"];
const KEY_STAGE_TWO_YEAR_GROUPS: YearGroup[] = ["3", "4", "5", "6"];

const areArraysEqaul = (arrayOne: YearGroup[], arrayTwo: YearGroup[]) => {
  if (arrayOne.length !== arrayTwo.length) {
    return false;
  }

  // Sort the arrays
  const sortedArrayOne = arrayOne.sort();
  const sortedArrayTwo = arrayTwo.sort();

  for (let i = 0; i < arrayOne.length; i++) {
    if (sortedArrayOne[i] !== sortedArrayTwo[i]) {
      return false;
    }
  }

  return true;
};

const valueToSelection = (value: YearGroup[]) => {
  // Default value
  if (value.length === 0) {
    return ALL_YEAR_GROUPS;
  }
  // Is a single year selected?
  if (value.length === 1) {
    const yearGroup: YearGroup = value[0];
    switch (yearGroup) {
      case "1":
        return YEAR_ONE;
      case "2":
        return YEAR_TWO;
      case "3":
        return YEAR_THREE;
      case "4":
        return YEAR_FOUR;
      case "5":
        return YEAR_FIVE;
      case "6":
        return YEAR_SIX;
      default:
        return ALL_YEAR_GROUPS;
    }
  }
  // Is key stage one selected?
  if (areArraysEqaul(value, KEY_STAGE_ONE_YEAR_GROUPS)) {
    return KEY_STAGE_ONE_YEAR_GROUPS;
  }
  // Is key stage two selected?
  if (areArraysEqaul(value, KEY_STAGE_TWO_YEAR_GROUPS)) {
    return KEY_STAGE_TWO_YEAR_GROUPS;
  }
  // Otherwise use default
  return ALL_YEAR_GROUPS;
};

const ALL_KEY_STAGE_ONE_OPTIONS = [
  KEY_STAGE_ONE_YEAR_GROUPS,
  YEAR_ONE,
  YEAR_TWO,
];
const ALL_KEY_STAGE_TWO_OPTIONS = [
  KEY_STAGE_TWO_YEAR_GROUPS,
  YEAR_THREE,
  YEAR_FOUR,
  YEAR_FIVE,
  YEAR_SIX,
];
const isInOptions = (
  selection: YearGroup[],
  groups: YearGroup[][]
): boolean => {
  for (let group of groups) {
    if (group === selection) {
      return true;
    }
  }
  // Otherwise
  return false;
};

const YearGroupSelect = function YearGroupSelect({
  value,
  onChange,
}: YearGroupSelectProps) {
  const selection = valueToSelection(value);
  const isInKeyStageOne = isInOptions(selection, ALL_KEY_STAGE_ONE_OPTIONS);
  const isInKeyStageTwo = isInOptions(selection, ALL_KEY_STAGE_TWO_OPTIONS);

  return (
    <div className="px-2 pb-2">
      <Label>Year Group(s)</Label>
      <div className="flex flex-row flex-wrap -m-2 p-1">
        <YearGroupSelectGroup
          label="All"
          selected={selection === ALL_YEAR_GROUPS}
        >
          <YearGroupSelectOption
            value={ALL_YEAR_GROUPS}
            onSelect={onChange}
            selected={selection === ALL_YEAR_GROUPS}
          >
            All
          </YearGroupSelectOption>
        </YearGroupSelectGroup>
        <YearGroupSelectGroup label="Key Stage 1" selected={isInKeyStageOne}>
          <YearGroupSelectOption
            value={[...KEY_STAGE_ONE_YEAR_GROUPS]}
            onSelect={onChange}
            selected={selection === KEY_STAGE_ONE_YEAR_GROUPS}
            groupSelected={isInKeyStageOne}
          >
            All
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["1"]}
            onSelect={onChange}
            selected={selection === YEAR_ONE}
            groupSelected={isInKeyStageOne}
          >
            Year 1
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["2"]}
            onSelect={onChange}
            selected={selection === YEAR_TWO}
            groupSelected={isInKeyStageOne}
          >
            Year 2
          </YearGroupSelectOption>
        </YearGroupSelectGroup>
        <YearGroupSelectGroup label="Key Stage 2" selected={isInKeyStageTwo}>
          <YearGroupSelectOption
            value={[...KEY_STAGE_TWO_YEAR_GROUPS]}
            onSelect={onChange}
            selected={selection === KEY_STAGE_TWO_YEAR_GROUPS}
            groupSelected={isInKeyStageTwo}
          >
            All
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["3"]}
            onSelect={onChange}
            selected={selection === YEAR_THREE}
            groupSelected={isInKeyStageTwo}
          >
            Year 3
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["4"]}
            onSelect={onChange}
            selected={selection === YEAR_FOUR}
            groupSelected={isInKeyStageTwo}
          >
            Year 4
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["5"]}
            onSelect={onChange}
            selected={selection === YEAR_FIVE}
            groupSelected={isInKeyStageTwo}
          >
            Year 5
          </YearGroupSelectOption>
          <YearGroupSelectOption
            value={["6"]}
            onSelect={onChange}
            selected={selection === YEAR_SIX}
            groupSelected={isInKeyStageTwo}
          >
            Year 6
          </YearGroupSelectOption>
        </YearGroupSelectGroup>
      </div>
    </div>
  );
};

export default YearGroupSelect;
