import React from "react";
import { Dialog, PanelHeader, PanelBody, PanelFooter } from "components";
import Button from "components/Button";
import { useAllStudents } from "services/hooks/useStudents";
import { YearGroup } from "services/years";
import classNames from "classnames";
import TextBox from "components/Controls/TextBox";

const noGroupNames: string[] = [];
const useGroupNames = (year: YearGroup): string[] => {
  const [students] = useAllStudents();
  if (students) {
    return Object.keys(
      students.reduce<Record<string, boolean>>((classes, student) => {
        const className = student.classes?.[year];
        if (className) {
          classes[className] = true;
        }
        return classes;
      }, {})
    );
  }
  // Otherwise
  return noGroupNames;
};

type AssignUngroupedStudentsDialogProps = {
  students: string[];
  year: YearGroup;
  onClose: (groupName: string | null) => void;
};

const AssignUngroupedStudentsDialog: React.ComponentType<AssignUngroupedStudentsDialogProps> =
  function ImportMissingStudentsDialog({
    students,
    year,
    onClose,
  }: AssignUngroupedStudentsDialogProps) {
    const groupNames = useGroupNames(year);
    const [selectedGroup, setSelectedGroup] = React.useState<string | boolean>(
      false
    );
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedGroup(event.target.value || true);
    };
    const [newGroupName, setNewGroupName] = React.useState<string>("");
    const handleNewGroupNameChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSelectedGroup(true);
      setNewGroupName(event.target.value);
    };

    // Calculate effective group name
    const effectiveGroupName =
      selectedGroup === true
        ? newGroupName.length > 0
          ? newGroupName
          : false
        : selectedGroup;

    // Render the dialog
    return (
      <Dialog className="w-full h-full sm:w-1/2 md:w-1/3 sm:h-96 max-h-full">
        <PanelHeader>Assign to</PanelHeader>
        <PanelBody>
          <div className="px-2 pt-1 pb-2 text-sm text-gray-800 font-semibold">
            Suggested Class
          </div>
          {groupNames.map((groupName) => (
            <label
              key={groupName}
              className={classNames(
                "mx-2 mb-1 p-2 block cursor-pointer border rounded",
                "hover:bg-sky-100 hover:border-sky-400",
                {
                  "bg-sky-100 border-sky-400": selectedGroup === groupName,
                  "border-white": selectedGroup !== groupName,
                }
              )}
            >
              <input
                type="radio"
                name="group"
                value={groupName}
                checked={selectedGroup === groupName}
                onChange={handleOptionChange}
              />
              <span className="pl-2">{groupName}</span>
            </label>
          ))}
          <div className="px-2 py-2 text-sm text-gray-800 font-semibold">
            New Class
          </div>
          <div className="mx-2 px-2 pt-2">
            <input
              type="radio"
              name="group"
              value=""
              checked={selectedGroup === true}
              onChange={handleOptionChange}
            />
            <TextBox
              name="new-group"
              label="New Class Name"
              placeholder="Class Name"
              labelDisplay="title"
              className="inline-block w-1/2 ml-2"
              value={newGroupName}
              onChange={handleNewGroupNameChange}
            />
          </div>
        </PanelBody>
        <PanelFooter>
          <Button color="grey" onClick={() => onClose(null)}>
            Cancel
          </Button>
          <div className="flex-1" />
          <Button
            onClick={() => onClose(effectiveGroupName || null)}
            disabled={effectiveGroupName === false}
          >
            Assign
          </Button>
        </PanelFooter>
      </Dialog>
    );
  };

export default AssignUngroupedStudentsDialog;
