import React from "react";
import Page from "components/Page";
import Select from "components/Controls/Select";
import reports, { ReportOption } from "./reports";

const ReportsPage = function ReportsPage() {
  const [selectedReport, setSelectedReport] = React.useState<ReportOption>(
    reports[0]
  );

  const ActiveReport = selectedReport.component;

  return (
    <Page title="Reports" className="flex flex-col">
      <div className="bg-gray-200 p-2 print:hidden">
        <Select
          name="report"
          label="Report"
          options={reports}
          value={selectedReport}
          onChange={(report: ReportOption) => setSelectedReport(report)}
        />
      </div>
      {selectedReport && <ActiveReport />}
    </Page>
  );
};

export default ReportsPage;
