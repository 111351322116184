import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";

import { MdSearch } from "react-icons/md";

interface FilterSearchBoxProps {
  value: string;
  onChange: (value: string) => unknown;
  className?: string;
}

const FilterSearchBox = function FilterSearchBox({
  value,
  onChange,
  className,
}: FilterSearchBoxProps) {
  const [focused, setFocused] = useState(false);
  const open = value.length > 0 || focused;
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);
  const onFocus = useCallback(() => setFocused(true), [setFocused]);
  const onBlur = useCallback(() => setFocused(false), [setFocused]);
  const internalOnChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div
      className={classNames(
        "border border-gray-400 bg-gray-50 rounded px-2 py-1 m-0.5",
        "text-sm text-gray-500",
        "overflow-hidden transition-all",
        open ? "w-60" : "w-8",
        "cursor-text",
        className
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center w-56">
        <MdSearch className="w-3.5 h-3.5" />
        <input
          id="search-filter"
          name="search-filter"
          title="Search"
          type="text"
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={internalOnChange}
          className={classNames(
            "ml-0.5 outline-none text-black",
            open ? "w-52" : "w-1"
          )}
        />
      </div>
    </div>
  );
};

export default FilterSearchBox;
