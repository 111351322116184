import React from "react";
import classNames from "classnames";

interface TableHeaderColumnProps {
  children?: React.ReactNode;
  className?: string;
  header?: boolean;
}

const TableHeaderColumn = function TableHeaderColumn({
  className,
  children,
  header,
}: TableHeaderColumnProps) {
  return (
    <th
      className={classNames(
        className,
        "p-2 font-normal text-black border-b border-gray-400 bg-gray-100/75",
        "print:p-1 print:text-sm print:font-semibold print:border print:border-gray-700",
        {
          "border-r border-r-gray-300": header,
        }
      )}
    >
      {children}
    </th>
  );
};

export default TableHeaderColumn;
