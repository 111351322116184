import { AssessmentScore, AssessmentYear, AssessmentYears } from "types";
import years from "services/years";
import { assessmentTerms } from "types";

export const resolveAssessmentYear = (
  previousScore: AssessmentScore,
  assessmentYear?: AssessmentYear
): AssessmentYear => {
  return assessmentTerms.reduce<AssessmentYear>((currentYear, term, index) => {
    const previousTerm = assessmentTerms[index - 1];
    const previousTermScore = previousTerm
      ? currentYear[previousTerm]
      : previousScore;
    return {
      ...currentYear,
      [term]: assessmentYear?.[term] ?? previousTermScore,
    };
  }, {});
};

const getScoreFromYear = (
  year?: AssessmentYear,
  fallbackScore?: AssessmentScore
): AssessmentScore => {
  if (year && year["6"] !== undefined) {
    return year["6"];
  }
  // Otherwise
  return fallbackScore || AssessmentScore.EXS;
};

const getInitialScore = (assessmentYears?: AssessmentYears) => {
  for (let i = 0; i < years.length; i++) {
    const assessmentYear = assessmentYears?.[years[i].id];
    if (assessmentYear) {
      for (let j = 0; j < assessmentTerms.length; j++) {
        const assessmentTerm = assessmentYear[assessmentTerms[j]];
        if (assessmentTerm !== undefined) {
          return assessmentTerm;
        }
      }
    }
  }
  // Otherwise
  return AssessmentScore.EXS;
};

export const resolveAssessmentYears = (
  assessmentYears?: AssessmentYears
): Required<AssessmentYears> => {
  const initialScore = getInitialScore(assessmentYears);
  return years.reduce<AssessmentYears>((currentYears, { id }, index) => {
    const previousYearId = years[index - 1]?.id;
    const previousScore = getScoreFromYear(
      currentYears[previousYearId],
      initialScore
    );
    return {
      ...currentYears,
      [id]: resolveAssessmentYear(previousScore, assessmentYears?.[id]),
    };
  }, {}) as Required<AssessmentYears>;
};
