import React, { useCallback, useMemo } from "react";
import Select, { SelectOption, SelectProps } from "components/Controls/Select";

const GENDER_OPTIONS: SelectOption[] = [
  { id: "female", label: "Female" },
  { id: "male", label: "Male" },
];

interface GenderSelectProps
  extends Omit<SelectProps<SelectOption>, "value" | "onChange" | "options"> {
  value?: string;
  onChange: (gender: string) => void;
}

const GenderSelect = React.forwardRef<HTMLInputElement, GenderSelectProps>(
  function GenderSelect(
    { value, onChange, label = "Gender", ...props }: GenderSelectProps,
    ref
  ) {
    const selectedOption = useMemo(
      () => GENDER_OPTIONS.find(({ id }) => id === value) || null,
      [value]
    );
    const onChangeHandler = useCallback(
      (option: SelectOption) => {
        const index = GENDER_OPTIONS.indexOf(option);
        if (index > -1 && index < GENDER_OPTIONS.length) {
          return onChange(GENDER_OPTIONS[index].id);
        }
        // Otherwise
        return onChange(GENDER_OPTIONS[0].id);
      },
      [onChange]
    );
    return (
      <Select
        value={selectedOption}
        options={GENDER_OPTIONS}
        onChange={onChangeHandler}
        label={label}
        {...props}
        ref={ref}
      />
    );
  }
);

export default GenderSelect;
