import React from "react";
import classNames from "classnames";

interface ProgressBarProps {
  progress: number;
  className?: string;
}

const ProgressBar = function ProgressBar({
  progress,
  className,
}: ProgressBarProps) {
  return (
    <div
      className={classNames(
        "rounded w-full h-2 bg-gray-600 overflow-hidden",
        className
      )}
    >
      <div
        className="rounded h-2 bg-sky-600"
        style={{ width: `${(progress * 100).toFixed()}%` }}
      />
    </div>
  );
};

export default ProgressBar;
