import React from "react";
import classNames from "classnames";

interface LabelProps {
  children: React.ReactNode;
  textStyle?: string;
  className?: string;
  spacing?: 0 | 0.5 | 1 | 2;
}

const Label = function Label({
  children,
  className,
  textStyle = "text-gray-800",
  spacing = 0.5,
}: LabelProps) {
  return (
    <div
      className={classNames(
        "text-sm font-semibold",
        {
          "mb-0.5": spacing === 0.5,
          "mb-1": spacing === 1,
          "mb-2": spacing === 2,
        },
        textStyle,
        className
      )}
    >
      {children}
    </div>
  );
};

export default Label;
