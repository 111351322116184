import React from "react";
import classNames from "classnames";

interface SideBarContentProps {
  children: React.ReactNode;
  className?: string;
}

const SideBarContent = function SideBarContent({
  className,
  children,
}: SideBarContentProps) {
  return (
    <div
      className={classNames("flex-1 overflow-y-auto print:block", className)}
    >
      {children}
    </div>
  );
};

export default SideBarContent;
