import React from "react";
import { useField } from "formik";
import TextBox from "components/Controls/TextBox";

interface TextFieldProps {
  name: string;
  label: string;
  className?: string;
}

const TextField = function TextField({
  name,
  label,
  className,
}: TextFieldProps) {
  const [field] = useField(name);
  return <TextBox label={label} className={className} {...field} />;
};

export default TextField;
