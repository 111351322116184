import React, { useState } from "react";
import Button from "components/Button";
import {
  Dialog,
  GenderSelect,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from "components";
import { MdAdd } from "react-icons/md";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextBox from "components/Controls/TextBox";
import YearSelect from "components/Controls/YearSelect";
import { useYears, Year } from "services";
import { useRunSchoolTransaction } from "services/firebaseHooks";
import { StudentsData } from "types";

interface NewStudentProps {
  id: string;
  name: string;
  gender: string;
  yearGroup: Year;
}

interface AddStudentButtonProps {
  className?: string;
}

const AddStudentButton = function AddStudentButton({
  className,
}: AddStudentButtonProps) {
  const runTransaction = useRunSchoolTransaction();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const defaultYearGroup = useYears()[0];
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewStudentProps>({
    defaultValues: {
      gender: "female",
      yearGroup: defaultYearGroup,
    },
  });

  const onSubmit: SubmitHandler<NewStudentProps> = async (student) => {
    const entryYear = student.yearGroup.entryYear;
    try {
      await runTransaction(async (transaction, schoolDoc) => {
        const studentsRef = schoolDoc(`students/${entryYear}`);
        const studentsDoc = await transaction.get(studentsRef);
        if (studentsDoc.exists()) {
          const studentsData = studentsDoc.data() as StudentsData;
          // Check that a student doesn't already exist with the provided id
          if (studentsData.students) {
            if (studentsData.students[student.id]) {
              return Promise.reject(
                "A student with the provided UPN has already been added"
              );
            }
          }
        }
        // Otherwise add the student to the collection
        transaction.set(
          studentsRef,
          {
            students: {
              [student.id]: {
                name: student.name,
                gender: student.gender,
              },
            },
          },
          { merge: true }
        );
        return true;
      });
      console.log("student added okay");
      setDialogOpen(false);
    } catch (e) {
      console.error("error adding student", e);
    }
  };

  return (
    <>
      <Button
        className={className}
        variant="primary"
        onClick={() => setDialogOpen(true)}
      >
        <MdAdd className="mr-0.5" /> New Student
      </Button>
      {dialogOpen && (
        <Dialog className="w-full h-full sm:w-1/2 sm:h-96 max-h-full">
          <form
            className="w-full h-full flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <PanelHeader>New Student</PanelHeader>
            <PanelBody className="p-2">
              <TextBox
                label="UPN"
                required
                {...register("id", { required: true })}
                error={errors.id}
              />
              <TextBox
                label="Name"
                required
                {...register("name", { required: true })}
                error={errors.name}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <GenderSelect label="Gender" {...field} />
                )}
              />
              <Controller
                name="yearGroup"
                control={control}
                render={({ field }) => (
                  <YearSelect label="Current Year Group" {...field} />
                )}
              />
            </PanelBody>
            <PanelFooter className="justify-between">
              <Button
                color="grey"
                variant="secondary"
                onClick={() => {
                  setDialogOpen(false);
                  reset();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </PanelFooter>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default AddStudentButton;
