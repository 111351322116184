import React, { useCallback, useMemo, useState } from "react";
import TagsField from "components/Fields/TagsField";
import { useThreads } from "services";
import { SelectOption } from "components/Controls/Select";
import { MdSettings } from "react-icons/md";
import Button from "components/Button";
import ManageThreadsDialog from "pages/Dashboard/components/ManageThreadsDialog";

const ThreadsField = function ThreadsField() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);
  const [threads] = useThreads();
  const threadOptions: SelectOption[] = useMemo(
    () =>
      threads
        ? threads.map(({ id, label, archived }) => ({
            id,
            label,
            active: !archived,
          }))
        : [],
    [threads]
  );
  return (
    <>
      <div className="flex flex-row mb-2 items-end">
        <TagsField
          name="threads"
          label="Threads"
          options={threadOptions}
          className="flex-1"
        />
        <Button className="mb-px ml-2" onClick={openDialog}>
          <MdSettings /> Manage
        </Button>
      </div>
      {dialogOpen && <ManageThreadsDialog onDismiss={closeDialog} />}
    </>
  );
};

export default ThreadsField;
