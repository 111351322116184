import { useCurrentUser } from "./services/user";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";

const LoadedState = ({ isAuthorized }) => (
  <div className="flex flex-row h-full w-full">
    <BrowserRouter>
      <Routes>
        <Route path="/login/*" element={<LoginPage />} />
        {isAuthorized && <Route path="/app/*" element={<Dashboard />} />}
        <Route
          path="*"
          element={
            isAuthorized ? (
              <Navigate to="/app" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  </div>
);
const LoadingState = () => <div>LOADING</div>;

function App() {
  const { isLoading, isAuthorized } = useCurrentUser();
  if (isLoading) {
    return <LoadingState />;
  }
  // Otherwise
  return <LoadedState isAuthorized={isAuthorized} />;
}

export default App;
