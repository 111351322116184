import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "services/firebaseApp";
import { useCurrentUser } from "services/user";
import { createGetFastId, getDiff } from "services/utlities";

const nextId = createGetFastId();

interface FirebaseCachedDocument {
  listeners: number;
  loading: boolean;
  error: boolean;
  data: any;
  originalData: any;
  pendingData: any;
  syncId: number | undefined;
}

interface FirebaseDocumentCache {
  [path: string]: FirebaseCachedDocument;
}

interface FirebaseCachedCollection {
  listeners: number;
  loading: boolean;
  error: boolean;
  documents: any[];
}

interface FirebaseCollectionCache {
  [path: string]: FirebaseCachedCollection;
}

type ValueOrUpdater<T> = T | ((value: T) => T);

type UpdateDocument<T> = (path: string, data: ValueOrUpdater<T>) => void;

interface FirebaseCache {
  documentCache: FirebaseDocumentCache;
  collectionCache: FirebaseCollectionCache;
  subscribe: (path: string) => () => void;
  update: UpdateDocument<unknown>;
}

const cacheContext = React.createContext<FirebaseCache>({
  documentCache: {},
  collectionCache: {},
  subscribe: () => {
    throw new Error(
      "No cache found, ensure a cache is added further up the tree"
    );
  },
  update: () => {
    throw new Error(
      "No cache found, ensure a cache is added further up the tree"
    );
  },
});

interface FirebaseCacheProps {
  children: React.ReactNode;
}

const CacheProvider = cacheContext.Provider;
const FirebaseCacheProvider = function FirebaseCacheProvider({
  children,
}: FirebaseCacheProps) {
  const [documentCache, setDocumentCache] = useState<FirebaseDocumentCache>({});
  const [collectionCache, setCollectionCache] =
    useState<FirebaseCollectionCache>({});

  const subscribe = useCallback(
    (path) => {
      const isDocumentPath = path.split("/").length % 2 === 0;
      if (isDocumentPath) {
        // Add the subscription or increase the listener count
        setDocumentCache((cache) => {
          if (!cache[path]) {
            // Otherwise
            return {
              ...cache,
              [path]: {
                listeners: 1,
                subscription: onSnapshot(doc(firestore, path), (document) => {
                  setDocumentCache((current) => ({
                    ...current,
                    [path]: {
                      ...current[path],
                      data: document.exists()
                        ? { ...document.data(), id: document.id }
                        : undefined,
                      loading: false,
                      error: false,
                    },
                  }));
                }),
                loading: true,
                error: false,
              },
            };
          }
          // Otherwise
          return {
            ...cache,
            [path]: {
              ...cache[path],
              listeners: (cache[path]?.listeners || 0) + 1,
            },
          };
        });
        // Return a clean-up method that can be used to decrease the listener count
        return () => {
          setDocumentCache((current) => ({
            ...current,
            [path]: {
              ...current[path],
              listeners: current[path].listeners - 1,
            },
          }));
        };
      }
      // Otherwise, subscribe to the collection
      setCollectionCache((cache) => {
        if (!cache[path]) {
          // Otherwise
          return {
            ...cache,
            [path]: {
              listeners: 1,
              subscription: onSnapshot(
                collection(firestore, path),
                (snapshot) => {
                  const documents: any[] = [];
                  snapshot.forEach((document) => {
                    documents.push({
                      ...document.data(),
                      id: document.id,
                    });
                  });
                  setCollectionCache((current) => ({
                    ...current,
                    [path]: {
                      ...current[path],
                      documents,
                    },
                  }));
                }
              ),
            },
          };
        }
        // Otherwise
        return {
          ...cache,
          [path]: {
            ...cache[path],
            listeners: (cache[path]?.listeners || 0) + 1,
          },
        };
      });
      // Return a clean-up method that can be used to decrease the listener count
      return () => {
        setCollectionCache((current) => ({
          ...current,
          [path]: {
            ...current[path],
            listeners: current[path].listeners - 1,
          },
        }));
      };
    },
    [setDocumentCache]
  );

  const update = useCallback<UpdateDocument<unknown>>((path, data) => {
    const syncId = nextId();
    // Set the pending data immediately
    setDocumentCache((cache) => {
      const document = cache[path];
      if (document) {
        // Then start a timer to sync the data
        setTimeout(() => {
          setDocumentCache((current) => {
            const document = current[path];
            if (document.syncId === syncId) {
              const diff = getDiff(
                document.originalData,
                document.pendingData
              ) as Record<any, unknown>;
              // Send the update to firestore
              if (diff) {
                setDoc(doc(firestore, path), diff, {
                  merge: true,
                });
              } else {
                console.warn("No diff found for: ", {
                  originalData: document.originalData,
                  pendingData: document.pendingData,
                });
              }
              // And update the cache
              return {
                ...current,
                [path]: {
                  ...document,
                  data: document.pendingData,
                  originalData: undefined,
                  pendingData: undefined,
                  syncId: undefined,
                },
              };
            }
            // Otherwise
            return current;
          });
        }, 4000);
        // And apply the pending data to the document
        return {
          ...cache,
          [path]: {
            ...document,
            originalData: document.originalData || document.data,
            pendingData:
              typeof data === "function"
                ? data(document.pendingData || document.data)
                : data,
            syncId,
          },
        };
      }
      // Otherwise
      return cache;
    });
  }, []);

  const cache = useMemo<FirebaseCache>(
    () => ({
      documentCache,
      collectionCache,
      subscribe,
      update,
    }),
    [documentCache, collectionCache, subscribe, update]
  );
  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export type SetSyncDocument<T> = (
  valueOrUpdater: T | ((value: T) => T)
) => void;

const useCachedDocument = function useDocument<T>(
  path: string
): [T | undefined, boolean, boolean, SetSyncDocument<T>] {
  const { subscribe, update, documentCache } = useContext(cacheContext);
  useEffect(() => subscribe(path), [path, subscribe]);
  const setDocument = useCallback<SetSyncDocument<T>>(
    (data) => update(path, data),
    [update, path]
  );
  const document = documentCache[path];

  return useMemo(() => {
    if (document) {
      return [
        (document.pendingData || document.data) as T,
        document.loading,
        document.error,
        setDocument,
      ];
    }
    // Otherwise
    return [undefined, true, false, setDocument];
  }, [document, setDocument]);
};

const useIsSyncing = function useIsSyncing() {
  const { documentCache } = useContext(cacheContext);
  return useMemo(() => {
    return Object.values(documentCache).some(
      (document) => document.syncId !== undefined
    );
  }, [documentCache]);
};

const useCachedSchoolDocument = function useSchoolDocument<T>(
  path: string
): [T | undefined, boolean, boolean, SetSyncDocument<T>] {
  const { school } = useCurrentUser();
  return useCachedDocument(`schools/${school}/${path}`);
};

const useCachedCollection = function useCollection<T>(
  path: string
): [T[] | undefined, boolean, boolean] {
  const { subscribe, collectionCache } = useContext(cacheContext);
  useEffect(() => subscribe(path), [path, subscribe]);
  const collection = collectionCache[path];
  return useMemo(() => {
    if (collection) {
      return [
        collection.documents as T[],
        collection.loading,
        collection.error,
      ];
    }
    // Otherwise
    return [undefined, true, false];
  }, [collection]);
};

const useCachedSchoolCollection = function useCachedSchoolCollection<T>(
  path: string
): [T[] | undefined, boolean, boolean] {
  const { school } = useCurrentUser();
  return useCachedCollection<T>(`schools/${school}/${path}`);
};

export {
  FirebaseCacheProvider as FirebaseCache,
  useCachedSchoolDocument,
  useCachedDocument,
  useCachedCollection,
  useCachedSchoolCollection,
  useIsSyncing,
};
