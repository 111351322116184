import { useCallback } from "react";
import { WeekData, WeekDataResolved } from "types";
import { useResolveObjectives } from "services/hooks/useResolveObjectives";
import { useResolveThreads } from "services/hooks/useResolveThreads";

const EMPTY_WEEK = {
  title: "",
  threads: [],
  objectives: [],
  resolvedObjectives: [],
  resolvedThreads: [],
};

export const useResolveWeek = () => {
  const resolveObjectives = useResolveObjectives();
  const resolveThreads = useResolveThreads();
  return useCallback(
    (week: WeekData): WeekDataResolved => {
      if (week) {
        return {
          ...week,
          resolvedObjectives: resolveObjectives(week.objectives),
          resolvedThreads: resolveThreads(week.threads),
        };
      }
      // Otherwise
      return EMPTY_WEEK;
    },
    [resolveObjectives, resolveThreads]
  );
};
