import * as XLSX from "xlsx";
import { WorkSheet } from "xlsx";

type HeaderMap<T extends Record<string, string | number>> = {
  [Key in keyof T]: string[];
};

type ResolvedHeaderMap<T extends Record<string, string | number>> = {
  [Key in keyof T]: string;
};

const resolveHeaderIndex = (
  potentialHeaders: string[],
  availableHeaders: string[]
): number => {
  for (let i = 0; i < potentialHeaders.length; i++) {
    const potentialHeader = `${potentialHeaders[i]}`.toLowerCase();
    const headerIndex = availableHeaders.indexOf(potentialHeader);
    if (headerIndex > -1) {
      return headerIndex;
    }
  }
  // Otherwise
  return -1;
};

const resolveHeadersFromRow = <T extends Record<string, string | number>>(
  headers: HeaderMap<T>,
  row: (string | number)[]
): ResolvedHeaderMap<T> => {
  const resolvedHeaders: Partial<ResolvedHeaderMap<T>> = {};
  const lowerCaseAvailableHeaders = row.map((header) =>
    `${header}`.toLowerCase()
  );
  Object.entries(headers).forEach(
    ([header, potentialHeaders]: [keyof T, string[]]) => {
      const headerIndex = resolveHeaderIndex(
        potentialHeaders,
        lowerCaseAvailableHeaders
      );
      if (headerIndex > -1) {
        resolvedHeaders[header] = `${row[headerIndex]}`;
      }
    }
  );
  return resolvedHeaders as ResolvedHeaderMap<T>;
};

const resolveHeaders = <T extends Record<string, string | number>>(
  headers: HeaderMap<T>,
  rows: (string | number)[][]
): [ResolvedHeaderMap<T>, number] => {
  let bestHeaders: ResolvedHeaderMap<T> = {} as ResolvedHeaderMap<T>;
  let bestHeaderCount = 0;
  let bestHeaderIndex = 0;
  // Find the first row that has all the headers, or the row with the most headers
  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];
    const resolvedHeaders = resolveHeadersFromRow(headers, row);
    const resolvedHeaderCount = Object.keys(resolvedHeaders).length;
    if (resolvedHeaderCount === Object.keys(headers).length) {
      return [resolvedHeaders, i];
    }
    // Otherwise
    if (resolvedHeaderCount > bestHeaderCount) {
      bestHeaders = resolvedHeaders;
      bestHeaderCount = resolvedHeaderCount;
      bestHeaderIndex = i;
    }
  }
  // Otherwise
  if (bestHeaderCount > 0) {
    return [bestHeaders, bestHeaderIndex];
  }
  // Otherwise
  throw new Error("Could not resolve headers");
};

const parseSheet = <T extends Record<string, string | number>>(
  sheet: WorkSheet,
  headerMap: HeaderMap<T>
): Partial<T>[] => {
  // Read the sheet as an array of arrays
  const importedData: (string | number)[][] = XLSX.utils.sheet_to_json(sheet, {
    header: 1,
  });
  // Sanitize the data by finding the most common column sizes
  const commonColumnCounts = Object.entries(
    importedData
      .filter((row) => {
        if (row.length > 1) {
          return true;
        }
        // Otherwise, remove the row
        return false;
      })
      .map((row) => row.length)
      .reduce<{ [key: string]: number }>(
        (columns, count) => ({
          ...columns,
          [count]: columns[count] ? columns[count] + 1 : 1,
        }),
        {}
      )
  ).reduce<number[]>((commonColumns, [columnSize, occurrences]) => {
    if (occurrences > 1) {
      return [...commonColumns, parseInt(columnSize)];
    }
    return commonColumns;
  }, []);
  // And retain only those rows that match the most common column sizes
  const sanitizedData = importedData.filter(
    (row) => commonColumnCounts.indexOf(row.length) > -1
  );
  // And resolve the headers
  const [resolvedHeaders, headerIndex] = resolveHeaders(
    headerMap,
    sanitizedData
  );
  // Then rebuild the data with the header row at the top
  const data = [
    sanitizedData[headerIndex],
    ...sanitizedData.slice(0, headerIndex),
    ...sanitizedData.slice(headerIndex + 1),
  ];
  // Then convert the data to a json object
  const jsonData: Record<string, string | number | Date>[] =
    XLSX.utils.sheet_to_json<Record<string, string | number | Date>>(
      XLSX.utils.aoa_to_sheet(data, { cellDates: true })
    );
  // And return the data with the resolved headers
  return jsonData.map((row) => {
    const newRow: Partial<T> = {};
    Object.entries(resolvedHeaders).forEach(
      ([key, header]: [keyof T, string]) => {
        const value: any = row[header];
        // If the value is a date
        if (value instanceof Date) {
          // Convert it to a string
          newRow[key] = value.toISOString() as T[keyof T];
        } else {
          newRow[key] = row[header] as T[keyof T];
        }
      }
    );
    return newRow;
  });
};

const readSheet = async <T extends Record<string, string | number>>(
  file: File,
  headerMap: HeaderMap<T>
): Promise<Partial<T>[]> => {
  // Read the file as an ArrayBuffer
  const data = await file.arrayBuffer();
  // Convert the data to a workbook using the XLSX library
  const workbook = XLSX.read(data, { cellDates: true });
  // Parse the first sheet of the workbook
  return parseSheet(workbook.Sheets[workbook.SheetNames[0]], headerMap);
};

export const readSheets = async <T extends Record<string, string | number>>(
  file: File,
  sheetNames: string[],
  headerMap: HeaderMap<T>
): Promise<Record<string, Partial<T>[]>> => {
  // Read the file as an ArrayBuffer
  const data = await file.arrayBuffer();
  // Convert the data to a workbook using the XLSX library
  const workbook = XLSX.read(data, { cellDates: true });
  // Then parse each required sheet
  return sheetNames.reduce<Record<string, Partial<T>[]>>(
    (results, sheetName) => {
      const realSheetName = workbook.SheetNames.find(
        (name) => name.toLowerCase() === sheetName.toLowerCase()
      );
      if (realSheetName) {
        return {
          ...results,
          [sheetName]: parseSheet(workbook.Sheets[realSheetName], headerMap),
        };
      }
      // Otherwise
      return results;
    },
    {}
  );
};

export default readSheet;
