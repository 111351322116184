import React from "react";
import {
  AssessmentScore,
  StudentWithAssessments,
  StudentWithProgress,
  Subject,
} from "types";
import { getAssessmentTermScore } from "services/utlities/getAssessmentTermScore";
import { Panel, PanelBody, PanelHeader } from "components";
import Label from "components/Controls/Label";
import { MdNorthEast, MdSouthEast } from "react-icons/md";
import classNames from "classnames";
import { assessmentScoreName } from "services/utlities/assessmentScoreName";
import _ from "lodash";

type StudentsByGrade = {
  [from in AssessmentScore]?: {
    [to in AssessmentScore]?: number;
  };
};

interface StudentProgressChipsProps {
  studentsByGrade: StudentsByGrade;
  totalStudents: number;
  color: "red" | "green" | "sky";
}

const StudentProgressChips = ({
  studentsByGrade,
  totalStudents,
  color,
}: StudentProgressChipsProps) => {
  const grades = Object.keys(studentsByGrade) as unknown as AssessmentScore[];
  return (
    <div className="flex flex-wrap pt-1 pb-2">
      {grades.map((from) => {
        const toGrades = Object.keys(
          studentsByGrade?.[from] || {}
        ) as unknown as AssessmentScore[];
        return toGrades.map((to) => {
          const count = studentsByGrade?.[from]?.[to];
          return (
            <div
              className={classNames("text-xs rounded px-1 py-0.5 mr-1", {
                "bg-red-100 border border-red-200": color === "red",
                "bg-green-100 border border-green-200": color === "green",
                "bg-sky-100 border border-sky-200": color === "sky",
              })}
            >
              {from !== to && (
                <>
                  {assessmentScoreName(from)}
                  {to < from ? (
                    <MdSouthEast className="inline-block text-xs mb-1" />
                  ) : (
                    <MdNorthEast className="inline-block text-xs mb-1" />
                  )}
                </>
              )}
              {assessmentScoreName(to)}: {count} (
              {Math.round(((count || 0) / totalStudents) * 100)}%)
            </div>
            /*<div key={`${from}-${to}`} className="">
              <div className="flex flex-col items-center justify-center">
                <div className="text-xs">{assessmentScoreName(from)}</div>
                <div className="text-xs">
                  <MdArrowForward />
                </div>
                <div className="text-xs">{assessmentScoreName(to)}</div>
                <div className="text-xs">{count}</div>
              </div>
            </div>*/
          );
        });
      })}
    </div>
  );
};

interface StudentProgressListHeaderProps {
  students: StudentWithProgress[];
  totalStudents: number;
  label: string;
  color: "red" | "green" | "sky";
  className?: string;
}

const StudentProgressListHeader = ({
  students,
  totalStudents,
  label,
  color,
  className,
}: StudentProgressListHeaderProps) => {
  const studentsByGrade = students.reduce<StudentsByGrade>(
    (current, { previousScore, currentScore }) => ({
      ...current,
      [previousScore]: {
        ...current[previousScore],
        [currentScore]: (current[previousScore]?.[currentScore] || 0) + 1,
      },
    }),
    {}
  );
  const percentage = Math.round((students.length / totalStudents) * 100);
  return (
    <div className={classNames("p-2", className)}>
      <Label
        textStyle={classNames({
          "text-red-800": color === "red",
          "text-green-800": color === "green",
          "text-sky-800": color === "sky",
        })}
        className="flex flex-row"
      >
        <div className="flex-1">{label}</div>
        {percentage > 0 && <div>{percentage}%</div>}
      </Label>
      <StudentProgressChips
        studentsByGrade={studentsByGrade}
        totalStudents={totalStudents}
        color={color}
      />
    </div>
  );
};

interface StudentProgressListProps {
  students: StudentWithProgress[];
  label: string;
  color: "red" | "green" | "sky";
  className?: string;
}

const StudentProgressList = ({
  students,
  color,
  className,
}: StudentProgressListProps) => {
  const groupedStudents = _.groupBy(
    students.map((student) => ({
      ...student,
      group: `${student.previousScore}-${student.currentScore}`,
    })),
    "group"
  );
  return (
    <div className={classNames("p-2", className)}>
      {students.length > 0 ? (
        Object.entries(groupedStudents).map(
          ([score, studentsInGroup], index) => {
            const [previousScore, currentScore] = score.split(
              "-"
            ) as unknown as [AssessmentScore, AssessmentScore];
            return (
              <div
                key={score}
                data-score={score}
                className={classNames(
                  "w-full flex flex-row items-start rounded  mb-2",
                  {
                    "border-red-700": color === "red",
                    "border-green-700": color === "green",
                    "border-sky-700": color === "sky",
                  }
                )}
              >
                <div className="flex-1">
                  {studentsInGroup.map((student, index) => (
                    <div
                      key={student.id}
                      className="px-1 py-0.5 m-1 inline-block"
                    >
                      {student.name}
                      {index < studentsInGroup.length - 1 && ","}
                    </div>
                  ))}
                </div>
                <div
                  className={classNames("text-sm rounded px-1 py-0.5 m-1", {
                    "bg-red-200": color === "red",
                    "bg-green-200": color === "green",
                    "bg-sky-200": color === "sky",
                  })}
                >
                  {currentScore !== previousScore && (
                    <>
                      {assessmentScoreName(previousScore)}
                      {currentScore < previousScore ? (
                        <MdSouthEast className="inline-block text-xs mb-1" />
                      ) : (
                        <MdNorthEast className="inline-block text-xs mb-1" />
                      )}
                    </>
                  )}
                  {assessmentScoreName(currentScore)}
                </div>
              </div>
            );
          }
        )
      ) : (
        <div className="py-1 text-gray-600">No students</div>
      )}
    </div>
  );
  // Old style
  /*return (
    <div className={classNames("p-2", className)}>
      {students.length > 0 ? (
        students.map((student) => (
          <div key={student.id} className="w-full py-1 flex flex-row">
            <div className="flex-1">{student.name}</div>
            <div
              className={classNames("text-sm rounded px-1 py-0.5", {
                "bg-red-200": color === "red",
                "bg-green-200": color === "green",
                "bg-sky-200": color === "sky",
              })}
            >
              {student.currentScore !== student.previousScore && (
                <>
                  {assessmentScoreName(student.previousScore)}
                  {student.currentScore < student.previousScore ? (
                    <MdSouthEast className="inline-block text-xs mb-1" />
                  ) : (
                    <MdNorthEast className="inline-block text-xs mb-1" />
                  )}
                </>
              )}
              {assessmentScoreName(student.currentScore)}
            </div>
          </div>
        ))
      ) : (
        <div className="py-1 text-gray-600">No students</div>
      )}
    </div>
  );*/
};

interface StudentProgressListsProps {
  improvedStudents: StudentWithProgress[];
  maintainedStudents: StudentWithProgress[];
  declinedStudents: StudentWithProgress[];
}

const StudentProgressLists = ({
  improvedStudents,
  maintainedStudents,
  declinedStudents,
}: StudentProgressListsProps) => {
  const totalStudents =
    improvedStudents.length +
    maintainedStudents.length +
    declinedStudents.length;
  return (
    <div className="xs:grid xs:grid-cols-3 xs:border-t border-gray-300 print:border-x print:border-b print:border-gray-600">
      <StudentProgressListHeader
        students={improvedStudents}
        totalStudents={totalStudents}
        label="Improved"
        color="sky"
        className={classNames(
          "xs:border-r border-b border-t xs:border-t-0 border-gray-300 print:border-gray-600",
          {
            "hidden xs:block": improvedStudents.length === 0,
          }
        )}
      />
      <StudentProgressList
        students={improvedStudents}
        label="Improved"
        color="sky"
        className={classNames(
          "xs:order-last xs:border-r border-gray-300 print:border-gray-600",
          {
            "hidden xs:block": improvedStudents.length === 0,
          }
        )}
      />
      <StudentProgressListHeader
        students={maintainedStudents}
        totalStudents={totalStudents}
        label="Maintained"
        color="green"
        className={classNames(
          "xs:border-r border-b border-t xs:border-t-0 border-gray-300 print:border-gray-600",
          {
            "hidden xs:block": maintainedStudents.length === 0,
          }
        )}
      />
      <StudentProgressList
        students={maintainedStudents}
        label="Maintained"
        color="green"
        className={classNames(
          "xs:order-last xs:border-r border-gray-300 print:border-gray-600",
          {
            "hidden xs:block": maintainedStudents.length === 0,
          }
        )}
      />
      <StudentProgressListHeader
        students={declinedStudents}
        totalStudents={totalStudents}
        label="Declined"
        color="red"
        className={classNames(
          "border-b border-t xs:border-t-0 border-gray-300 print:border-gray-600",
          {
            "hidden xs:block": declinedStudents.length === 0,
          }
        )}
      />
      <StudentProgressList
        students={declinedStudents}
        label="Declined"
        color="red"
        className={classNames("xs:order-last", {
          "hidden xs:block": declinedStudents.length === 0,
        })}
      />
    </div>
  );
};

interface StudentProgressBarProps {
  improvedStudents: StudentWithProgress[];
  maintainedStudents: StudentWithProgress[];
  declinedStudents: StudentWithProgress[];
}

const StudentProgressBar = ({
  improvedStudents,
  maintainedStudents,
  declinedStudents,
}: StudentProgressBarProps) => {
  const totalStudents =
    improvedStudents.length +
    maintainedStudents.length +
    declinedStudents.length;
  return (
    <div className="w-full flex flex-row">
      <div
        className="bg-sky-800 flex-auto text-center text-white/95 py-2"
        style={{
          width: `${(improvedStudents.length / totalStudents) * 100}%`,
        }}
      />
      <div
        className="bg-green-800 flex-auto"
        style={{
          width: `${(maintainedStudents.length / totalStudents) * 100}%`,
        }}
      />
      <div
        className="bg-red-800 flex-auto"
        style={{
          width: `${(declinedStudents.length / totalStudents) * 100}%`,
        }}
      />
    </div>
  );
};

interface SubjectProgressBreakdownProps {
  students: StudentWithAssessments[];
  subject: Subject;
  from: number;
  to: number;
  className: string;
}

const SubjectProgressBreakdown = function SubjectProgressBreakdown({
  students,
  subject,
  from,
  to,
  className,
}: SubjectProgressBreakdownProps) {
  // Get the previous and current score for each of the students
  const studentsWithProgress = students
    .map<StudentWithProgress>((student) => ({
      ...student,
      previousScore: getAssessmentTermScore(
        student.assessments,
        subject.id,
        from
      ),
      currentScore: getAssessmentTermScore(student.assessments, subject.id, to),
    }))
    .sort((a, b) => b.currentScore - a.currentScore);

  const previousTotalScore = studentsWithProgress.reduce<number>(
    (score, { previousScore }) => score + previousScore,
    0
  );
  const currentTotalScore = studentsWithProgress.reduce<number>(
    (score, { currentScore }) => score + currentScore,
    0
  );
  const changeInScore = currentTotalScore - previousTotalScore;
  const percentageChange = Math.round(
    (changeInScore / previousTotalScore) * 100
  );

  const improvedStudents = studentsWithProgress.filter(
    ({ previousScore, currentScore }) => currentScore > previousScore
  );
  const maintainedStudents = studentsWithProgress.filter(
    ({ previousScore, currentScore }) => currentScore === previousScore
  );
  const declinedStudents = studentsWithProgress.filter(
    ({ previousScore, currentScore }) => currentScore < previousScore
  );

  return (
    <Panel
      className={classNames("mb-2 text-sm", className)}
      expandable
      initiallyExpanded={true}
    >
      <PanelHeader>
        {subject.name} Progress
        <span
          className={classNames(
            "pl-1 text-sm",
            currentTotalScore < previousTotalScore
              ? "text-red-600"
              : "text-green-600"
          )}
        >
          {currentTotalScore < previousTotalScore ? (
            <MdSouthEast className="inline-block mb-0.5" />
          ) : (
            <MdNorthEast className="inline-block mb-0.5" />
          )}
          {percentageChange !== 0 ? `${percentageChange}%` : ""}
        </span>
      </PanelHeader>
      <PanelBody>
        <div className="p-2 print:hidden">
          <StudentProgressBar
            improvedStudents={improvedStudents}
            maintainedStudents={maintainedStudents}
            declinedStudents={declinedStudents}
          />
        </div>
        <StudentProgressLists
          improvedStudents={improvedStudents}
          maintainedStudents={maintainedStudents}
          declinedStudents={declinedStudents}
        />
      </PanelBody>
    </Panel>
  );
};

export default SubjectProgressBreakdown;
