import { ResolvedStudentData } from "types";
import { YearGroup } from "services/years";

export const getClassName = (
  student: ResolvedStudentData,
  yearId: YearGroup
): string => {
  if (student.classes && student.classes[yearId]) {
    return student.classes[yearId];
  }
  // Otherwise return an empty string (no class)
  return "";
};
