import React from "react";
import classNames from "classnames";

interface SideBarProps {
  children: React.ReactNode;
  className?: string;
}

const SideBar = function SideBar({ className, children }: SideBarProps) {
  return (
    <div
      className={classNames(
        "w-64 p-2 bg-gray-200 border-r border-gray-300 shadow print:hidden text-gray-800",
        className
      )}
    >
      {children}
    </div>
  );
};

export default SideBar;
