import React from "react";
import classNames from "classnames";

interface TableHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const TableHeader = function TableHeader({
  className,
  children,
}: TableHeaderProps) {
  return (
    <thead className={classNames(className)}>
      <tr className="text-left">{children}</tr>
    </thead>
  );
};

export default TableHeader;
