import React, { useCallback, useMemo, useState } from "react";
import PanelHeader from "components/Panel/PanelHeader";
import PanelBody from "components/Panel/PanelBody";
import TextBox from "components/Controls/TextBox";
import PanelFooter from "components/Panel/PanelFooter";
import Button from "components/Button";
import Dialog from "components/Dialog";
import {
  useSchoolDocumentOnce,
  useSetSchoolDocument,
} from "services/firebaseHooks";
import { v4 as uuid4 } from "uuid";
import _ from "lodash";
import { CgTrash } from "react-icons/cg";
import {
  MdCheck,
  MdEdit,
  MdOutlineArchive,
  MdOutlineUnarchive,
} from "react-icons/md";
import { Timestamp } from "firebase/firestore";
import classNames from "classnames";
import IconButton from "components/IconButton";
import ClickAwayListener from "components/ClickAwayListener";

const formatThread = (thread: string) => {
  return thread.trim().toLowerCase();
};

interface Thread {
  id: string;
  label: string;
  archived?: Timestamp | null;
  pending?: boolean;
}

interface Threads {
  threads?: {
    [key: string]: {
      label: string;
      archived?: Timestamp | null;
    };
  };
}

interface ManageThreadProps {
  thread: Thread;
  onDelete: () => void;
  onRestore: () => void;
  onUpdate: (label: string) => void;
}

const ManageThread = function ({
  thread,
  onDelete,
  onRestore,
  onUpdate,
}: ManageThreadProps) {
  const [editing, setEditing] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const onEdit = useCallback(() => {
    setNewLabel("");
    setEditing(true);
  }, [setNewLabel, setEditing]);
  const onBlur = useCallback(() => {
    setEditing(false);
  }, []);
  const onSave = useCallback(() => {
    onUpdate(newLabel);
    setEditing(false);
  }, [onUpdate, newLabel, setEditing]);
  const onChange = useCallback(
    (event) => setNewLabel(event.target.value),
    [setNewLabel]
  );
  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSave();
      }
      // Otherwise
      if (event.key === "Tab") {
        onBlur();
      }
    },
    [onSave, onBlur]
  );
  if (editing) {
    return (
      <ClickAwayListener onClickAway={onBlur}>
        <div className="capitalize border-b border-gray-300 last:border-b-0 flex flex-row group">
          <div className="p-2 pr-0 flex-1">
            <TextBox
              name={`thread-${thread.id}`}
              label="Thread Name"
              labelDisplay="title"
              spacing={0}
              onChange={onChange}
              value={newLabel}
              placeholder={thread.label}
              autoFocus={true}
              className="capitalize"
              onKeyDown={onKeyDown}
            />
          </div>
          <IconButton tooltip={"Save"} tooltipPlacement="left" onClick={onSave}>
            <MdCheck />
          </IconButton>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <div className="capitalize border-b border-gray-300 last:border-b-0 flex flex-row group">
      <div
        className={classNames("p-2 pr-0 flex-1", {
          "text-gray-500": thread.archived,
        })}
      >
        <span onClick={onEdit}>{thread.label}</span>
      </div>
      <IconButton
        tooltip="Edit"
        tooltipPlacement="left"
        onClick={onEdit}
        className="transition-opacity opacity-0 group-hover:opacity-100"
      >
        <MdEdit />
      </IconButton>
      <IconButton
        tooltip={
          thread.archived
            ? "Restore from archive"
            : thread.pending
            ? "Delete"
            : "Archive"
        }
        tooltipPlacement="left"
        onClick={thread.archived ? onRestore : onDelete}
        className="transition-opacity opacity-0 group-hover:opacity-100"
      >
        {thread.archived ? (
          <MdOutlineUnarchive />
        ) : thread.pending ? (
          <CgTrash />
        ) : (
          <MdOutlineArchive />
        )}
      </IconButton>
    </div>
  );
};

interface ManageThreadsProps {
  onDismiss: () => unknown;
  threads: Thread[];
}

const ManageThreads = function ({ threads, onDismiss }: ManageThreadsProps) {
  const [currentThreads, setCurrentThreads] = useState(threads);
  const [thread, setThread] = useState("");
  const addThread = useCallback(
    (thread: string) => {
      // Format the entered thread
      const formattedThread = formatThread(thread);
      // Then only add if the thread isn't empty
      if (formattedThread.length > 0) {
        setCurrentThreads((current) => {
          if (_.findIndex(current, { label: formattedThread }) > -1) {
            // Cannot add the thread if it already exists
            return current;
          }
          // Otherwise, append the thread to the list of existing threads
          return [
            ...current,
            {
              id: uuid4(),
              label: formattedThread,
              pending: true,
            },
          ];
        });
      }
      setThread("");
    },
    [setCurrentThreads, setThread]
  );
  const onDelete = useCallback(
    (threadId) => {
      setCurrentThreads((current) => {
        // Check the target thread exists
        const threadIndex = _.findIndex(current, { id: threadId });
        if (threadIndex > -1) {
          const thread = current[threadIndex];
          // Check if the thread is pending?
          if (thread.pending) {
            // And if so just remove the thread
            return current.filter(({ id }) => id !== threadId);
          }
          // Otherwise, archive the thread as it may still be in use
          return [
            ...current.slice(0, threadIndex),
            {
              ...current[threadIndex],
              archived: Timestamp.now(),
            },
            ...current.slice(threadIndex + 1),
          ];
        }
        // Otherwise, do not modify the threads
        return current;
      });
    },
    [setCurrentThreads]
  );
  const onRestore = useCallback(
    (threadId) => {
      setCurrentThreads((current) => {
        const threadIndex = _.findIndex(current, { id: threadId });
        if (threadIndex > -1) {
          return [
            ...current.slice(0, threadIndex),
            {
              ...current[threadIndex],
              archived: null,
            },
            ...current.slice(threadIndex + 1),
          ];
        }
        // Otherwise
        return current;
      });
    },
    [setCurrentThreads]
  );
  const onUpdate = useCallback(
    (threadId, label) => {
      // Format the entered thread
      const formattedThread = formatThread(label);
      // Then only update if the thread isn't empty
      if (formattedThread.length > 0) {
        setCurrentThreads((current) => {
          if (_.findIndex(current, { label: formattedThread }) > -1) {
            // Cannot update the thread to match an existing thread
            return current;
          }
          const threadIndex = _.findIndex(current, { id: threadId });
          if (threadIndex > -1) {
            return [
              ...current.slice(0, threadIndex),
              {
                ...current[threadIndex],
                label: formattedThread,
              },
              ...current.slice(threadIndex + 1),
            ];
          }
          // Otherwise
          return current;
        });
      }
    },
    [setCurrentThreads]
  );
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      addThread(thread);
    }
  };
  const setThreads = useSetSchoolDocument<Threads>("meta/threads");
  const onSubmit = async () => {
    await setThreads({
      threads: currentThreads.reduce(
        (current, thread) => ({
          ...current,
          [thread.id]: {
            label: thread.label,
            archived: thread.archived ? thread.archived : null,
          },
        }),
        {}
      ),
    });
    onDismiss();
  };

  const sortedThreads = _.sortBy(currentThreads, [
    ({ archived }) => (archived ? 1 : 0),
    "label",
  ]);
  const filteredThreads = _.filter(
    sortedThreads,
    ({ label }) => label.toLowerCase().indexOf(formatThread(thread)) > -1
  );
  return (
    <>
      <PanelBody className="flex flex-col">
        <div className="p-2 bg-gray-200 border-b border-gray-300">
          <div className="flex flex-row items-center">
            <TextBox
              name="thread"
              label="Thread"
              value={thread}
              onChange={(event) => setThread(event.target.value)}
              onKeyDown={onKeyDown}
              labelDisplay="title"
              placeholder="Search Threads"
              spacing={0}
              className="flex-1"
            />
            <Button className="ml-2" onClick={() => addThread(thread)}>
              Add
            </Button>
          </div>
        </div>
        <div className="flex-1">
          {sortedThreads.length > 0 ? (
            filteredThreads.length > 0 ? (
              filteredThreads.map((thread) => (
                <ManageThread
                  key={thread.id}
                  thread={thread}
                  onDelete={() => onDelete(thread.id)}
                  onRestore={() => onRestore(thread.id)}
                  onUpdate={(newLabel) => onUpdate(thread.id, newLabel)}
                />
              ))
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <div className="text-gray-700">No matching threads found</div>
              </div>
            )
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <div className="text-gray-700">
                No threads created yet, add one above
              </div>
            </div>
          )}
        </div>
      </PanelBody>
      <PanelFooter className="justify-between">
        <Button color="grey" variant="secondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button type="submit" onClick={onSubmit}>
          Save
        </Button>
      </PanelFooter>
    </>
  );
};

interface ManageThreadsDialogProps {
  onDismiss: () => unknown;
}

const ManageThreadsDialog = function ManageThreadsDialog({
  onDismiss,
}: ManageThreadsDialogProps) {
  const [threads, loading] = useSchoolDocumentOnce<Threads>("meta/threads");
  const threadsCollection = useMemo(() => {
    if (threads) {
      const threadsMap = (threads as Threads).threads;
      if (threadsMap) {
        return Object.entries(threadsMap).map(([id, { label, archived }]) => ({
          id,
          label,
          archived,
        }));
      }
    }
    // Otherwise
    return [];
  }, [threads]);
  return (
    <Dialog className="w-full h-full sm:w-1/2 sm:h-96 max-h-full">
      {loading ? (
        <div>loading</div>
      ) : (
        <>
          <PanelHeader>Manage Threads</PanelHeader>
          <ManageThreads threads={threadsCollection} onDismiss={onDismiss} />
        </>
      )}
    </Dialog>
  );
};

export default ManageThreadsDialog;
