import React from "react";
import StudentProgressReport from "./StudentProgressReport";
import StudentAttainmentReport from "./StudentAttainmentReport";
import { SelectOption } from "components/Controls/Select";

export interface ReportOption extends SelectOption {
  component: React.ComponentType;
}

export const reports: ReportOption[] = [
  {
    id: "student-attainment",
    label: "Student Attainment",
    component: StudentAttainmentReport,
  },
  {
    id: "student-progress",
    label: "Student Progress",
    component: StudentProgressReport,
  },
];

export default reports;
