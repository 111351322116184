import React, { useCallback, useMemo, useState } from "react";
import { useAllStudents } from "services/hooks/useStudents";
import { useYears } from "services/hooks/useYears";
import { ResolvedStudentData } from "types";
import {
  SideBar,
  SideBarItem,
  SideBarSection,
  SideBarSubPanel,
} from "components/SideBar";
import SkeletonSideBarItems from "components/Skeleton/SkeletonSideBarItems";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useAssessedSubjects } from "services";

interface AssessmentsSidebarProps {
  className?: string;
}

interface Year {
  id: string;
  label: string;
  classes: string[];
}

export type AssessmentSelection = {
  yearId?: string;
  className?: string;
  subjectId?: string;
};

type SetAssessmentSelectionCallback = (
  selection: AssessmentSelection
) => AssessmentSelection;

const getClassName = (student: ResolvedStudentData, yearId: string): string => {
  if (student.classes && student.classes[yearId]) {
    return student.classes[yearId];
  }
  // Otherwise return an empty string (no class)
  return "";
};

const useAssessmentSelection = (): [
  AssessmentSelection,
  (
    setAssessmentSelection: (
      currentAssessmentSelection: AssessmentSelection
    ) => AssessmentSelection
  ) => void
] => {
  const assessmentSelection = useParams<AssessmentSelection>();
  const navigate = useNavigate();
  const setAssessmentSelection = useCallback(
    (
      newAssessmentSelection:
        | AssessmentSelection
        | SetAssessmentSelectionCallback
    ) => {
      const appliedAssessmentSelection =
        typeof newAssessmentSelection === "function"
          ? newAssessmentSelection(assessmentSelection)
          : newAssessmentSelection;
      if (appliedAssessmentSelection.subjectId) {
        return navigate(
          `/app/assessments/class/${appliedAssessmentSelection.yearId}/${appliedAssessmentSelection.className}/${appliedAssessmentSelection.subjectId}`
        );
      }
      return navigate(
        `/app/assessments/class/${appliedAssessmentSelection.yearId}/${appliedAssessmentSelection.className}`
      );
    },
    [assessmentSelection, navigate]
  );
  return [assessmentSelection, setAssessmentSelection];
};

const AssessmentsSidebar = function AssessmentsSidebar({
  className,
}: AssessmentsSidebarProps) {
  const [students, studentsLoading] = useAllStudents();
  const [subjects, subjectsLoading] = useAssessedSubjects();
  const loading = studentsLoading || subjectsLoading;
  const [assessmentSelection, setAssessmentSelection] =
    useAssessmentSelection();
  const [reselecting, setReselecting] = useState(false);
  const currentYears = useYears();
  const yearClasses = useMemo<Year[]>(
    () =>
      currentYears.map(({ id, label, entryYear }) => ({
        id,
        label,
        entryYear,
        classes: students
          .reduce<string[]>((classes, student) => {
            // Only use students from the correct year
            if (student.entryYear === entryYear) {
              // Get their current class
              const className = getClassName(student, id);
              // And the class isn't already in the list
              if (!classes.includes(className)) {
                // Add the class to the list
                return [...classes, className];
              }
            }
            return classes;
          }, [])
          .sort(),
      })),
    [students, currentYears]
  );
  return (
    <SideBar className={className}>
      <SideBarSubPanel
        active={
          assessmentSelection.yearId === undefined ||
          assessmentSelection.className === undefined ||
          reselecting
        }
      >
        {yearClasses.map(({ id, label, classes }) => (
          <SideBarSection
            title={label}
            key={id}
            active={assessmentSelection.yearId === id}
          >
            {loading ? (
              <SkeletonSideBarItems items={2} />
            ) : classes.length > 0 ? (
              classes.map((className) => (
                <SideBarItem
                  key={className ? className : "_ungrouped"}
                  onClick={() => {
                    setAssessmentSelection((current) => {
                      if (
                        current.yearId === id &&
                        current.className === className
                      ) {
                        return current;
                      }
                      // Otherwise
                      return { yearId: id, className };
                    });
                    setReselecting(false);
                  }}
                  selected={
                    assessmentSelection.yearId === id &&
                    (assessmentSelection.className === className ||
                      (!assessmentSelection.className && !className))
                  }
                >
                  {className ? className : "Ungrouped"}
                </SideBarItem>
              ))
            ) : (
              <SideBarItem className="text-gray-400">No Students</SideBarItem>
            )}
          </SideBarSection>
        ))}
      </SideBarSubPanel>
      <SideBarSubPanel
        active={
          assessmentSelection.yearId !== undefined &&
          assessmentSelection.className !== undefined &&
          !reselecting
        }
      >
        <SideBarSection
          active
          title={
            <div className="flex items-center">
              <MdArrowBack className="block mr-1" />
              {assessmentSelection.className}
            </div>
          }
          onHeaderClick={() => setReselecting(true)}
        >
          <SideBarItem
            selected={assessmentSelection.subjectId === undefined}
            onClick={() =>
              setAssessmentSelection((current) => ({
                ...current,
                subjectId: undefined,
              }))
            }
          >
            Overview
          </SideBarItem>
          {subjectsLoading ? (
            <SkeletonSideBarItems items={2} />
          ) : (
            subjects?.map((subject) => (
              <SideBarItem
                key={subject.id}
                selected={assessmentSelection.subjectId === subject.id}
                onClick={() =>
                  setAssessmentSelection((current) => ({
                    ...current,
                    subjectId: subject.id,
                  }))
                }
              >
                {subject.name}
              </SideBarItem>
            ))
          )}
        </SideBarSection>
      </SideBarSubPanel>
    </SideBar>
  );
};

export default AssessmentsSidebar;
