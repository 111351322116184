import React, { useEffect, useRef } from "react";

interface ClickAwayListenerProps {
  children: React.ReactElement;
  onClickAway: () => void;
}

const ClickAwayListener = function ClickAwayListener({
  children,
  onClickAway,
}: ClickAwayListenerProps) {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    let effect = {
      alive: true,
    };
    const clickAwayListener = (event: any) => {
      if (ref.current === null || !ref.current.contains(event.target)) {
        onClickAway();
      }
    };
    setTimeout(() => {
      if (effect.alive) {
        document.body.addEventListener("click", clickAwayListener);
      }
    }, 0);
    return () => {
      effect.alive = false;
      document.body.removeEventListener("click", clickAwayListener);
    };
  }, [onClickAway]);
  return React.cloneElement(children, { ref });
};

export default ClickAwayListener;
