import React from "react";
import classNames from "classnames";

interface ToggleProps {
  name: string;
  label: string;
  checked: boolean;
  onCheck: (checked: boolean) => unknown;
  className?: string;
}

const Toggle = function Toggle({
  name,
  label,
  checked,
  onCheck,
  className,
}: ToggleProps) {
  return (
    <label
      htmlFor={name}
      className={classNames("flex items-center cursor-pointer", className)}
    >
      <span className="mr-1">{label}</span>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={(event) => {
          event.persist();
          onCheck(!checked);
        }}
      />
      <div className="relative">
        {/* Background */}
        <div className="w-9 h-5 bg-slate-500 rounded-full shadow-inner" />
        {/* Dot */}
        <div
          className={classNames(
            "dot absolute w-4 h-4 rounded-full shadow left-0.5 top-0.5 transition",
            checked ? "translate-x-full bg-sky-400" : "bg-slate-50"
          )}
        />
      </div>
    </label>
  );
};

export default Toggle;
