import React from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  useCachedSchoolDocument,
  useOpenDialog,
  useYear,
  useYears,
  Year,
} from "services";
import { useStudents } from "services/hooks/useStudents";
import { ResolvedStudentData, StudentData, StudentsData } from "types";
import { Panel, PanelBody, PanelHeader } from "components";
import CheckBox from "components/Controls/CheckBox";
import Button from "components/Button";
import AssignUngroupedStudentsDialog from "pages/Dashboard/pages/Assessments/components/AssignUngroupedStudentsDialog";

interface SuggestedGroup {
  className: string;
  index: number;
}

const getSuggestedGroup = (
  student: ResolvedStudentData,
  year: Year,
  years: Year[]
): SuggestedGroup => {
  const currentYearIndex = years.findIndex((y) => y.id === year.id);
  // Find the first group going backwards
  for (let i = currentYearIndex; i >= 0; i--) {
    const year = years[i];
    const className = student.classes?.[year.id];
    if (className) {
      return { className, index: i - currentYearIndex };
    }
  }
  // And if not then find the first group going forwards (worst case scenario)
  for (let i = currentYearIndex; i < years.length; i++) {
    const year = years[i];
    const className = student.classes?.[year.id];
    if (className) {
      return { className, index: i - currentYearIndex };
    }
  }
  // Otherwise
  return { className: "Ungrouped", index: 0 };
};

interface SuggestedGroups {
  [className: string]: {
    groupName: string;
    students: ResolvedStudentData[];
  };
}

const getSuggestedGroups = (
  students: ResolvedStudentData[],
  year: Year,
  years: Year[]
) => {
  let suggestions: number = 0;
  return students.reduce<SuggestedGroups>((groups, student) => {
    // Get the suggested group for the current student
    const suggestedGroup = getSuggestedGroup(student, year, years);
    // And if the group doesn't exist then create it
    if (!groups[suggestedGroup.className]) {
      suggestions += 1;
      return {
        ...groups,
        [suggestedGroup.className]: {
          groupName:
            suggestedGroup.index > 0
              ? `Group ${suggestions}`
              : `Previous Class: ${suggestedGroup.className}`,
          students: [student],
        },
      };
    }
    // Otherwise add the student to the group
    return {
      ...groups,
      [suggestedGroup.className]: {
        ...groups[suggestedGroup.className],
        students: [...groups[suggestedGroup.className].students, student],
      },
    };
  }, {});
};

interface UngroupedStudentsProps {
  year: Year;
  className?: string;
}

const UngroupedStudents = function UngroupedStudents({
  year,
}: UngroupedStudentsProps) {
  const years = useYears();
  const [students] = useStudents(year?.entryYear);
  const ungroupedStudents = students.filter(
    (student) => student.classes?.[year.id] === undefined
  );
  const suggestedGroups = getSuggestedGroups(ungroupedStudents, year, years);
  const [selectedStudents, setSelectedStudents] = React.useState<
    Record<string, boolean>
  >({});
  // Select student methods
  const selectStudent = (studentId: string) => {
    setSelectedStudents((current) => ({
      ...current,
      [studentId]: !current[studentId],
    }));
  };
  const isStudentSelected = (studentId: string): boolean =>
    selectedStudents[studentId];
  // Select group methods
  const selectGroup = (className: string) => {
    const group = suggestedGroups[className];
    // Check if any students are currently selected
    const selected = group.students.some((student) =>
      isStudentSelected(student.id)
    );
    // Prepare an update object from the groups students
    const update = group.students.reduce<Record<string, boolean>>(
      (current, student) => ({
        ...current,
        [student.id]: !selected,
      }),
      {}
    );
    // And update the selected state
    setSelectedStudents((current) => ({ ...current, ...update }));
  };
  const isGroupSelected = (className: string): boolean | "indeterminate" => {
    const group = suggestedGroups[className];
    // Check if any students are currently selected
    const selected = group.students.some((student) =>
      isStudentSelected(student.id)
    );
    // Check if all students are currently selected
    const allSelected = group.students.every((student) =>
      isStudentSelected(student.id)
    );
    // Return the appropriate value
    return selected ? (allSelected ? true : "indeterminate") : false;
  };
  const [, , , setStudentsMap] = useCachedSchoolDocument<StudentsData>(
    `students/${year.entryYear}`
  );
  const openDialog = useOpenDialog();
  const assignStudents = async () => {
    const selectedStudentIds = Object.entries(selectedStudents)
      .filter(([, selected]) => selected)
      .map(([id]) => id);
    const groupName = await openDialog(AssignUngroupedStudentsDialog, {
      students: selectedStudentIds,
      year: year.id,
    });
    if (groupName) {
      // Then update the students map
      setStudentsMap(({ students }) => ({
        students: {
          ...students,
          ...selectedStudentIds.reduce<Record<string, StudentData>>(
            (current, studentId) => ({
              ...current,
              [studentId]: {
                ...students[studentId],
                classes: {
                  ...students[studentId].classes,
                  [year.id]: groupName,
                },
              },
            }),
            {}
          ),
        },
      }));
      // And clear the current selection
      setSelectedStudents({});
    }
  };
  return (
    <>
      <div className="mb-2 flex flex-row items-center">
        <div className="flex-1 text-lg">
          Suggested Groupings for Year {year.id}
        </div>
        <Button onClick={assignStudents}>Assign</Button>
      </div>
      {Object.entries(suggestedGroups).map(([id, { groupName, students }]) => (
        <Panel className="mb-2" key={id}>
          <PanelHeader>
            <CheckBox
              name={id}
              label={groupName}
              checked={isGroupSelected(id)}
              onChecked={() => selectGroup(id)}
              spacing={0}
            />
          </PanelHeader>
          <PanelBody>
            <ul>
              {students.map((student) => (
                <li
                  key={student.id}
                  className="border-b border-gray-300 flex flex-row items-center"
                >
                  <CheckBox
                    name={student.id}
                    label={student.name}
                    checked={isStudentSelected(student.id)}
                    onChecked={() => selectStudent(student.id)}
                    spacing={0}
                    className="w-full p-2 hover:bg-sky-100"
                  />
                </li>
              ))}
            </ul>
          </PanelBody>
        </Panel>
      ))}
    </>
  );
};

const UngroupedStudentsWrapper = () => {
  const { yearId } = useParams();
  const year = useYear(yearId || "");
  if (year) {
    return <UngroupedStudents year={year} key={year.id} />;
  }
  // Otherwise
  return <Navigate to="/app/assessments" replace />;
};

export default UngroupedStudentsWrapper;
