import React, { useMemo, useState } from "react";
import {
  includesAll,
  useAssessedSubjects,
  usePrintOptions,
  YearGroup,
  years,
} from "services";
import Select, { SelectOption } from "components/Controls/Select";
import { AssessmentTerm, assessmentTerms, StudentWithAssessments } from "types";
import { useAllStudentsWithAssessments } from "services/hooks/useStudentsWithAssessments";
import _ from "lodash";
import { StudentWithScore } from "./types";
import StudentAttainmentBreakdown from "pages/Dashboard/pages/Reports/reports/StudentAttainmentReport/components/StudentAttainmentBreakdown";
import YearGroupSelect from "./components/YearGroupSelect";

const getStudentScore = (
  student: StudentWithAssessments,
  subjectId: string,
  term: AssessmentTerm
) => {
  console.log(
    "reading from: ",
    ["student.assessments", subjectId, student.yearGroup, term].join(".")
  );
  return _.get(student.assessments, [subjectId, student.yearGroup, term], 10);
};

interface StudentAttainmentReportViewProps {
  students: StudentWithScore[];
  yearGroups: YearGroup[];
  flags: string[];
  expectedOnly: boolean;
}

const StudentAttainmentReportView = function StudentAttainmentReportView({
  students,
  yearGroups,
  flags,
  expectedOnly,
}: StudentAttainmentReportViewProps) {
  // Display report view based on selected year group
  if (yearGroups.length === 1) {
    const yearGroup = yearGroups[0];
    const studentsForYear = students.filter(
      (student) => student.yearGroup === yearGroup
    );
    console.log("students for year: ", students);
    const classes: string[] = studentsForYear.reduce<string[]>(
      (currentClasses, { classes }) => {
        const studentsClass = _.get(classes, yearGroup);
        if (studentsClass && !currentClasses.includes(studentsClass)) {
          return [...currentClasses, studentsClass];
        }
        // Otherwise
        return currentClasses;
      },
      []
    );
    console.log("classes: ", classes);
    return (
      <div className="overflow-y-auto print:overflow-visible">
        <div className="text-xl p-2 sticky top-0 bg-gray-100">
          Year {yearGroup}
        </div>
        <div className="px-2 pb-4">
          <StudentAttainmentBreakdown
            title="Overview"
            students={students.filter(
              (student) => student.yearGroup === yearGroup
            )}
            flags={flags}
            expectedOnly={expectedOnly}
          />
        </div>
        {classes.length > 0 && (
          <>
            <div className="text-xl p-2 sticky top-0 bg-gray-100">Classes</div>
            <div className="px-2">
              {classes.map((schoolClass) => {
                const studentsForClass = studentsForYear.filter(
                  (student) =>
                    _.get(student, ["classes", yearGroup]) === schoolClass
                );
                console.log(
                  `students for class ${schoolClass}: `,
                  studentsForClass
                );
                return (
                  <StudentAttainmentBreakdown
                    key={schoolClass}
                    title={schoolClass}
                    students={studentsForClass}
                    expectedOnly={expectedOnly}
                    flags={flags}
                    color={Number(yearGroup) > 2 ? "purple" : "green"}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
  // Display report view based on selected key stage
  if (yearGroups.length === 2 && includesAll(yearGroups, ["1", "2"])) {
    return (
      <div className="overflow-y-auto print:overflow-visible">
        <div className="text-xl p-2 sticky top-0 bg-gray-100">Key Stage 1</div>
        <div className="px-2 pb-4">
          <StudentAttainmentBreakdown
            title="Overview"
            students={students.filter((student) =>
              yearGroups.includes(student.yearGroup)
            )}
            flags={flags}
            expectedOnly={expectedOnly}
          />
        </div>
        <div className="text-xl p-2 sticky top-0 bg-gray-100">Years</div>
        <div className="px-2">
          {years
            .filter((year) => Number(year.id) < 3)
            .map((year) => (
              <StudentAttainmentBreakdown
                key={year.id}
                title={year.label}
                students={students.filter(
                  (student) => student.yearGroup === year.id
                )}
                expectedOnly={expectedOnly}
                flags={flags}
                color="green"
              />
            ))}
        </div>
      </div>
    );
  }
  if (
    yearGroups.length === 4 &&
    includesAll(yearGroups, ["3", "4", "5", "6"])
  ) {
    return (
      <div className="overflow-y-auto print:overflow-visible">
        <div className="text-xl p-2 sticky top-0 bg-gray-100">Key Stage 2</div>
        <div className="px-2 pb-4">
          <StudentAttainmentBreakdown
            title="Overview"
            students={students.filter((student) =>
              yearGroups.includes(student.yearGroup)
            )}
            flags={flags}
            expectedOnly={expectedOnly}
          />
        </div>
        <div className="text-xl p-2 sticky top-0 bg-gray-100">Years</div>
        <div className="px-2">
          {years
            .filter((year) => Number(year.id) > 2)
            .map((year) => (
              <StudentAttainmentBreakdown
                key={year.id}
                title={year.label}
                students={students.filter(
                  (student) => student.yearGroup === year.id
                )}
                expectedOnly={expectedOnly}
                flags={flags}
                color="purple"
              />
            ))}
        </div>
      </div>
    );
  }
  // Otherwise
  return (
    <div className="overflow-y-auto print:overflow-visible">
      <div className="text-xl p-2 sticky top-0 bg-gray-100">Whole School</div>
      <div className="px-2 pb-4">
        <StudentAttainmentBreakdown
          title="Overview"
          students={students.filter((student) =>
            yearGroups.includes(student.yearGroup)
          )}
          flags={flags}
          expectedOnly={expectedOnly}
        />
      </div>
      <div className="text-xl p-2 sticky top-0 bg-gray-100">Key Stage 1</div>
      <div className="px-2 pb-4">
        {years
          .filter((year) => Number(year.id) < 3)
          .map((year) => (
            <StudentAttainmentBreakdown
              key={year.id}
              title={year.label}
              students={students.filter(
                (student) => student.yearGroup === year.id
              )}
              expectedOnly={expectedOnly}
              flags={flags}
              color="green"
            />
          ))}
      </div>
      <div className="text-xl p-2 sticky top-0 bg-gray-100">Key Stage 2</div>
      <div className="px-2">
        {years
          .filter((year) => Number(year.id) > 2)
          .map((year) => (
            <StudentAttainmentBreakdown
              key={year.id}
              title={year.label}
              students={students.filter(
                (student) => student.yearGroup === year.id
              )}
              expectedOnly={expectedOnly}
              flags={flags}
              color="purple"
            />
          ))}
      </div>
    </div>
  );
};

interface TermOption extends SelectOption {
  term: AssessmentTerm;
}

const termOptions: TermOption[] = assessmentTerms.map<TermOption>((term) => ({
  label: term,
  id: term,
  term: term,
}));

interface ReportOptions {
  subject: SelectOption;
  term: TermOption;
}

interface StudentAttainmentReportProps {
  subjects: SelectOption[];
}

const StudentAttainmentReport = function StudentAttainmentReport({
  subjects,
}: StudentAttainmentReportProps) {
  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    subject: subjects[0],
    term: termOptions[0],
  });
  // Extract report options
  const subjectId = reportOptions.subject.id;
  const subjectName = reportOptions.subject.label;
  const termNumber = reportOptions.term.term;
  // Selected year groups
  const [yearGroups, setYearGroups] = useState<YearGroup[]>([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
  ]);
  // Filters
  // const [filters, setFilters] = useState<FilterValue[]>([]);
  // Filter options
  // const filterOptions: FilterOptions[] = useMemo(
  //   () => [
  //     {
  //       type: "key-stage",
  //       label: "Key Stage",
  //       labelPlural: "Key Stages",
  //       options: [
  //         {
  //           id: "KS1",
  //           label: "Key Stage 1",
  //         },
  //         {
  //           id: "KS2",
  //           label: "Key Stage 2",
  //         },
  //       ],
  //     },
  //   ],
  //   []
  // );
  // Set the print header
  usePrintOptions(
    useMemo(
      () => ({
        title: `Student Attainment Report - ${subjectName} - Term ${termNumber}`,
      }),
      [subjectName, termNumber]
    )
  );
  // Load all students for the selected academic year
  const [allStudents] = useAllStudentsWithAssessments();
  // Get the score for each student (for the selected subject and term)
  const studentsWithScores = useMemo<StudentWithScore[]>(
    () =>
      allStudents.map((student) => ({
        ...student,
        score: getStudentScore(student, subjectId, termNumber),
      })),
    [allStudents, subjectId, termNumber]
  );
  // Get all current flags
  const flags = allStudents.reduce<string[]>(
    (flags, { flags: studentFlags }) => _.union(flags, studentFlags),
    []
  );
  // Display only expected or above?
  const expectedOnly = subjectId === "combined";

  console.log("students: ", studentsWithScores);

  // Display the report, with the settings above
  return (
    <>
      <div className="bg-gray-200 border-b border-gray-300 print:hidden">
        <div className="flex flex-row px-1 pb-1">
          <Select
            name="subject"
            label="Subject"
            options={subjects}
            value={reportOptions.subject}
            onChange={(subject) =>
              setReportOptions((current) => ({ ...current, subject }))
            }
            labelClassName="flex-1 mx-1"
          />
          <Select
            name="term"
            label="Term Number"
            options={termOptions}
            value={reportOptions.term}
            onChange={(termOption) =>
              setReportOptions((current) => ({ ...current, term: termOption }))
            }
            labelClassName="flex-1 mx-1"
          />
        </div>
        <YearGroupSelect value={yearGroups} onChange={setYearGroups} />
      </div>
      {/* <Filters filters={filterOptions} value={filters} onChange={setFilters} /> */}
      <StudentAttainmentReportView
        students={studentsWithScores}
        yearGroups={yearGroups}
        flags={flags}
        expectedOnly={expectedOnly}
      />
      {/* <div className="p-2 overflow-y-auto print:overflow-visible">
        <h1>Overview</h1>
        <StudentAttainmentBreakdown
          title="Overview"
          students={studentsWithScores.filter((student) =>
            yearGroups.includes(student.yearGroup)
          )}
          flags={flags}
          expectedOnly={expectedOnly}
        />
        {includesAll(yearGroups, ["1", "2"]) && (
          <>
            <h1>Key Stage 1</h1>
          </>
        )}
        {years
          .filter((year) => Number(year.id) < 3)
          .map((year) => (
            <StudentAttainmentBreakdown
              key={year.id}
              title={year.label}
              students={studentsWithScores.filter(
                (student) => student.yearGroup === year.id
              )}
              expectedOnly={subjectId === "combined"}
              flags={flags}
            />
          ))}
        <Panel
          className="print:break-inside-avoid"
          expandable
          initiallyExpanded
        >
          <PanelHeader>Key Stage 2</PanelHeader>
          <PanelBody className="p-2 print:pl-4 print:pr-0">
            <StudentAttainmentBreakdown
              title="Summary"
              students={studentsWithScores.filter(
                (student) => Number(student.yearGroup) > 2
              )}
              flags={flags}
              expectedOnly={expectedOnly}
              initiallyExpanded
            />
            {years
              .filter((year) => Number(year.id) > 2)
              .map((year) => (
                <StudentAttainmentBreakdown
                  key={year.id}
                  title={year.label}
                  students={studentsWithScores.filter(
                    (student) => student.yearGroup === year.id
                  )}
                  flags={flags}
                  expectedOnly={expectedOnly}
                />
              ))}
          </PanelBody>
        </Panel>
      </div> */}
    </>
  );
};

const StudentAttainmentReportWrapper = () => {
  const [assessedSubjects, loading] = useAssessedSubjects();
  const subjectOptions: SelectOption[] = useMemo(
    () =>
      assessedSubjects
        ? [
            {
              id: "combined",
              label: "Combined",
            },
            ...assessedSubjects.map((subject) => ({
              id: subject.id,
              label: subject.name,
            })),
          ]
        : [],
    [assessedSubjects]
  );
  if (loading || subjectOptions.length === 0) {
    return <div>Loading...</div>;
  }
  // Otherwise
  return <StudentAttainmentReport subjects={subjectOptions} />;
};

export default StudentAttainmentReportWrapper;
