import { Timestamp } from "firebase/firestore";
import { Thread } from "types";
import { useCachedSchoolDocument } from "services/providers";
import { useMemo } from "react";

interface Threads {
  threads?: {
    [key: string]: {
      label: string;
      archived?: Timestamp | null;
    };
  };
}

const useThreads: () => [Thread[] | null, boolean, boolean] = () => {
  const [threads, loading, error] =
    useCachedSchoolDocument<Threads>("meta/threads");
  return useMemo(() => {
    if (loading) {
      return [null, true, false];
    }
    // Otherwise
    if (error || !threads) {
      return [null, false, true];
    }
    return [
      Object.entries((threads as Threads).threads || {}).map(
        ([id, thread]) => ({
          id: id,
          ...thread,
        })
      ),
      false,
      false,
    ];
  }, [threads, loading, error]);
};

export { useThreads };
