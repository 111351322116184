const includesAll = <T>(collection: T[], values: T[]): boolean => {
  for (let value of values) {
    if (!collection.includes(value)) {
      return false;
    }
  }
  // Otherwise
  return true;
};

const includesAny = <T>(collection: T[], values: T[]): boolean => {
  for (let value of values) {
    if (collection.includes(value)) {
      return true;
    }
  }
  // Otherwise
  return false;
};

export { includesAll, includesAny };
