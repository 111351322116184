import React, { useMemo, useState } from "react";
import Term from "pages/Dashboard/pages/Plan/pages/YearPage/components/Term";
import WeekFilters from "pages/Dashboard/pages/Plan/pages/YearPage/components/WeekFilters";
import { FilterValue } from "components/Filters/types";
import { TermDataResolved } from "types";
import { useTerms } from "services";
import buildFilterTerm from "services/utlities/buildFilterTerm";
import TermStats from "pages/Dashboard/pages/Plan/pages/YearPage/components/TermStats";

const useAllTerms = (academicYear: string): [TermDataResolved[][], boolean] => {
  const [yearOne, loadingYearOne] = useTerms(academicYear, "1");
  const [yearTwo, loadingYearTwo] = useTerms(academicYear, "2");
  const [yearThree, loadingYearThree] = useTerms(academicYear, "3");
  const [yearFour, loadingYearFour] = useTerms(academicYear, "4");
  const [yearFive, loadingYearFive] = useTerms(academicYear, "5");
  const [yearSix, loadingYearSix] = useTerms(academicYear, "6");
  const allTerms = useMemo(
    () => [yearOne, yearTwo, yearThree, yearFour, yearFive, yearSix],
    [yearOne, yearTwo, yearThree, yearFour, yearFive, yearSix]
  );
  const loading = useMemo(
    () =>
      loadingYearOne ||
      loadingYearTwo ||
      loadingYearThree ||
      loadingYearFour ||
      loadingYearFive ||
      loadingYearSix,
    [
      loadingYearOne,
      loadingYearTwo,
      loadingYearThree,
      loadingYearFour,
      loadingYearFive,
      loadingYearSix,
    ]
  );
  return [allTerms, loading];
};

interface AllYearsProps {
  academicYear: string;
}

const AllYears = function AllYears({ academicYear }: AllYearsProps) {
  const [allTerms, loading] = useAllTerms(academicYear);
  const flatTerms = useMemo(() => allTerms.flat(), [allTerms]);
  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [search, setSearch] = useState("");
  const filteredTerms = useMemo(() => {
    const filterTerm = buildFilterTerm(filters, search);
    return allTerms
      .map((terms) => terms.map((term) => filterTerm(term)))
      .map((terms) => ({
        terms,
        matches: terms.reduce<number>(
          (matches, term) => matches + term.filteredWeeks.length,
          0
        ),
      }));
  }, [allTerms, filters, search]);
  if (loading) {
    return <div>Loading</div>;
  }
  // Otherwise
  return (
    <div>
      <WeekFilters
        terms={flatTerms}
        value={filters}
        onChange={setFilters}
        search={search}
        onSearchChange={setSearch}
      />
      <TermStats terms={flatTerms} year="all" />
      {(filters && filters.length > 0) ||
      (search && search.trim().length > 0) ? (
        filteredTerms.map(({ terms, matches }, index) => {
          if (matches > 0) {
            return (
              <React.Fragment key={index}>
                <div className="text-lg mb-2 print:break-before-page">
                  Year {index + 1}
                </div>
                {terms.map((term, index) => {
                  if (term.filteredWeeks.length > 0) {
                    return (
                      <Term
                        key={index}
                        term={term}
                        termNumber={index + 1}
                        initiallyExpanded={true}
                      />
                    );
                  }
                  // Otherwise
                  return null;
                })}
              </React.Fragment>
            );
          }
          // Otherwise
          return null;
        })
      ) : (
        <div>Please select one or more filters</div>
      )}
    </div>
  );
};

export default AllYears;
