import { FilterValue } from "components/Filters/types";
import { WeekDataResolved } from "types";
import _ from "lodash";

const FUNC_TRUE = () => true;

const toSearchString = (search?: string): string | undefined => {
  if (search) {
    const trimmedSearch = search.trim();
    if (trimmedSearch.length > 0) {
      return trimmedSearch.toLowerCase();
    }
  }
  // Otherwise
  return undefined;
};

const weekTextSearch = (
  search: string,
  { title, description, resolvedObjectives, resolvedThreads }: WeekDataResolved
) => {
  // Search in title
  if (title && title.toLowerCase().indexOf(search) > -1) {
    return true;
  }
  // Search in description
  if (description && description.toLowerCase().indexOf(search) > -1) {
    return true;
  }
  // Search in threads
  if (
    resolvedThreads.find(
      ({ label }) => label.toLowerCase().indexOf(search) > -1
    )
  ) {
    return true;
  }
  // Search in objectives
  if (
    resolvedObjectives.find(
      ({ label }) => label.toLowerCase().indexOf(search) > -1
    )
  ) {
    return true;
  }

  // Otherwise
  return false;
};

const buildWeekMatcher = (filters?: FilterValue[], search?: string) => {
  const searchString = toSearchString(search);
  if (filters && filters.length > 0) {
    const filterThreads = filters
      .filter(({ type }) => type === "thread")
      .map(({ id }) => id);
    const filterObjectives = filters
      .filter(({ type }) => type === "objective")
      .map(({ id }) => id);
    const filterObjectiveGroups = filters
      .filter(({ type }) => type === "objective-group")
      .map(({ id }) => id);
    const filterSubjects = filters
      .filter(({ type }) => type === "subject")
      .map(({ id }) => id);
    return (week: WeekDataResolved) => {
      const { objectives, threads, resolvedObjectives } = week;
      // Check for all matching threads
      for (let i = 0; i < filterThreads.length; i += 1) {
        if (threads.indexOf(filterThreads[i]) < 0) {
          return false;
        }
      }
      // Check for all matching objective groups
      for (let i = 0; i < filterObjectiveGroups.length; i += 1) {
        if (!_.find(resolvedObjectives, { group: filterObjectiveGroups[i] })) {
          return false;
        }
      }
      // Check for all matching objectives
      for (let i = 0; i < filterObjectives.length; i += 1) {
        if (objectives.indexOf(filterObjectives[i]) < 0) {
          return false;
        }
      }
      // Check for all matching subjects
      for (let i = 0; i < filterSubjects.length; i += 1) {
        if (
          _.findIndex(resolvedObjectives, { subject: filterSubjects[i] }) < 0
        ) {
          return false;
        }
      }
      if (searchString) {
        return weekTextSearch(searchString, week);
      }
      // Otherwise
      return true;
    };
  }
  if (searchString) {
    return (week: WeekDataResolved) => weekTextSearch(searchString, week);
  }
  // Otherwise
  return FUNC_TRUE;
};

export default buildWeekMatcher;
