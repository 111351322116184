import React, { useCallback, useState } from "react";
import classNames from "classnames";
import TextBox, { TextBoxProps } from "components/Controls/TextBox";
import Tippy, { TippyProps } from "@tippyjs/react";

const MENU_OFFSET: [number, number] = [0, 1];
const DURATION: [number, number] = [275, 0];

const POPPER_OPTIONS: TippyProps["popperOptions"] = {
  modifiers: [
    {
      name: "sameWidth",
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        state.styles.popper.maxWidth = `${state.rects.reference.width}px`;
      },
      phase: "beforeWrite",
      requires: ["computeStyles"],
      effect: ({ state }) => {
        const target = state.elements.reference as Element;
        state.elements.popper.style.minWidth = `${target.clientWidth}px`;
        state.elements.popper.style.maxWidth = `${target.clientWidth}px`;
      },
    },
  ],
};

interface AutoCompleteProps extends Omit<TextBoxProps, "value" | "onChange"> {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  allowNew?: boolean;
}

const AutoComplete = function AutoComplete({
  className,
  options,
  value,
  onChange,
  allowNew = false,
  ...others
}: AutoCompleteProps) {
  const [search, setSearch] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const newValue = search.trim();
  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);
  const selectValue = (selectedValue: string) => {
    setSearch("");
    onChange([...value, selectedValue]);
  };
  const remainingOptions = options.filter((option) => !value.includes(option));
  const filteredOptions = remainingOptions.filter((option) =>
    option.toLowerCase().includes(newValue.toLowerCase())
  );
  const alreadySelected =
    value.findIndex(
      (option) => option.toLowerCase() === newValue.toLowerCase()
    ) > -1;
  const menuVisible =
    menuOpen &&
    (filteredOptions.length > 0 ||
      (allowNew && newValue.length > 0 && !alreadySelected));
  console.log("menu visible: ", menuVisible, {
    allowNew,
    newValue,
    alreadySelected,
  });
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (filteredOptions.length > 0) {
        selectValue(filteredOptions[0]);
      } else if (allowNew && newValue.length > 0 && !alreadySelected) {
        selectValue(newValue);
      }
    }
  };
  return (
    <Tippy
      content={
        <>
          {filteredOptions.map((option, index) => (
            <div
              key={option}
              className="p-1 group cursor-pointer"
              onClick={() => {
                selectValue(option);
                closeMenu();
              }}
            >
              <div
                className={classNames("p-1 rounded group-hover:bg-sky-200", {
                  "bg-sky-100": index === 0 && newValue.length > 0,
                })}
              >
                {option}
              </div>
            </div>
          ))}
          {allowNew && newValue.length > 0 && !alreadySelected && (
            <div
              className="p-1 group cursor-pointer"
              onClick={() => {
                selectValue(newValue);
                closeMenu();
              }}
            >
              <div
                className={classNames("p-1 rounded group-hover:bg-sky-200", {
                  "bg-sky-100": filteredOptions.length === 0,
                })}
              >
                <span className="text-gray-700 font-bold">New Flag:</span>{" "}
                {newValue}
              </div>
            </div>
          )}
        </>
      }
      theme="menu"
      arrow={false}
      visible={menuVisible}
      placement="bottom-start"
      offset={MENU_OFFSET}
      popperOptions={POPPER_OPTIONS}
      interactive={true}
      onClickOutside={closeMenu}
      appendTo={document.body}
      maxWidth="none"
      duration={DURATION}
    >
      <TextBox
        {...others}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onKeyDown={onKeyDown}
        onFocus={() => setMenuOpen(true)}
        className={classNames(className, {
          "rounded-b-none": menuVisible,
        })}
      />
    </Tippy>
  );
};

export default AutoComplete;
