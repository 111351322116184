import React from "react";
import classNames from "classnames";

interface TableBodyProps {
  children: React.ReactNode;
  className?: string;
}

const TableBody = function TableBody({ className, children }: TableBodyProps) {
  return <tbody className={classNames(className, "")}>{children}</tbody>;
};

export default TableBody;
