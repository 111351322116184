import React from "react";
import classNames from "classnames";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";

const getSpacing = (spacing: 0 | 1 | 2) => {
  switch (spacing) {
    case 2:
      return "mb-2";
    case 1:
      return "mb-1";
    case 0:
    default:
      return null;
  }
};

interface CheckBoxProps {
  name: string;
  label: string;
  labelDisplay?: "label" | "title";
  checked: boolean | "indeterminate";
  onChecked: () => void;
  helperText?: string;
  disabled?: boolean;
  spacing?: 0 | 1 | 2;
  className?: string;
}

const getCheckIcon = (checked: boolean | "indeterminate") => {
  switch (checked) {
    case "indeterminate":
      return <MdOutlineIndeterminateCheckBox className="text-sky-700" />;
    case true:
      return <MdOutlineCheckBox className="text-sky-700" />;
    case false:
    default:
      return <MdOutlineCheckBoxOutlineBlank />;
  }
};

const CheckBox = function CheckBox({
  name,
  label,
  labelDisplay = "label",
  checked,
  onChecked,
  helperText,
  disabled = false,
  spacing = 2,
  className,
}: CheckBoxProps) {
  return (
    <label
      className={classNames(
        "block group select-none",
        getSpacing(spacing),
        {
          "text-gray-900 hover:text-sky-700 cursor-pointer": !disabled,
          "text-gray-500": disabled,
        },
        className
      )}
    >
      <div className="flex flex-row items-center">
        {getCheckIcon(checked)}
        <input
          name={name}
          id={name}
          className="hidden"
          type="checkbox"
          onChange={onChecked}
          disabled={disabled}
          title={labelDisplay === "title" ? label : undefined}
          checked={checked === true}
        />
        {labelDisplay === "label" && (
          <span className="text-sm ml-2">{label}</span>
        )}
      </div>
      {helperText && <div className="block text-xs">{helperText}</div>}
    </label>
  );
};

export default CheckBox;
