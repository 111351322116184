import React from "react";
import classNames from "classnames";
import Page from "components/Page";
import { SideBarContainer, SideBarContent } from "components/SideBar";

import AssessmentsSidebar from "./components/AssessmentsSidebar";
import { Route, Routes } from "react-router-dom";
import ClassAssessmentsOverview from "pages/Dashboard/pages/Assessments/components/ClassAssessmentsOverview";
import SubjectAssessments from "pages/Dashboard/pages/Assessments/components/SubjectAssessments";
import UngroupedStudents from "pages/Dashboard/pages/Assessments/components/UngroupedStudents";

interface AssessmentsPageProps {
  className?: string;
}

const AssessmentsPage = function AssessmentsPage({
  className,
}: AssessmentsPageProps) {
  return (
    <Page
      title="Assessments"
      className={classNames("AssessmentsPage", className)}
      onAcademicYearChange="/app/assessments"
    >
      <SideBarContainer>
        <Routes>
          <Route path="/" element={<AssessmentsSidebar />} />
          <Route
            path="/class/:yearId/:className"
            element={<AssessmentsSidebar />}
          />
          <Route path="/class/:yearId/" element={<AssessmentsSidebar />} />
          <Route
            path="/class/:yearId/:className/:subjectId"
            element={<AssessmentsSidebar />}
          />
        </Routes>
        <SideBarContent className="p-3 flex flex-col">
          <Routes>
            <Route path="/" element={<div>Select a class</div>} />
            <Route path="/class/:yearId/" element={<UngroupedStudents />} />
            <Route
              path="/class/:yearId/:className"
              element={<ClassAssessmentsOverview />}
            />
            <Route
              path="/class/:yearId/:className/:subjectId"
              element={<SubjectAssessments />}
            />
          </Routes>
        </SideBarContent>
      </SideBarContainer>
    </Page>
  );
};

export default AssessmentsPage;
