import React, { useCallback, useEffect, useMemo, useState } from "react";
import Term from "pages/Dashboard/pages/Plan/pages/YearPage/components/Term";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useCurrentUser } from "services/user";
import {
  doc,
  runTransaction,
  deleteField,
  arrayRemove,
} from "firebase/firestore";
import { firestore } from "services/firebaseApp";
import WeekFilters from "pages/Dashboard/pages/Plan/pages/YearPage/components/WeekFilters";
import { FilterValue } from "components/Filters/types";
import TermStats from "pages/Dashboard/pages/Plan/pages/YearPage/components/TermStats";
import { Subject, TermDataResolved } from "types";
import { useTerms } from "services";
import buildFilterTerm from "services/utlities/buildFilterTerm";
import { useResolveTerm } from "services/hooks/useResolveTerm";
import _ from "lodash";
import { useResolveSubjects } from "services/hooks/useResolveSubjects";

interface TermListProps {
  academicYear: string;
  yearGroup: string;
}

const TermList = function TermList({ academicYear, yearGroup }: TermListProps) {
  const resolveTerm = useResolveTerm();
  const [realTerms, isLoading] = useTerms(academicYear, yearGroup);
  const [terms, setTerms] = useState<TermDataResolved[]>([]);
  const [dragging, setDragging] = useState(false);
  useEffect(() => {
    if (!dragging) {
      setTerms(realTerms);
    }
  }, [realTerms, dragging]);
  const onDragStart = useCallback(() => setDragging(true), [setDragging]);
  const { school } = useCurrentUser();
  const onDragEnd = useCallback(
    async (drop: DropResult) => {
      if (drop.destination) {
        const weekId = drop.draggableId;
        if (drop.source.droppableId === drop.destination.droppableId) {
          // Re-ordering within the same term
          const termNumber = Number(drop.destination.droppableId);
          const targetIndex = drop.destination.index;
          // Apply the change to the local data set immediately
          setTerms((currentTerms) => {
            const termIndex = termNumber - 1;
            const targetTerm = currentTerms[termIndex];
            const newOrder = (targetTerm.order || []).filter(
              (currentWeekId) => currentWeekId !== weekId
            );
            newOrder.splice(targetIndex, 0, weekId);
            return [
              ...currentTerms.slice(0, termIndex),
              resolveTerm(yearGroup, targetTerm.termNumber, {
                ...targetTerm,
                order: newOrder,
              }),
              ...currentTerms.slice(termIndex + 1),
            ];
          });
          // Then apply the change to the database
          const termId = `${academicYear}-${yearGroup}-${termNumber}`;
          await runTransaction(firestore, async (transaction) => {
            const termReference = doc(
              firestore,
              `schools/${school}/terms/${termId}`
            );
            const term = await transaction.get(termReference);
            const order = term.data()?.order || [];
            const newOrder = order.filter(
              (currentWeekId: string) => currentWeekId !== weekId
            );
            newOrder.splice(targetIndex, 0, weekId);
            const currentIndex = order.indexOf(drop.draggableId);
            if (currentIndex > -1 && currentIndex !== targetIndex) {
              await transaction.set(
                termReference,
                {
                  order: newOrder,
                },
                { merge: true }
              );
            }
          });
        } else {
          // Moving week to different term
          const sourceTermNumber = Number(drop.source.droppableId);
          const targetTermNumber = Number(drop.destination.droppableId);
          const targetIndex = Number(drop.destination.index);
          // Apply the change to the local data set immediately
          setTerms((currentTerms) => {
            const week = currentTerms[sourceTermNumber - 1].weeks[weekId];
            return currentTerms.map((term, index) => {
              if (index === sourceTermNumber - 1) {
                // Remove the week from the source
                const weeks = { ...term.weeks };
                delete weeks[weekId];
                return resolveTerm(yearGroup, term.termNumber, {
                  ...term,
                  weeks,
                  order: term.order.filter((value) => value !== weekId),
                });
              }
              if (index === targetTermNumber - 1) {
                // Add the week to the target
                return resolveTerm(yearGroup, term.termNumber, {
                  ...term,
                  weeks: {
                    ...term.weeks,
                    [weekId]: { ...week },
                  },
                  order: [
                    ...term.order.slice(0, targetIndex),
                    weekId,
                    ...term.order.slice(targetIndex),
                  ],
                });
              }
              // Otherwise
              return term;
            });
          });
          // Then apply the change to the database
          const sourceTermId = `${academicYear}-${yearGroup}-${sourceTermNumber}`;
          const targetTermId = `${academicYear}-${yearGroup}-${targetTermNumber}`;
          await runTransaction(firestore, async (transaction) => {
            const sourceTermReference = doc(
              firestore,
              `schools/${school}/terms/${sourceTermId}`
            );
            const targetTermReference = doc(
              firestore,
              `schools/${school}/terms/${targetTermId}`
            );
            const sourceTerm = await transaction.get(sourceTermReference);
            const targetTerm = await transaction.get(targetTermReference);
            const week = sourceTerm.data()?.weeks[weekId];
            const order = targetTerm.data()?.order || [];
            if (week) {
              await Promise.all([
                transaction.update(sourceTermReference, {
                  [`weeks.${weekId}`]: deleteField(),
                  order: arrayRemove(weekId),
                }),
                transaction.set(
                  targetTermReference,
                  {
                    weeks: {
                      [weekId]: week,
                    },
                    order: [
                      ...order.slice(0, targetIndex),
                      weekId,
                      ...order.slice(targetIndex),
                    ],
                  },
                  { merge: true }
                ),
              ]);
            }
          });
        }
      }
      setTimeout(() => setDragging(false), 200);
    },
    [school, academicYear, yearGroup, resolveTerm]
  );

  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [search, setSearch] = useState("");
  const resolveSubjects = useResolveSubjects();
  const filteredSubjects = useMemo<Subject[]>(
    () =>
      resolveSubjects(
        _.filter(filters, { type: "subject" }).map(({ id }) => id)
      ),
    [filters, resolveSubjects]
  );
  const filteredTerms = useMemo(() => {
    const filterTerm = buildFilterTerm(filters, search);
    return terms.map((term, index) => {
      const filteredTerm = filterTerm(term);
      return {
        index,
        key: `${filters.length > 0 ? "f-" : ""}${
          filteredTerm.filteredWeeks.length < filteredTerm.orderedWeeks.length
            ? "m-"
            : ""
        }${index}`,
        term: filterTerm(term),
      };
    });
  }, [terms, filters, search]);
  if (isLoading) {
    return <div>loading...</div>;
  }
  // Otherwise
  return (
    <>
      <WeekFilters
        terms={terms}
        value={filters}
        onChange={setFilters}
        search={search}
        onSearchChange={setSearch}
      />
      <div className="text-lg mb-2 print:hidden">Year {yearGroup}</div>
      {yearGroup && <TermStats terms={terms} year={yearGroup} />}

      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {filteredTerms.map(({ term, index, key }) => (
          <Term
            key={key}
            term={term}
            termNumber={index + 1}
            dragEnabled={true}
            filtered={filters.length > 0}
            filteredSubjects={filteredSubjects}
            initiallyExpanded={
              term.filteredWeeks.length < term.orderedWeeks.length
            }
          />
        ))}
      </DragDropContext>
    </>
  );
};

export default TermList;
