import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  labelDisplay?: "label" | "title";
  variant?: "default" | "inline";
  row?: boolean;
  spacing?: 0 | 1 | 2;
  disabled?: boolean;
  className?: string;
  rightAdornment?: React.ReactNode;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(
    {
      name,
      label,
      labelDisplay = "label",
      variant = "default",
      row = false,
      spacing = 2,
      disabled,
      className,
      ...props
    }: TextAreaProps,
    ref
  ) {
    const input = (
      <textarea
        name={name}
        id={name}
        className={classNames(
          "block p-1 px-2 border rounded outline-none border-gray-300 text-base text-black",
          row ? "inline-block ml-2" : "block w-full",
          disabled
            ? "bg-gray-100"
            : "hover:border-sky-300 focus:border-sky-600 focus:shadow focus:shadow-sky-200 transition-colors",
          {
            "mt-0.5": labelDisplay === "label",
          },
          className
        )}
        disabled={disabled}
        title={labelDisplay === "title" ? label : undefined}
        aria-label={labelDisplay !== "label" ? label : undefined}
        {...props}
        ref={ref}
      />
    );
    if (labelDisplay === "label") {
      return (
        <label
          className={classNames(
            "text-sm font-semibold text-gray-800",
            row ? "inline-block" : "block",
            {
              "mb-2": spacing === 2,
              "mb-1": spacing === 1,
              "text-gray-500": disabled,
            }
          )}
        >
          {label}
          {input}
        </label>
      );
    }
    // Otherwise
    return input;
  }
);

export default TextArea;
