import React from "react";
import Tippy from "@tippyjs/react";
import { MdClose } from "react-icons/md";

interface FilterProps {
  value: {
    typeLabel: string;
    optionLabel: string;
  };
  onDismiss: () => unknown;
  className?: string;
}

const Filter = function Filter({ value, onDismiss, className }: FilterProps) {
  return (
    <Tippy
      content={
        <>
          <span className="font-semibold">{value.typeLabel}: </span>
          <span>{value.optionLabel}</span>
        </>
      }
      placement="bottom"
    >
      <div className="bg-gray-50 border border-gray-400 rounded m-0.5 max-w-xs text-sm flex flex-row">
        <div className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis pl-2 py-1">
          <span className="font-semibold">{value.typeLabel}: </span>
          <span className="text-gray-800">{value.optionLabel}</span>
        </div>
        <div
          className="py-1 px-2 flex items-center cursor-pointer text-gray-500 hover:text-sky-700"
          onClick={onDismiss}
        >
          <MdClose />
        </div>
      </div>
    </Tippy>
  );
};

export default Filter;
