import React from "react";
import { List, ListItem } from "types";
import EditList from "components/EditList/EditList";

interface EditGroupsDialogProps {
  groups: List;
  onClose: () => unknown;
  onEdit?: (list: List) => unknown;
  onSelect?: (group: ListItem) => unknown;
}

const EditGroupsDialog = function EditGroupsDialog({
  groups,
  onEdit,
  onClose,
  onSelect,
}: EditGroupsDialogProps) {
  return (
    <EditList
      title="Assign to Group"
      items={groups}
      onEdit={onEdit}
      onItemSelect={onSelect}
      onClose={onClose}
      dialog
      type="Groups"
    />
  );
};

export default EditGroupsDialog;
