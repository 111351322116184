import React from "react";
import { Dialog, PanelHeader, PanelBody, PanelFooter } from "components";
import { MissingStudents } from "../types";
import Button from "components/Button";

type ImportMissingStudentsDialogProps = {
  students: MissingStudents;
  onClose: (option: "import" | "ignore" | "cancel") => void;
  className?: string;
};

const ImportMissingStudentsDialog: React.ComponentType<ImportMissingStudentsDialogProps> =
  function ImportMissingStudentsDialog({
    students,
    onClose,
    className,
  }: ImportMissingStudentsDialogProps) {
    return (
      <Dialog className="w-full h-full sm:w-1/2 h-96 max-h-full">
        <PanelHeader>Import Missing Students?</PanelHeader>
        <PanelBody>
          <div className="p-2">
            <div className="pb-1">
              The imported assessments referenced the following students that
              are not yet in the system:
            </div>
            <ul className="list-disc list-inside pb-2">
              {students.map((student) => (
                <li key={student.id}>
                  {student.name}{" "}
                  <span className="text-gray-500 text-sm">- {student.id}</span>
                </li>
              ))}
            </ul>
            <div className="pb-2">
              Would you like to import the missing students to the currently
              selected class or ignore them?
            </div>
            <div className="text-sm">
              Note:{" "}
              <i className="text-gray-600">
                If you choose to ignore the missing students only assessments
                for known students will be imported this time.
              </i>
            </div>
          </div>
        </PanelBody>
        <PanelFooter>
          <Button color="grey" onClick={() => onClose("cancel")}>
            Cancel
          </Button>
          <div className="flex-1" />
          <Button className="mr-2" onClick={() => onClose("ignore")}>
            Ignore
          </Button>
          <Button onClick={() => onClose("import")}>Import</Button>
        </PanelFooter>
      </Dialog>
    );
  };

export default ImportMissingStudentsDialog;
