import React, { useCallback, useState } from "react";
import Button from "components/Button";

const ProgressButton = function ProgressButton({
  onClick: externalOnClick,
  loading = false,
  ...others
}) {
  const [inProgress, setInProgress] = useState(false);
  const onClick = useCallback(async () => {
    setInProgress(true);
    await externalOnClick();
    setInProgress(false);
  }, [setInProgress, externalOnClick]);
  return (
    <Button onClick={onClick} loading={loading || inProgress} {...others} />
  );
};

export default ProgressButton;
