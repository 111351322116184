import React from "react";
import PropTypes from "prop-types";
import { Navigate, Route, Routes } from "react-router-dom";
import YearPage from "./pages/YearPage";
import WeekPage from "./pages/WeekPage";

const PlanPage = function PlanPage({ className, children }) {
  return (
    <>
      <Routes>
        <Route path=":academicYear/:year/*" element={<YearPage />} />
        <Route path="*" element={<Navigate to={`2022/1`} replace />} />
      </Routes>
      <Routes>
        <Route path="/:academicYear/:year/:term/:week" element={<WeekPage />} />
      </Routes>
    </>
  );
};

PlanPage.defaultProps = {
  className: null,
};

PlanPage.propTypes = {
  className: PropTypes.string,
};

export default PlanPage;
