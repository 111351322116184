import { AssessmentScore } from "types";

const assessmentScoreMap: Record<AssessmentScore, string> = {
  [AssessmentScore.ILP]: "ILP",
  [AssessmentScore.WTS]: "WTS",
  [AssessmentScore.EXS]: "EXS",
  [AssessmentScore.GDP]: "GDP",
};

export const assessmentScoreName = (score: AssessmentScore): string => {
  return assessmentScoreMap[score] || "unknown";
};
