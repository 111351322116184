import React, { createContext, useContext, useMemo } from "react";
import { AcademicYear } from "types";

interface AcademicYearContext {
  current: AcademicYear;
  selected: AcademicYear;
  select: (year: AcademicYear) => void;
  options: AcademicYear[];
}

const currentAcademicYear = (() => {
  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();
  return month < 8 ? year - 1 : year;
})();

const academicYearOptions = (() => {
  const options = [];
  for (let i = 0; i < 6; i++) {
    options.push({
      id: `${currentAcademicYear - i}`,
      value: currentAcademicYear - i,
      label: `${currentAcademicYear - i}/${currentAcademicYear - i + 1}`,
    });
  }
  options.push({
    id: `${currentAcademicYear + 1}`,
    value: currentAcademicYear + 1,
    label: `${currentAcademicYear + 1}/${currentAcademicYear + 2}`,
  });
  return options;
})();

const currentAcademicYearOption = academicYearOptions[0];

const academicYearContext = createContext<AcademicYearContext>({
  current: currentAcademicYearOption,
  selected: currentAcademicYearOption,
  select: (year: AcademicYear) => {},
  options: academicYearOptions,
});

const Provider = academicYearContext.Provider;

const AcademicYearProvider = function AcademicYearProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selected, setSelected] = React.useState<AcademicYear>(
    currentAcademicYearOption
  );

  const value = useMemo(
    () => ({
      current: currentAcademicYearOption,
      selected,
      select: setSelected,
      options: academicYearOptions,
    }),
    [selected]
  );

  return <Provider value={value}>{children}</Provider>;
};

const useAcademicYear = function useAcademicYear() {
  return useContext(academicYearContext);
};

export { AcademicYearProvider, useAcademicYear };
