import React from "react";
import classNames from "classnames";

interface PanelFooterProps {
  children: React.ReactNode;
  className?: string;
}

const PanelFooter = function PanelFooter({
  className,
  children,
}: PanelFooterProps) {
  return (
    <div
      className={classNames(
        className,
        "border-t border-gray-300 p-2 bg-white rounded-b-sm flex flex-row items-center"
      )}
    >
      {children}
    </div>
  );
};

export default PanelFooter;
