import React from "react";
import classNames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";

interface IconButtonProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  tooltip?: React.ReactNode;
  tooltipPlacement?: TippyProps["placement"];
  className?: string;
}

const IconButton = function IconButton({
  children,
  onClick,
  tooltip,
  tooltipPlacement,
  className,
}: IconButtonProps) {
  const button = (
    <div
      onClick={onClick}
      className={classNames(
        "p-2 flex justify-center items-center cursor-pointer hover:text-sky-700",
        className
      )}
    >
      {children}
    </div>
  );
  if (tooltip) {
    return (
      <Tippy content={tooltip} placement={tooltipPlacement}>
        {button}
      </Tippy>
    );
  }
  // Otherwise
  return button;
};

export default IconButton;
