import _ from "lodash";

const getDiff = function getDiff(a: unknown, b: unknown): unknown {
  // If the values are the same, return undefined (no diff)
  if (_.isEqual(a, b)) {
    return undefined;
  }
  const aType = typeof a;
  const bType = typeof b;

  // If the types are different: b has replaced a
  if (aType !== bType) {
    return b;
  }

  // Otherwise if the values are objects
  if (aType === "object") {
    // diff the objects
    return diffObjects(a as Record<any, unknown>, b as Record<any, unknown>);
  }

  // Otherwise, b has replaced a
  return b;
};

const diffObjects = function diffObjects(
  a: Record<any, unknown>,
  b: Record<any, unknown>
) {
  let delta: Record<string, unknown> = {};
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  const allKeys = new Set([...aKeys, ...bKeys]);
  allKeys.forEach((key) => {
    const aVal = a[key];
    const bVal = b[key];
    const keyDiff = getDiff(aVal, bVal);
    if (keyDiff !== undefined) {
      delta[key] = keyDiff;
    }
  });
  if (Object.keys(delta).length === 0) {
    return undefined;
  }
  return delta;
};

export { getDiff };
