import { useObjectives } from "services/providers";
import { useCallback } from "react";
import { Objective } from "types";
import _ from "lodash";

export const useResolveObjective = () => {
  const [objectives] = useObjectives();
  return useCallback(
    (id: string): Objective | undefined => _.find(objectives, { id }),
    [objectives]
  );
};

export const useResolveObjectives = () => {
  const [objectives] = useObjectives();
  return useCallback(
    (ids: string[]): Objective[] =>
      ids
        .map((id) => _.find(objectives, { id }))
        .filter((objective) => objective !== undefined) as Objective[],
    [objectives]
  );
};
