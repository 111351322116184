import React, { useMemo } from "react";
import EditList from "components/EditList/EditList";
import { ListItems, RawObjectives } from "types";
import _ from "lodash";

interface YearObjectivesProps {
  year: React.ReactText;
  objectives: RawObjectives;
  onEdit: (objectives: RawObjectives) => unknown;
  groups: ListItems;
  onGroupsEdit: (groups: ListItems) => unknown;
}

const YearObjectives = function YearObjectives({
  year,
  objectives,
  groups,
  onEdit,
  onGroupsEdit,
}: YearObjectivesProps) {
  const items: ListItems = useMemo(
    () =>
      Object.entries(objectives).map(([id, objective]) => {
        if (_.isString(objective)) {
          return { id, label: objective };
        }
        // Otherwise
        return { id, ...objective };
      }),
    [objectives]
  );
  return (
    <EditList
      title={`Year ${year}`}
      items={items}
      groups={groups}
      onEdit={(newObjectives) => {
        return onEdit(
          newObjectives.reduce(
            (current, { id, ...objective }) => ({
              ...current,
              [id]: objective,
            }),
            {}
          )
        );
      }}
      onGroupsEdit={onGroupsEdit}
      type="Objectives"
    />
  );
};

export default YearObjectives;
