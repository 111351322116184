import React, { useMemo } from "react";
import { useField } from "formik";
import Label from "components/Controls/Label";
import Select, { SelectOption } from "components/Controls/Select";
import _ from "lodash";
import { CgTrash } from "react-icons/cg";

interface SelectListFieldProps {
  name: string;
  label: string;
  placeholder: string;
  options: SelectOption[];
  className?: string;
}

const SelectListField = function SelectListField({
  name,
  label,
  options,
  placeholder,
  className,
}: SelectListFieldProps) {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const [selectedOptions, unselectedOptions] = useMemo(() => {
    const grouped = _.groupBy(options, ({ id }) =>
      _.indexOf(value, id) > -1 ? "selected" : "unselected"
    );
    return [grouped.selected || [], grouped.unselected || []];
  }, [value, options]);
  const sortedOptions = useMemo(
    () =>
      _.sortBy(selectedOptions, ["group", ({ label }) => label.toLowerCase()]),
    [selectedOptions]
  );
  const selectOption = ({ id }: SelectOption) => {
    setValue([...value, id]);
  };
  const deselectOption = ({ id }: SelectOption) => {
    setValue(value.filter((currentId: string) => currentId !== id));
  };
  return (
    <div className={className}>
      <Label>{label}</Label>
      {Object.entries(_.groupBy(sortedOptions, "group")).map(
        ([group, options]) => (
          <>
            <Label textStyle="text-xs text-gray-500">{group}</Label>
            <ul className="list-disc ml-6">
              {options.map((option) => (
                <li key={option.id}>
                  <div className="flex flex-row items-center group-one">
                    <div className="py-1">{option.label}</div>
                    <div
                      className="inline-block p-1 cursor-pointer hover:text-sky-700 opacity-0 group-one-hover:opacity-100 transition-opacity"
                      onClick={() => deselectOption(option)}
                    >
                      <CgTrash />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )
      )}
      <ul className="list-disc ml-6">
        <li className="py-1 text-gray-600">
          <Select
            name="objective"
            label="Objectives"
            value={null}
            options={unselectedOptions}
            onChange={selectOption}
            labelDisplay="title"
            placeholder={placeholder}
            variant="inline"
            grouped
          />
        </li>
      </ul>
    </div>
  );
};

export default SelectListField;
