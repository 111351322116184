import React, { useMemo } from "react";
import { AssessmentScore, StudentWithAssessments } from "types";
import { ResponsiveLine } from "@nivo/line";
import { fromTermNumber, useAssessedSubjects } from "services";
import { Panel, PanelHeader } from "components";
import { getAssessmentTermScore } from "services/utlities/getAssessmentTermScore";
import { assessmentScoreName } from "services/utlities/assessmentScoreName";

interface Scores {
  [subject: string]: number;
}
interface ScoresByTerm {
  term: string;
  scores: Scores;
}

interface ClassProgressTimelineProps {
  students: StudentWithAssessments[];
  from: number;
  to: number;
}

const getAssessmentScoreName = (score: number) => {
  // Return the closest score name
  if (score === 3) {
    return `${assessmentScoreName(AssessmentScore.GDP)} - ${score}`;
  }
  // Otherwise
  if (score === 2) {
    return `${assessmentScoreName(AssessmentScore.EXS)} - ${score}`;
  }
  // Otherwise
  if (score === 1) {
    return `${assessmentScoreName(AssessmentScore.WTS)} - ${score}`;
  }
  // Otherwise
  if (score === 0) {
    return `${assessmentScoreName(AssessmentScore.ILP)} - ${score}`;
  }
  // Otherwise
  return `${score}`;
};

const ClassProgressTimeline = function ClassProgressTimeline({
  students,
  from,
  to,
}: ClassProgressTimelineProps) {
  const [subjects] = useAssessedSubjects();
  const scoresByTerm = useMemo<ScoresByTerm[]>(() => {
    if (Array.isArray(subjects)) {
      const result: ScoresByTerm[] = [];
      for (let termNumber = from; termNumber <= to; termNumber++) {
        const scores = subjects.reduce<Scores>(
          (current, subject) => ({
            ...current,
            [subject.id]:
              students.reduce<number>(
                (currentScore, { assessments }) =>
                  currentScore +
                  getAssessmentTermScore(assessments, subject.id, termNumber),
                0
              ) / students.length,
          }),
          {}
        );
        const [year, term] = fromTermNumber(termNumber);
        result.push({
          term: `${year}-${term}`,
          scores,
        });
      }
      return result;
    }
    // Otherwise
    return [];
  }, [subjects, students, from, to]);

  const data = useMemo(() => {
    if (Array.isArray(subjects)) {
      return subjects.map(({ id, name }) => {
        return {
          id: name,
          data: scoresByTerm.map(({ term, scores }) => ({
            x: term,
            y: scores[id],
          })),
        };
      });
    }
    // Otherwise
    return [];
  }, [subjects, scoresByTerm]);
  return (
    <Panel className="mb-2 print:hidden">
      <PanelHeader>
        <h3>Average Grade by Term</h3>
      </PanelHeader>
      <div className="h-64 p-2 w-full print:border border-gray-600">
        <ResponsiveLine
          data={data}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", stacked: false, min: 0, max: "auto" }}
          margin={{ top: 25, right: 90, bottom: 40, left: 60 }}
          pointSize={8}
          pointBorderWidth={2}
          pointBorderColor={{ from: "seriesColor" }}
          enableSlices={"x"}
          sliceTooltip={({ slice }) => {
            const [year, term] = `${slice.points[0].data.x}`.split("-");
            return (
              <div className="bg-white border border-gray-300 rounded p-2 shadow font-normal text-base">
                <div className="text-xs font-semibold text-sky-800 pb-1">
                  Year {year} - Term {term}
                </div>
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    className="flex items-center pb-0.5 text-sm"
                  >
                    <div
                      className="h-4 w-4"
                      style={{ backgroundColor: point.color }}
                    />
                    <div className="flex-1 pl-1 pr-4">{point.serieId}</div>
                    <div>{point.data.yFormatted}</div>
                  </div>
                ))}
              </div>
            );
          }}
          useMesh={true}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            format: (value) => getAssessmentScoreName(value),
          }}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              itemWidth: 80,
              itemHeight: 20,
              translateX: 90,
            },
          ]}
        />
      </div>
    </Panel>
  );
};

export default ClassProgressTimeline;
