import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import logo from "assets/logo.png";
import { NavLink } from "react-router-dom";
import {
  MdOutlineCalendarToday,
  MdOutlineChecklist,
  MdPeople,
  MdAssessment,
  MdPieChart,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { useCurrentUser } from "services/user";

const Link = function Link({ path, label, icon: Icon }) {
  return (
    <NavLink
      key={path}
      to={path}
      className={({ isActive }) =>
        classNames("w-full p-2 rounded block flex items-center mb-2", {
          "text-white bg-gray-900 cursor-auto": isActive,
          "text-white/90 hover:text-white hover:bg-gray-900/75": !isActive,
        })
      }
    >
      <Icon className="text-xl block" />
      <div className="ml-2">{label}</div>
    </NavLink>
  );
};

const Navigation = function Navigation({ printTitle, className }) {
  const { role } = useCurrentUser();
  return (
    <div className="w-64 bg-gray-800 print:w-auto">
      <div
        className={classNames(
          className,
          "h-16 p-3 bg-gray-900 flex flex-row items-center ring-1 ring-gray-900 ring-opacity-5 shadow-sm",
          "text-xl text-white",
          "print:bg-white print:ring-0 print:shadow-none print:text-black"
        )}
      >
        <img src={logo} className="h-10 w-10" alt="school logo" />
        <div className="ml-3">
          <div className="print:leading-tight font-bold">
            Silverhill<span className="hidden print:inline"> Primary</span>
          </div>
          {printTitle && (
            <div className="hidden print:block text-sm leading-3">
              {printTitle}
            </div>
          )}
        </div>
      </div>
      <div className="p-2 print:hidden">
        <div className="text-sm text-white/50 mb-2">Plan</div>
        <Link path="plan" label="Plan" icon={MdOutlineCalendarToday} />
        {role === "admin" && (
          <Link
            path="objectives"
            label="Objectives"
            icon={MdOutlineChecklist}
          />
        )}
        <div className="text-sm text-white/50 mb-2">Track</div>
        <Link path="assessments" label="Assessments" icon={MdAssessment} />
        <Link path="reports" label="Reports" icon={MdPieChart} />
        <div className="text-sm text-white/50 mb-2">Manage</div>
        <Link path="students" label="Students" icon={MdPeople} />
        {role === "admin" && (
          <Link
            path="users"
            label="User Access"
            icon={MdOutlineAdminPanelSettings}
          />
        )}
      </div>
    </div>
  );
};

Navigation.defaultProps = {
  className: null,
  printTitle: "",
};

Navigation.propTypes = {
  className: PropTypes.string,
  printTitle: PropTypes.string,
};

export default Navigation;
