// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import environment from "services/environment";

// Firebase configuration for production environment
const productionConfig = {
  apiKey: "AIzaSyCvIHNof6E2bQJW7nMzVDvfiXjsXd0rvx0",
  authDomain: "silverhill-objectives.firebaseapp.com",
  projectId: "silverhill-objectives",
  storageBucket: "silverhill-objectives.appspot.com",
  messagingSenderId: "612682869386",
  appId: "1:612682869386:web:ed8d64ae3ef1378e175b73",
};
// Firebase configuration for staging environment
const stagingConfig = {
  apiKey: "AIzaSyDOOKvwIHf1HYM8qcSbgBEF_YEffelieuY",
  authDomain: "primary-tracker-staging.firebaseapp.com",
  projectId: "primary-tracker-staging",
  storageBucket: "primary-tracker-staging.appspot.com",
  messagingSenderId: "673353209783",
  appId: "1:673353209783:web:f9904020d1ea75c74dba32",
  measurementId: "G-WKKX65H6DM",
};
// Firebase configuration for current environment
const firebaseConfig = environment.isDevelopment
  ? stagingConfig
  : productionConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);

// Connect to emulators
if (environment.isDevelopment) {
  console.info("Running in development mode", {
    ...environment,
    hostname: window.location.hostname,
    config: firebaseConfig,
  });
  if (!environment.isCodeSandbox) {
    console.info("connecting to local emulators");
    connectAuthEmulator(auth, `http://localhost:9099`);
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
}

export default app;
export { auth, firestore, functions };
