import React from "react";
import classNames from "classnames";

interface SideBarHeaderProps {
  children: React.ReactNode;
  title: React.ReactNode;
  active?: boolean;
  selected?: boolean;
  className?: string;
  onHeaderClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SideBarSection = function SideBarHeader({
  className,
  title,
  children,
  active = false,
  selected = false,
  onHeaderClick,
}: SideBarHeaderProps) {
  return (
    <div className={className}>
      <div
        className={classNames("p-1 rounded text-sm", {
          "bg-sky-700 text-white/90": selected,
          "bg-slate-300": active,
          "text-gray-500": !active && !selected,
        })}
        onClick={onHeaderClick}
      >
        {title}
      </div>
      <div className="pl-2">{children}</div>
    </div>
  );
};

export default SideBarSection;
