import React, { useMemo } from "react";

interface SkeletonSideBarItemsProps {
  items?: number;
}

const SkeletonSideBarItems = function SkeletonSideBarItems({
  items = 3,
}: SkeletonSideBarItemsProps) {
  return useMemo(() => {
    const options = [];
    for (let i = 0; i < items; i += 1) {
      options.push(
        <div key={i} className="px-2 py-3 w-full">
          <div className="h-4 w-full bg-gray-300" />
        </div>
      );
    }
    return <div className="animate-pulse">{options}</div>;
  }, [items]);
};

export default SkeletonSideBarItems;
