import React from "react";
import { useParams } from "react-router-dom";
import ImportAssessmentsButton from "./ImportAssessmentsButton";
import { useCurrentUser } from "services/user";
import {
  getClassName,
  toTermNumber,
  useAssessedSubjects,
  usePreviousYear,
  useYear,
  Year,
} from "services";
import { useStudentsWithAssessments } from "services/hooks/useStudentsWithAssessments";
import {
  AssessmentScore,
  StudentWithAssessments,
  StudentWithProgress,
  Subject,
} from "types";
import { getAssessmentTermScore } from "services/utlities/getAssessmentTermScore";
import { Panel, PanelBody, PanelHeader } from "components";
import Label from "components/Controls/Label";
import Tippy from "@tippyjs/react";
import classNames from "classnames";

const StudentProgressBarSection = function StudentProgressBarSection({
  students,
  totalStudents,
  color,
  label,
}: {
  students: StudentWithProgress[];
  totalStudents: number;
  color: "gray" | "red" | "green" | "sky";
  label: string;
}) {
  if (students.length === 0) {
    return null;
  }
  // Otherwise
  const percent = (students.length / totalStudents) * 100;
  return (
    <Tippy
      content={
        <div className="w-80">
          <Label
            textStyle={classNames({
              "text-gray-800": color === "gray",
              "text-red-800": color === "red",
              "text-green-800": color === "green",
              "text-sky-800": color === "sky",
            })}
            className="flex flex-row p-2 border-b"
          >
            <div className="flex-1">{label}</div>
            <div>{Math.round(percent)}%</div>
          </Label>
          <div className="p-2">
            {students.map((student, index) => (
              <div key={student.id} className="px-1 py-0.5 m-1 inline-block">
                {student.name}
                {index < students.length - 1 && ","}
              </div>
            ))}
          </div>
        </div>
      }
      placement="bottom"
      theme="white"
      interactive
    >
      <div
        className={classNames("flex-auto text-center text-white/95", {
          "bg-gray-800": color === "gray",
          "bg-red-800": color === "red",
          "bg-green-800": color === "green",
          "bg-sky-800": color === "sky",
        })}
        style={{
          width: `${percent}%`,
        }}
      >
        {percent > 0 && `${Math.round(percent)}%`}
      </div>
    </Tippy>
  );
};

const StudentProgressBar = function StudentProgressBar({
  students,
}: {
  students: StudentWithProgress[];
}) {
  const ilpStudents = students.filter(
    (student) => student.currentScore === AssessmentScore.ILP
  );
  const wtsStudents = students.filter(
    (student) => student.currentScore === AssessmentScore.WTS
  );
  const exsStudents = students.filter(
    (student) => student.currentScore === AssessmentScore.EXS
  );
  const gdpStudents = students.filter(
    (student) => student.currentScore === AssessmentScore.GDP
  );
  return (
    <div className="w-full flex flex-row">
      <StudentProgressBarSection
        students={ilpStudents}
        totalStudents={students.length}
        color="gray"
        label="ILP"
      />
      <StudentProgressBarSection
        students={wtsStudents}
        totalStudents={students.length}
        color="red"
        label="WTS"
      />
      <StudentProgressBarSection
        students={exsStudents}
        totalStudents={students.length}
        color="green"
        label="EXS"
      />
      <StudentProgressBarSection
        students={gdpStudents}
        totalStudents={students.length}
        color="sky"
        label="GDP"
      />
    </div>
  );
};

const useProgressRange = (currentYear: Year): [number, number] => {
  const previousYear = usePreviousYear(currentYear.id);
  if (previousYear) {
    return [
      toTermNumber(previousYear.id, "6"),
      toTermNumber(currentYear.id, "6"),
    ];
  }
  // Otherwise
  return [toTermNumber(currentYear.id, "1"), toTermNumber(currentYear.id, "6")];
};

const ClassSubjectOverview = function ClassSubjectOverview({
  students,
  year,
  subject,
}: {
  students: StudentWithAssessments[];
  year: Year;
  subject: Subject;
}) {
  // Get the comparison range
  const [from, to] = useProgressRange(year);
  // Get the previous and current score for each of the students
  const studentsWithProgress = students
    .map<StudentWithProgress>((student) => ({
      ...student,
      previousScore: getAssessmentTermScore(
        student.assessments,
        subject.id,
        from
      ),
      currentScore: getAssessmentTermScore(student.assessments, subject.id, to),
    }))
    .sort((a, b) => b.currentScore - a.currentScore);
  return (
    <div className="p-2 pb-4 border-b">
      <Label>{subject.name}</Label>
      <StudentProgressBar students={studentsWithProgress} />
    </div>
  );
};

const ClassSubjectsOverview = function ClassSubjectsOverview({
  year,
  schoolClassName,
}: {
  year: Year;
  schoolClassName: string;
}) {
  // Get all students for the given year
  const [allStudentsWithAssessments] = useStudentsWithAssessments(
    year.entryYear
  );
  // Filter students to current class
  const studentsWithAssessments = allStudentsWithAssessments.filter(
    (student) => getClassName(student, year.id) === schoolClassName
  );
  // Get all the assessed subjects
  const [subjects] = useAssessedSubjects();

  // Display the overview for each subject
  if (Array.isArray(subjects)) {
    return (
      <Panel className="mb-2">
        <PanelHeader>Attainment</PanelHeader>
        <PanelBody>
          {subjects.map((subject, index) => (
            <ClassSubjectOverview
              key={subject.id}
              students={studentsWithAssessments}
              year={year}
              subject={subject}
            />
          ))}
        </PanelBody>
      </Panel>
    );
  }
  // Otherwise
  return <div>Loading...</div>;
};

const ClassAssessmentsOverview = function ClassAssessmentsOverview() {
  const { yearId, className } = useParams();
  const year = useYear(yearId);
  const { role } = useCurrentUser();
  return (
    <>
      <div className="text-lg mb-2 print:hidden sticky">
        Class {className} Overview
      </div>
      {yearId && className && role === "admin" && (
        <ImportAssessmentsButton yearId={yearId} schoolClassName={className} />
      )}
      {year && className && (
        <ClassSubjectsOverview year={year} schoolClassName={className} />
      )}
    </>
  );
};

export default ClassAssessmentsOverview;
