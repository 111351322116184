import React from "react";
import { TermWeek } from "services/hooks";
import getTermName from "services/getTermName";

const formatCompletedWeek = ({ termNumber, weekNumber }: TermWeek) => {
  return `${getTermName(termNumber, true)} ${weekNumber}`;
};

const formatCompletedWeeks = (completedWeeks?: TermWeek[]) => {
  if (completedWeeks) {
    return (
      <span className="text-green-700">
        {" - "}
        Covered in:{" "}
        {completedWeeks.reduce<string>(
          (current, completedWeek, index) =>
            current
              ? `${current}, ${formatCompletedWeek(completedWeek)}`
              : formatCompletedWeek(completedWeek),
          ""
        )}
      </span>
    );
  }
  // Otherwise
  return null;
};

export { formatCompletedWeek, formatCompletedWeeks };
