import React, { useCallback, useContext, useMemo, useState } from "react";
import classNames from "classnames";

interface PanelContext {
  expanded: boolean;
  expandable: boolean;
  alwaysPrint: boolean;
  onToggle?: () => unknown;
  onDismiss?: () => unknown;
  color: "gray" | "green" | "purple";
}

const panelContext = React.createContext<PanelContext>({
  expanded: true,
  expandable: false,
  alwaysPrint: false,
  color: "gray",
});
const Provider = panelContext.Provider;

export const usePanelContext = () => useContext(panelContext);

interface PanelProps {
  children: React.ReactNode;
  onDismiss?: () => unknown;
  expandable?: boolean;
  alwaysPrint?: boolean;
  initiallyExpanded?: boolean;
  expanded?: boolean;
  className?: string;
  color?: "gray" | "green" | "purple";
}

const Panel = function Panel({
  children,
  expandable = false,
  alwaysPrint = false,
  initiallyExpanded = true,
  onDismiss,
  className,
  color = "gray",
}: PanelProps) {
  const [panelState, setPanelState] = useState({
    expanded: initiallyExpanded,
  });
  const onTogglePanelExpanded = useCallback(
    () =>
      setPanelState((current) => ({ ...current, expanded: !current.expanded })),
    [setPanelState]
  );
  const panelContext: PanelContext = useMemo<PanelContext>(
    () => ({
      expanded: expandable ? panelState.expanded : true,
      expandable,
      alwaysPrint,
      onToggle: onTogglePanelExpanded,
      onDismiss: onDismiss,
      color,
    }),
    [
      panelState,
      onTogglePanelExpanded,
      expandable,
      alwaysPrint,
      onDismiss,
      color,
    ]
  );
  return (
    <Provider value={panelContext}>
      <div
        className={classNames(
          className,
          "border bg-gray-50 shadow rounded-sm flex flex-col group",
          "print:border-none print:shadow-none print:bg-white print-exact",
          alwaysPrint || panelState.expanded ? "print:block" : "print:hidden",
          {
            "border-gray-400": color === "gray",
            "border-green-700": color === "green",
            "border-purple-800": color === "purple",
          }
        )}
      >
        {children}
      </div>
    </Provider>
  );
};

export default Panel;
