interface Environment {
  isDevelopment: boolean;
  isProduction: boolean;
  isCodeSandbox: boolean;
  sandboxId: null | string;
}

const isDev = process.env.NODE_ENV === "development";
const [isCodeSandbox, sandboxId] = (() => {
  // Check if running in development environment
  if (isDev) {
    // Check if the current host is CSB
    const match = window.location.hostname.match(/([^-]+)-(\d+).csb.app/s);
    if (match !== null) {
      // And if so return the sandbox id
      return [true, match[1]];
    }
  }
  // Otherwise
  return [false, ""];
})();
const isProd = !isDev && !isCodeSandbox;

const environment: Environment = {
  isDevelopment: isDev,
  isProduction: isProd,
  isCodeSandbox,
  sandboxId,
};

export default environment;
