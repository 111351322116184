import React, { useMemo } from "react";
import classNames from "classnames";
import { ListItems, RawGroups, Subject } from "types";
import YearObjectives from "./YearObjectives";
import { useSetSchoolDocument } from "services/firebaseHooks";

const years = [1, 2, 3, 4, 5, 6];

interface SubjectObjectivesProps {
  subject: Subject;
  className?: string;
}

const SubjectObjectives = function SubjectObjectives({
  subject,
  className,
}: SubjectObjectivesProps) {
  const setSubjectData = useSetSchoolDocument(`subjects/${subject.id}`, true);
  const groups = useMemo<ListItems>(() => {
    if (subject.groups) {
      return Object.entries(subject.groups).map(([id, group]) => ({
        id,
        ...group,
      }));
    }
    // Otherwise
    return [];
  }, [subject]);
  return (
    <div className={classNames(className, "")}>
      <div className="text-lg mb-2 print:hidden sticky">
        {subject.name} Objectives
      </div>
      {years.map((year) => (
        <YearObjectives
          key={year}
          year={year}
          objectives={
            (subject &&
              subject.objectives &&
              subject.objectives[`year_${year}`]) ||
            {}
          }
          onEdit={(newObjectives) => {
            console.log("saving document: ", {
              objectives: { [`year_${year}`]: newObjectives },
            });
            return setSubjectData({
              objectives: { [`year_${year}`]: newObjectives },
            }).then(
              () => {
                console.log("saved");
              },
              (error) => {
                console.log("error: ", error);
              }
            );
          }}
          groups={groups}
          onGroupsEdit={(newGroups: ListItems) => {
            return setSubjectData({
              groups: newGroups.reduce<RawGroups>(
                (current, { id, ...group }) => ({
                  ...current,
                  [id]: group,
                }),
                {}
              ),
            });
          }}
        />
      ))}
    </div>
  );
};

export default SubjectObjectives;
