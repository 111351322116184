import React, { useRef, useState } from "react";
import Button from "components/Button";
import { readSheets } from "services/utlities/readSheet";
import {
  parseAssessmentScore,
  useOpenDialog,
  useSubjects,
  useYear,
} from "services";
import { useAllStudents } from "services/hooks/useStudents";
import {
  AssessmentData,
  AssessmentsData,
  AssessmentYear,
} from "types/assessments";
import { MissingStudent, MissingStudents } from "../types";
import ImportMissingStudentsDialog from "pages/Dashboard/pages/Assessments/components/ImportMissingStudentsDialog";
import { writeBatch } from "@firebase/firestore";
import { firestore } from "services/firebaseApp";
import { doc } from "firebase/firestore";
import { useCurrentUser } from "services/user";
import _ from "lodash";

interface ImportedAssessment extends Record<string, any> {
  studentId?: string;
  studentName?: string;
  termOne?: string;
  termTwo?: string;
  termThree?: string;
  termFour?: string;
  termFive?: string;
  termSix?: string;
}

type ImportedAssessments = Record<string, ImportedAssessment[]>;

interface StudentAssessments {
  [studentId: string]: {
    name?: string;
    subjects: {
      [subjectId: string]: AssessmentYear;
    };
  };
}

interface ResolvedAssessments {
  [entryYear: string]: AssessmentsData;
}

const parseAssessment = (assessment: ImportedAssessment): AssessmentYear => {
  // Parse all provided term data
  return Object.entries({
    1: parseAssessmentScore(assessment.termOne),
    2: parseAssessmentScore(assessment.termTwo),
    3: parseAssessmentScore(assessment.termThree),
    4: parseAssessmentScore(assessment.termFour),
    5: parseAssessmentScore(assessment.termFive),
    6: parseAssessmentScore(assessment.termSix),
  }).reduce<AssessmentYear>((currentYear, [term, score]) => {
    if (score !== undefined) {
      return {
        ...currentYear,
        [term]: score,
      };
    }
    // Otherwise
    return currentYear;
  }, {});
};

interface ImportAssessmentsButtonProps {
  yearId: string;
  schoolClassName: string;
  className?: string;
}

const ImportAssessmentsButton = function ImportAssessmentsButton({
  yearId,
  schoolClassName,
  className,
}: ImportAssessmentsButtonProps) {
  const year = useYear(yearId);
  const { school } = useCurrentUser();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const openDialog = useOpenDialog();
  const [importing, setImporting] = useState(false);
  const [subjects, loadingSubjects] = useSubjects();
  const [students, loadingStudents] = useAllStudents();
  const loading = loadingSubjects || loadingStudents;
  const importFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && year && school) {
      setImporting(true);
      const assessments = await readSheets<ImportedAssessment>(
        event.target.files[0],
        subjects?.map((subject) => subject.name) || [],
        {
          studentId: ["Student ID"],
          studentName: ["Student Name", "Student", "Name"],
          termOne: ["Term 1", "Autumn 1"],
          termTwo: ["Term 2", "Autumn 2"],
          termThree: ["Term 3", "Spring 1"],
          termFour: ["Term 4", "Spring 2"],
          termFive: ["Term 5", "Summer 1"],
          termSix: ["Term 6", "Summer 2"],
        }
      );
      // Map the assessment names to the subject ids
      const importedAssessments: ImportedAssessments = Object.entries(
        assessments
      ).reduce<ImportedAssessments>(
        (current, [subjectName, assessments]) => ({
          ...current,
          [subjects?.find((subject) => subject.name === subjectName)?.id || ""]:
            assessments.map((assessment) => ({
              ...assessment,
              studentId: assessment.studentId
                ? _.trim(assessment.studentId)
                : undefined,
            })),
        }),
        {}
      );
      console.log("imported assessments: ", importedAssessments);

      // Map the assessments to the students
      const studentAssessments: StudentAssessments = Object.entries(
        importedAssessments
      ).reduce<StudentAssessments>(
        (currentStudentAssessments, [subjectId, assessments]) => {
          return assessments.reduce<StudentAssessments>(
            (current, assessment) => {
              if (assessment.studentId) {
                return {
                  ...current,
                  [assessment.studentId]: {
                    name:
                      current[assessment.studentId]?.name ||
                      assessment.studentName,
                    subjects: {
                      ...current[assessment.studentId]?.subjects,
                      [subjectId]: parseAssessment(assessment),
                    },
                  },
                };
              }
              // Otherwise
              return current;
            },
            currentStudentAssessments
          );
        },
        {}
      );

      console.log("imported student assessments: ", studentAssessments);
      console.log("all students: ", students);

      // Check for any students that don't currently exist
      const missingStudents: MissingStudents = Object.entries(
        studentAssessments
      )
        .filter(
          ([studentId]) =>
            !students?.find((student) => student.id === studentId)
        )
        .map<MissingStudent>(([studentId, student]) => ({
          id: studentId,
          name: student.name || "",
        }));

      console.log("missing students: ", missingStudents);
      let option = "ignore";
      if (missingStudents.length > 0) {
        option = await openDialog(ImportMissingStudentsDialog, {
          students: missingStudents,
        });
      }
      if (option === "cancel") {
        setImporting(false);
        return;
      }
      const targetData =
        option === "import"
          ? studentAssessments
          : Object.entries(studentAssessments).reduce<StudentAssessments>(
              (current, [studentId, student]) => {
                if (students?.find((student) => student.id === studentId)) {
                  return {
                    ...current,
                    [studentId]: student,
                  };
                }
                return current;
              },
              {}
            );
      console.log("target data: ", targetData);

      // Create a function that resolves a students entry year
      const resolveEntryYear = (studentId: string): number | undefined => {
        const student = students?.find((student) => student.id === studentId);
        if (student) {
          return student.entryYear;
        }
        // Otherwise
        if (option === "import") {
          return year.entryYear;
        }
        // Otherwise
        return undefined;
      };

      const resolveYearGroup = (entryYear: number) => {
        return year.entryYear - entryYear + Number(year.id);
      };

      // Prepare the import by resolving each student to the correct entry year
      const importData: ResolvedAssessments = Object.entries(
        studentAssessments
      ).reduce<ResolvedAssessments>((current, [studentId, student]) => {
        const entryYear = resolveEntryYear(studentId);
        if (entryYear) {
          return {
            ...current,
            [entryYear]: {
              assessments: {
                ...current[entryYear]?.assessments,
                [studentId]: Object.entries(
                  student.subjects
                ).reduce<AssessmentData>(
                  (assessmentData, [subjectId, assessment]) => ({
                    ...assessmentData,
                    [subjectId]: {
                      [resolveYearGroup(entryYear)]: assessment,
                    },
                  }),
                  {}
                ),
              },
            },
          };
        }
        // Otherwise
        return current;
      }, {});

      console.log("import data: ", importData);

      // Prepare the batched writes
      const batch = writeBatch(firestore);
      // Write to each affected assessment document
      Object.entries(importData).forEach(([year, assessments]) => {
        const assessmentsRef = doc(
          firestore,
          `schools/${school}/assessments/${year}`
        );
        batch.set(assessmentsRef, assessments, { merge: true });
      });
      // And if importing students then also write to each affected students document
      if (option === "import") {
        const studentsRef = doc(
          firestore,
          `schools/${school}/students/${year.entryYear}`
        );
        batch.set(
          studentsRef,
          {
            students: missingStudents.reduce(
              (current, { id, name }) => ({
                ...current,
                [id]: {
                  name,
                  classes: { [year.id]: schoolClassName },
                },
              }),
              {}
            ),
          },
          { merge: true }
        );
      }
      // // Write the batch
      await batch.commit();
      // Reset the status
      setImporting(false);
    }
  };
  const openFileSelect = () => {
    if (fileInput.current) {
      fileInput.current?.click();
    }
  };
  return (
    <label>
      <Button
        onClick={openFileSelect}
        loading={importing || loading}
        className={className}
      >
        Import
      </Button>
      <input type="file" onChange={importFile} hidden ref={fileInput} />
    </label>
  );
};

export default ImportAssessmentsButton;
