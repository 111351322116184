import {
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  runTransaction,
  Transaction,
} from "firebase/firestore";
import { firestore } from "./firebaseApp";
import { useCurrentUser } from "./user";
import { useMemo } from "react";

export const useCollection = <T>(path: string) => {
  console.log("using collection", path);
  const query = collection(firestore, path);
  // @ts-ignore
  const [values, loading, error] = useCollectionData<T>(query, {
    idField: "id",
  });
  return [values as unknown as T[], loading, error];
};

export const useDocument = <T>(path: string): [T, boolean, boolean] => {
  const reference = doc(firestore, path);
  // @ts-ignore
  const [value, loading, error] = useDocumentData<T>(reference, {
    idField: "id",
  });
  return [value as T, loading, !!error];
};

export const useSetDocument = <T>(path: string, merge: boolean = false) => {
  return useMemo(() => {
    const reference = doc(firestore, path);
    return (document: T) => {
      return setDoc(reference, document, { merge });
    };
  }, [path, merge]);
};

export const useUpdateDocument = <T>(path: string) => {
  return useMemo(() => {
    const reference = doc(firestore, path);
    return (updates: Partial<T>) => {
      // @ts-ignore
      return updateDoc(reference, updates);
    };
  }, [path]);
};

export const useDocumentOnce = <T>(path: string) => {
  const reference = doc(firestore, path);
  // @ts-ignore
  const [value, loading, error] = useDocumentDataOnce<T>(reference, {
    idField: "id",
  });
  return [value as T, loading, error];
};

export const useSchoolCollection = <T>(path: string) => {
  const { school } = useCurrentUser();
  return useCollection<T>(`schools/${school}/${path}`);
};

export const useSchoolDocument = <T>(path: string) => {
  const { school } = useCurrentUser();
  return useDocument<T>(`schools/${school}/${path}`);
};

export const useSchoolDocumentOnce = <T>(path: string) => {
  const { school } = useCurrentUser();
  return useDocumentOnce<T>(`schools/${school}/${path}`);
};

export const useSetSchoolDocument = <T>(
  path: string,
  merge: boolean = false
) => {
  const { school } = useCurrentUser();
  return useSetDocument<T>(`schools/${school}/${path}`, merge);
};

export const useUpdateSchoolDocument = <T>(path: string) => {
  const { school } = useCurrentUser();
  return useUpdateDocument<T>(`schools/${school}/${path}`);
};

export const useRunTransaction =
  <T>() =>
  (updateFunction: (transaction: Transaction) => Promise<T>) =>
    runTransaction(firestore, updateFunction);

export const useRunSchoolTransaction = <T>() => {
  const { school } = useCurrentUser();
  const getSchoolDoc = (path: string) =>
    doc(firestore, `schools/${school}/${path}`);
  return (
    updateFunction: (
      transaction: Transaction,
      schoolDoc: typeof getSchoolDoc
    ) => Promise<T>
  ) => {
    return runTransaction(firestore, (transaction: Transaction) =>
      updateFunction(transaction, getSchoolDoc)
    );
  };
};
