import React, { useCallback, useState } from "react";
import { PendingListItem } from "./types";
import classNames from "classnames";
import IconButton from "components/IconButton";
import {
  MdCheck,
  MdEdit,
  MdOutlineArchive,
  MdOutlineUnarchive,
} from "react-icons/md";
import { CgTrash } from "react-icons/cg";
import ClickAwayListener from "components/ClickAwayListener";
import TextBox from "components/Controls/TextBox";
import CheckBox from "components/Controls/CheckBox";
import { ListItem } from "types";
import { Timestamp } from "firebase/firestore";
import { formatItem } from "./utilities";

interface EditItemProps {
  item: PendingListItem;
  onUpdate?: (updateItem: PendingListItem) => void;
  onDelete?: (id: string) => void;
  onClick?: (item: ListItem) => unknown;
  selectable?: boolean;
  editable?: boolean;
}

const EditItem = function EditItem({
  item,
  onUpdate,
  onClick,
  onDelete,
  selectable = false,
  editable = true,
}: EditItemProps) {
  const isEditable = editable && onUpdate;
  const [editing, setEditing] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const onEdit = useCallback(() => {
    setNewLabel(item.label);
    setEditing(true);
  }, [item.label, setNewLabel, setEditing]);
  const onBlur = useCallback(() => {
    setEditing(false);
  }, []);
  const onArchiveOrDelete = useCallback(() => {
    if (item.pending && onDelete) {
      return onDelete(item.id);
    }
    // Otherwise
    if (onUpdate) {
      if (item.archived) {
        return onUpdate({
          ...item,
          archived: null,
        });
      }
      // Otherwise
      return onUpdate({
        ...item,
        archived: Timestamp.now(),
      });
    }
  }, [item, onUpdate, onDelete]);
  const onSave = useCallback(() => {
    if (onUpdate) {
      const label = formatItem(newLabel);
      if (label.length > 0) {
        onUpdate({
          ...item,
          label: newLabel,
        });
      }
    }
    setEditing(false);
  }, [item, onUpdate, newLabel, setEditing]);
  const onChange = useCallback(
    (event) => setNewLabel(event.target.value),
    [setNewLabel]
  );
  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSave();
      }
      // Otherwise
      if (event.key === "Tab") {
        onBlur();
      }
    },
    [onSave, onBlur]
  );
  const onSelect = () => {
    if (onUpdate) {
      onUpdate({ ...item, selected: !item.selected });
    }
  };
  const isClickable = onClick && !(editing || editable);
  if (editing) {
    return (
      <ClickAwayListener onClickAway={onBlur}>
        <div className="capitalize border-b border-gray-300 last:border-b-0 flex flex-row group">
          <div className="p-2 pr-0 flex-1">
            <TextBox
              name={`item-${item.id}`}
              label="Thread Name"
              labelDisplay="title"
              spacing={0}
              onChange={onChange}
              value={newLabel}
              placeholder={item.label}
              autoFocus={true}
              className="capitalize"
              onKeyDown={onKeyDown}
            />
          </div>
          <IconButton tooltip={"Save"} tooltipPlacement="left" onClick={onSave}>
            <MdCheck />
          </IconButton>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <div
      className={classNames(
        "capitalize border-b border-gray-300 last:border-b-0 flex flex-row group-one",
        {
          "hover:bg-sky-100 cursor-pointer": isClickable,
        }
      )}
      onClick={isClickable ? () => onClick(item) : undefined}
    >
      {editable && selectable && (
        <CheckBox
          name="test"
          checked={item.selected || false}
          onChecked={onSelect}
          label={"Check"}
          labelDisplay="title"
          spacing={0}
          className="px-2 pt-3 border-r hover:bg-sky-50"
        />
      )}
      <div
        className={classNames("p-2 flex-1 text-base", {
          "text-gray-500": item.archived,
        })}
      >
        <span onClick={isEditable ? onEdit : undefined}>{item.label}</span>
      </div>
      {isEditable && (
        <>
          <IconButton
            tooltip="Edit"
            tooltipPlacement="left"
            onClick={onEdit}
            className="transition-opacity opacity-0 group-one-hover:opacity-100"
          >
            <MdEdit />
          </IconButton>
          <IconButton
            tooltip={
              item.archived
                ? "Restore from archive"
                : item.pending
                ? "Delete"
                : "Archive"
            }
            tooltipPlacement="left"
            onClick={onArchiveOrDelete}
            className="transition-opacity opacity-0 group-one-hover:opacity-100"
          >
            {item.archived ? (
              <MdOutlineUnarchive />
            ) : item.pending ? (
              <CgTrash />
            ) : (
              <MdOutlineArchive />
            )}
          </IconButton>
        </>
      )}
    </div>
  );
};

export default EditItem;
