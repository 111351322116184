import { useAssessedSubjects } from "services/hooks/useSubjects";
import { useStudents } from "services/hooks/useStudents";
import { useAcademicYear } from "services/providers/AcademicYearProvider";
import { useMemo } from "react";
import years from "services/years";
import {
  assessmentTerms,
  AssessmentData,
  AssessmentScore,
  AssessmentYears,
  AssessmentYear,
  ResolvedAssessmentData,
  StudentWithAssessments,
} from "types";
import { resolveAssessmentYears } from "services/utlities";
import { useAssessmentsData } from "services/hooks/useAssessments";

const EMPTY_ARRAY: StudentWithAssessments[] = [];

const useStudentsWithAssessments = (
  entryYear: number
): [StudentWithAssessments[], boolean, boolean] => {
  // Determine the academic year to load data for
  const { selected: selectedAcademicYear } = useAcademicYear();
  const targetEntryYear = entryYear ? entryYear : selectedAcademicYear.value;
  // Fetch the assessed subjects
  const [subjects, loadingSubjects, subjectsError] = useAssessedSubjects();
  // Fetch the students
  const [students, loadingStudents, studentsError] =
    useStudents(targetEntryYear);
  // Fetch the relevant assessments
  const [assessments, loadingAssessments, assessmentsError] =
    useAssessmentsData(targetEntryYear);

  return useMemo(() => {
    if (loadingStudents || loadingSubjects || loadingAssessments) {
      return [EMPTY_ARRAY, true, false];
    }
    // Otherwise
    if (studentsError || subjectsError || assessmentsError) {
      return [EMPTY_ARRAY, false, true];
    }
    const assessmentData = assessments?.assessments || {};
    // Otherwise
    if (students && Array.isArray(subjects)) {
      const studentsWithAssessments: StudentWithAssessments[] = students.map(
        (student) => {
          const assessmentsForStudent = assessmentData[student.id];
          // If subjects is an array
          const subjectAssessments = subjects.reduce<AssessmentData>(
            (current, { id }) => ({
              ...current,
              [id]: resolveAssessmentYears(assessmentsForStudent?.[id]),
            }),
            {}
          ) as ResolvedAssessmentData;
          // subjects.reduce<AssessmentYears>(
          //   (current, { id }) => ({
          //     'combined':
          //   }), {},
          // )

          const combinedAssessments = {
            ...subjectAssessments,
            combined: years.reduce<AssessmentYears>(
              (currentYears, { id: yearId }, index) => ({
                ...currentYears,
                [yearId]: assessmentTerms.reduce<AssessmentYear>(
                  (currentYear, term, index) => ({
                    ...currentYear,
                    [term]: subjects.reduce<AssessmentScore>(
                      (current, { id: subjectId }) =>
                        current < subjectAssessments[subjectId][yearId][term]
                          ? current
                          : subjectAssessments[subjectId][yearId][term],
                      AssessmentScore.GDP
                    ),
                  }),
                  {}
                ),
              }),
              {}
            ),
          } as ResolvedAssessmentData;

          console.log(
            `subject assessments for ${student.name}: `,
            combinedAssessments
          );
          return {
            ...student,
            assessments: combinedAssessments,
          };
        }
      );
      return [studentsWithAssessments, false, false];
    }
    // Otherwise
    return [EMPTY_ARRAY, false, false];
  }, [
    loadingStudents,
    loadingAssessments,
    loadingSubjects,
    studentsError,
    subjectsError,
    assessmentsError,
    assessments,
    subjects,
    students,
  ]);
};

const useAllStudentsWithAssessments = (
  academicYear?: number
): [StudentWithAssessments[], boolean, boolean] => {
  const { selected: selectedAcademicYear } = useAcademicYear();
  const targetAcademicYear = academicYear
    ? academicYear
    : Number(selectedAcademicYear.value);
  const [yearOne, yearOneLoading, yearOneError] = useStudentsWithAssessments(
    targetAcademicYear - 1
  );
  const [yearTwo, yearTwoLoading, yearTwoError] = useStudentsWithAssessments(
    targetAcademicYear - 2
  );
  const [yearThree, yearThreeLoading, yearThreeError] =
    useStudentsWithAssessments(targetAcademicYear - 3);
  const [yearFour, yearFourLoading, yearFourError] = useStudentsWithAssessments(
    targetAcademicYear - 4
  );
  const [yearFive, yearFiveLoading, yearFiveError] = useStudentsWithAssessments(
    targetAcademicYear - 5
  );
  const [yearSix, yearSixLoading, yearSixError] = useStudentsWithAssessments(
    targetAcademicYear - 6
  );
  // Determine loading and error states
  const isLoading =
    yearOneLoading ||
    yearTwoLoading ||
    yearThreeLoading ||
    yearFourLoading ||
    yearFiveLoading ||
    yearSixLoading;
  const isError =
    yearOneError ||
    yearTwoError ||
    yearThreeError ||
    yearFourError ||
    yearFiveError ||
    yearSixError;
  // Return students from each year combined
  return useMemo(() => {
    if (isLoading) {
      return [EMPTY_ARRAY, true, false];
    }
    // Otherwise
    if (isError) {
      return [EMPTY_ARRAY, false, true];
    }
    // Otherwise
    return [
      [yearOne, yearTwo, yearThree, yearFour, yearFive, yearSix].flatMap(
        (year) => year
      ),
      false,
      false,
    ];
  }, [
    yearOne,
    yearTwo,
    yearThree,
    yearFour,
    yearFive,
    yearSix,
    isLoading,
    isError,
  ]);
};

export { useStudentsWithAssessments, useAllStudentsWithAssessments };
