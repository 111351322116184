import { useCachedSchoolDocument } from "services/providers";
import { ResolvedStudentDataWithYearGroup, StudentsData } from "types";
import { useMemo } from "react";
import { useAcademicYear } from "services/providers/AcademicYearProvider";
import { toYearGroup } from "services/years";

const EMPTY_ARRAY: ResolvedStudentDataWithYearGroup[] = [];
export const useStudents = (
  entryYear?: number
): [ResolvedStudentDataWithYearGroup[], boolean, boolean] => {
  const { selected: selectedAcademicYear } = useAcademicYear();
  const selectedAcademicYearValue = selectedAcademicYear.value;
  const targetEntryYear = entryYear ? entryYear : selectedAcademicYearValue;
  const [students, loading, error] = useCachedSchoolDocument<StudentsData>(
    `students/${entryYear}`
  );
  return useMemo(() => {
    return [
      students?.students
        ? Object.entries(students.students)
            .map(([id, data]) => ({
              ...data,
              id,
              entryYear: targetEntryYear,
              yearGroup: toYearGroup(
                selectedAcademicYearValue - targetEntryYear
              ),
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        : EMPTY_ARRAY,
      loading,
      error,
    ];
  }, [selectedAcademicYearValue, targetEntryYear, students, loading, error]);
};

export const useAllStudents = (
  academicYear?: number
): [ResolvedStudentDataWithYearGroup[], boolean, boolean] => {
  const { selected: selectedAcademicYear } = useAcademicYear();
  const targetAcademicYear = academicYear
    ? academicYear
    : Number(selectedAcademicYear.value);
  const [yearOne, yearOneLoading, yearOneError] = useStudents(
    targetAcademicYear - 1
  );
  const [yearTwo, yearTwoLoading, yearTwoError] = useStudents(
    targetAcademicYear - 2
  );
  const [yearThree, yearThreeLoading, yearThreeError] = useStudents(
    targetAcademicYear - 3
  );
  const [yearFour, yearFourLoading, yearFourError] = useStudents(
    targetAcademicYear - 4
  );
  const [yearFive, yearFiveLoading, yearFiveError] = useStudents(
    targetAcademicYear - 5
  );
  const [yearSix, yearSixLoading, yearSixError] = useStudents(
    targetAcademicYear - 6
  );
  // Determine loading and error states
  const isLoading =
    yearOneLoading ||
    yearTwoLoading ||
    yearThreeLoading ||
    yearFourLoading ||
    yearFiveLoading ||
    yearSixLoading;
  const isError =
    yearOneError ||
    yearTwoError ||
    yearThreeError ||
    yearFourError ||
    yearFiveError ||
    yearSixError;
  // Return students from each year combined
  return useMemo(() => {
    if (isLoading) {
      return [EMPTY_ARRAY, true, false];
    }
    // Otherwise
    if (isError) {
      return [EMPTY_ARRAY, false, true];
    }
    // Otherwise
    return [
      [yearOne, yearTwo, yearThree, yearFour, yearFive, yearSix].flatMap(
        (year) => year
      ),
      false,
      false,
    ];
  }, [
    yearOne,
    yearTwo,
    yearThree,
    yearFour,
    yearFive,
    yearSix,
    isLoading,
    isError,
  ]);
};
