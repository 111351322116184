import React from "react";
import classNames from "classnames";
import { FaTimes } from "react-icons/fa";

const styles = [
  "bg-slate-200 border border-slate-300 text-black",
  "bg-slate-600 border border-slate-700 text-white",
  "bg-zinc-200 border border-zinc-300 text-black",
  "bg-zinc-600 border border-zinc-700 text-white",
  "bg-stone-300 border border-stone-400 text-black",
  "bg-stone-600 border border-stone-700 text-white",
  "bg-orange-200 border border-orange-300 text-black",
  "bg-yellow-200 border border-yellow-300 text-black",
  "bg-amber-600 border border-amber-700 text-white",
  "bg-lime-300 border border-lime-400 text-black",
  "bg-lime-600 border border-lime-700 text-white",
  "bg-green-300 border border-green-400 text-black",
  "bg-green-600 border border-green-700 text-white",
  "bg-emerald-300 border border-emerald-400 text-black",
  "bg-emerald-600 border border-emerald-700 text-white",
  "bg-teal-200 border border-teal-300 text-black",
  "bg-teal-600 border border-teal-700 text-white",
  "bg-cyan-200 border border-cyan-300 text-black",
  "bg-cyan-600 border border-cyan-700 text-white",
  "bg-blue-300 border border-blue-400 text-black",
  "bg-blue-600 border border-blue-700 text-white",
  "bg-indigo-200 border border-indigo-300 text-black",
  "bg-indigo-600 border border-indigo-700 text-white",
  "bg-violet-300 border border-violet-400 text-black",
  "bg-violet-600 border border-violet-700 text-white",
  "bg-purple-300 border border-purple-400 text-black",
  "bg-purple-600 border border-purple-700 text-white",
  "bg-fuchsia-300 border border-fuchsia-400 text-black",
  "bg-fuchsia-600 border border-fuchsia-700 text-white",
  "bg-pink-200 border border-pink-300 text-black",
  "bg-pink-600 border border-pink-700 text-white",
  "bg-rose-500 border border-rose-600 text-white",
];

const range = styles.length;

const toStyle = (value: string) => {
  const index = value
    .split("")
    .reduce<number>(
      (value, character) => (value + character.charCodeAt(0)) % range,
      0
    );
  return styles[index];
};

interface TagProps {
  children: string;
  size?: "regular" | "small";
  id?: string;
  onDismiss?: React.MouseEventHandler;
  className?: string;
}

const Tag = function Tag({
  className,
  onDismiss,
  children,
  id,
  size = "regular",
}: TagProps) {
  return (
    <div
      className={classNames(
        className,
        "rounded flex flex-row items-center",
        {
          "py-1 px-2": size === "regular",
          "px-1": size === "small",
        },
        toStyle(id || children)
      )}
    >
      {children}
      {onDismiss && (
        <div className="ml-2 cursor-pointer" onClick={onDismiss}>
          <FaTimes />
        </div>
      )}
    </div>
  );
};

export default Tag;
