import React from "react";
import classNames from "classnames";
import Button from "components/Button";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import IconButton from "components/IconButton";
import { usePanelContext } from "components/Panel/Panel";

export interface Action {
  name: string;
  label: React.ReactNode;
  color?: "blue" | "red" | "grey";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "secondary" | "primary";
}

interface PanelHeaderProps {
  children: React.ReactNode;
  actions?: Action[];
  className?: string;
  padding?: 0 | 1 | 2;
}

const getPadding = (padding?: 0 | 1 | 2) => {
  switch (padding) {
    case 0:
      return "p-0";
    case 1:
      return "p-1";
    case 2:
    default:
      return "p-2";
  }
};

const PanelHeader = function PanelHeader({
  children,
  actions,
  className,
  padding,
}: PanelHeaderProps) {
  const { expanded, expandable, onToggle, color } = usePanelContext();
  return (
    <div
      className={classNames(
        className,
        "border-b bg-white shadow-sm rounded-t-sm font-bold flex flex-row items-center",
        "print:border-none print:shadow-none print:p-0",
        "group-one",
        {
          "border-gray-400": color === "gray",
          "border-green-700/70": color === "green",
          "border-purple-800/70": color === "purple",
        }
      )}
    >
      <div
        className={classNames(
          "flex-1 flex flex-row print:px-0 items-center",
          getPadding(padding),
          expandable && "group-one-hover:text-sky-700 cursor-pointer",
          {
            "text-gray-900/90": color === "gray",
            "text-green-900/90": color === "green",
            "text-purple-900/90": color === "purple",
          }
        )}
        onClick={onToggle}
      >
        {children}
      </div>
      {actions && (
        <div
          className={classNames(
            "opacity-0 group-hover:opacity-100 transition-opacity py-2 print:hidden flex flex-row items-center",
            {
              "pr-2": !(expandable && onToggle),
            }
          )}
        >
          {actions.map(
            ({ name, label, color, onClick, variant = "secondary" }, index) => (
              <Button
                key={name}
                onClick={onClick}
                color={color}
                variant={variant}
                className={index > 0 ? "ml-1" : ""}
              >
                {label}
              </Button>
            )
          )}
        </div>
      )}
      {expandable && onToggle && (
        <IconButton
          onClick={onToggle}
          className="pl-3 group-one-hover:text-sky-700 print:hidden"
        >
          {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
      )}
    </div>
  );
};

export default PanelHeader;
