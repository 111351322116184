import years from "services/years";
import { useAcademicYear } from "services/providers/AcademicYearProvider";
import { useMemo } from "react";

export type Year = {
  id: "1" | "2" | "3" | "4" | "5" | "6";
  label: string;
  entryYear: number;
};

export const useYears = (): Year[] => {
  const { selected: selectedAcademicYear } = useAcademicYear();
  return useMemo<Year[]>(() => {
    return years.map<Year>((year, index) => ({
      ...year,
      entryYear: selectedAcademicYear.value - index - 1,
    }));
  }, [selectedAcademicYear.value]);
};

export const useYear = (id: string | undefined): Year | undefined => {
  const years = useYears();
  return years.find((year) => year.id === id);
};

export const usePreviousYear = (id: string | undefined): Year | undefined => {
  const years = useYears();
  const index = years.findIndex((year) => year.id === id);
  if (id && index > 0) {
    return years[index - 1];
  }
  // Otherwise
  return undefined;
};
