import React from "react";
import classNames from "classnames";
import Label from "components/Controls/Label";
import { assessmentTerms } from "types";
import years, { YearGroup } from "services/years";
import _ from "lodash";

interface TimelineProps {
  year: YearGroup;
  to: number;
  from: number;
  onChange: (from: number, to: number) => void;
  className?: string;
}

const totalTerms = years.length * assessmentTerms.length;

// Calculate the position of a given term when all terms are spaced equally
const termWidth = 100 / (totalTerms - 1);
const getTermPosition = (term: number): number => {
  return termWidth * (term - 1);
};

const getYearPositionStyle = (yearIndex: number) => {
  return {
    left: `${getTermPosition(yearIndex * assessmentTerms.length + 1)}%`,
    right: `calc(${
      100 -
      getTermPosition(
        yearIndex * assessmentTerms.length + assessmentTerms.length
      )
    }% - 12px)`,
  };
};

interface DragState {
  target: "from" | "to";
  current: number;
}

const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};

const Timeline = function Timeline({
  year,
  from,
  to,
  onChange,
  className,
}: TimelineProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [dragState, setDragState] = React.useState<DragState | null>(null);
  const yearIndex = years.findIndex((y) => y.id === year) || 0;
  const createDragHandler =
    (target: "from" | "to") => (event: React.MouseEvent) => {
      if (containerRef.current) {
        // Prevent the default action
        event.preventDefault();
        // Get the container position at the start of the drag
        const rect = containerRef.current.getBoundingClientRect();
        const minX = rect.left;
        const maxX = rect.right;
        const width = maxX - minX;
        /**
         * Create a function to return the current position of the mouse as a percentage of the container width
         * @param x - current mouse x position
         */
        const getPosition = (x: number) => {
          if (x < minX) {
            return 0;
          }
          if (x > maxX) {
            return 100;
          }
          // Otherwise
          return (x - minX) / width;
        };

        const getTerm = (position: number) => {
          const term = Math.round((position + termWidth / 400) * totalTerms);
          if (target === "to") {
            // Clamp term to be within or lower than the year group
            const yearStartTem = yearIndex * assessmentTerms.length + 1;
            const maxTerm = yearStartTem + assessmentTerms.length - 1;
            return clamp(term, from + 1, maxTerm);
          }
          // Otherwise
          return clamp(term, 1, to - 1);
        };

        // Create a throttled event listener to update the drag state
        const updateDragState = _.throttle((position: number) => {
          setDragState({
            target,
            // current: Math.round((position + termWidth / 400) * totalTerms),
            current: getTerm(position),
          });
        }, 30);

        // Mouse move handler
        const mouseMove = (event: MouseEvent) => {
          // Get the position of the mouse relative to the container
          updateDragState(getPosition(event.clientX));
        };

        // Mouse up handler
        const mouseUp = (event: MouseEvent) => {
          // Cancel the throttled event listener (to prevent updates after the mouse is released)
          updateDragState.cancel();
          // Apply the change
          if (target === "from") {
            onChange(getTerm(getPosition(event.clientX)), to);
          } else {
            onChange(from, getTerm(getPosition(event.clientX)));
          }
          // Clear the current drag state
          setDragState(null);
          // Remove the event listeners
          window.removeEventListener("mousemove", mouseMove);
          window.removeEventListener("mouseup", mouseUp);
        };

        // Attach the event listeners
        window.addEventListener("mousemove", mouseMove);
        window.addEventListener("mouseup", mouseUp);
      }
    };

  // Get the effective range (from and to) based on the drag state
  const _from = dragState?.target === "from" ? dragState.current : from;
  const _to = dragState?.target === "to" ? dragState.current : to;

  // Render the timeline control
  return (
    <div className={classNames(className, "")} ref={containerRef}>
      <Label spacing={2}>Timeline</Label>
      {/* Wrapper will reserve enough space for the final element */}
      <div className="pr-3">
        {/* Relative container will hold the progress bar and notch elements */}
        <div className="relative h-4">
          {/* Render the sliders background */}
          <div className="absolute top-1.5 left-2 -right-2 h-1 bg-gray-400" />
          {/* Render the sliders fill */}
          <div
            className="absolute top-1.5 h-1 bg-sky-700"
            style={{
              left: `calc(${getTermPosition(_from)}% + 8px)`,
              right: `calc(${100 - getTermPosition(_to)}% - 8px)`,
            }}
          />
          {/* Render the notches */}
          {years.map((year, yearIndex) =>
            assessmentTerms.map((term, termIndex) => {
              const termNumber =
                yearIndex * assessmentTerms.length + termIndex + 1;
              const selected = termNumber === _from || termNumber === _to;
              const dragging = termNumber === dragState?.current;
              const active = termNumber >= _from && termNumber <= _to;
              return (
                <div
                  className={classNames("absolute top-0 w-4 h-4 group-one", {
                    "cursor-pointer": selected,
                  })}
                  style={{
                    left: `${getTermPosition(
                      termIndex + 1 + yearIndex * assessmentTerms.length
                    )}%`,
                  }}
                  onMouseDown={
                    termNumber === _from
                      ? createDragHandler("from")
                      : termNumber === to
                      ? createDragHandler("to")
                      : undefined
                  }
                >
                  {dragging && (
                    <div className="absolute top-0 left-0 w-full h-full bg-sky-400 rounded-full animate-ping" />
                  )}
                  <div
                    className={classNames(
                      "rounded-full absolute top-0",
                      termIndex === 0 ? "h-4 w-4" : "h-2 w-2 m-1",
                      active ? "bg-sky-700" : "bg-gray-400",
                      selected
                        ? {
                            "group-one-hover:outline outline-4 outline-sky-300/50":
                              true,
                            "p-1": termIndex === 0,
                            "p-0.5": termIndex !== 0,
                          }
                        : null
                    )}
                  >
                    {selected && (
                      <div
                        className={classNames(
                          "bg-sky-400 rounded-full",
                          termIndex === 0 ? "h-2 w-2" : "h-1 w-1"
                        )}
                      />
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
        {/* Container to hold the year names */}
        <div className="relative h-4 mt-0.5">
          {/* Display the year name, under the appropriate range */}
          {years.map(({ label, id }, index) => {
            const draggable = dragState && index <= yearIndex;

            return (
              <div
                className={classNames(
                  "absolute h-4 border-l-2 border-r-2",
                  draggable ? "border-sky-700/50" : "border-gray-300"
                )}
                style={getYearPositionStyle(index)}
              >
                <div className="relative">
                  <div
                    className={classNames(
                      "absolute top-2 left-0 -right-0 h-0.5",
                      draggable ? "bg-sky-700/50" : "bg-gray-300"
                    )}
                  />
                  <div className="absolute w-full flex flex-row justify-center">
                    <div
                      className={classNames(
                        "text-xs bg-gray-200 px-1",
                        draggable ? "text-sky-700/75" : "text-gray-700"
                      )}
                    >
                      {label}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
