import { AssessmentScore } from "types";

const assessmentScoreMap: Record<string, AssessmentScore> = {
  ILP: AssessmentScore.ILP,
  WTS: AssessmentScore.WTS,
  EXS: AssessmentScore.EXS,
  GDP: AssessmentScore.GDP,
};

export const parseAssessmentScore = (grade?: string) => {
  if (grade) {
    const uppercaseGrade = grade.toUpperCase();
    if (assessmentScoreMap[uppercaseGrade] !== undefined) {
      return assessmentScoreMap[uppercaseGrade];
    }
  }
  // Otherwise
  return undefined;
};
