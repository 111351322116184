import React, { useCallback } from "react";
import _ from "lodash";
import Button from "components/Button";
import { writeSheet } from "services";
import { ResolvedStudentDataWithYearGroup } from "types";

type ExportableStudent = {
  id: string;
  name: string;
  gender: string;
  entryYear: number;
  yearGroup: number;
  class: string;
  flags: string;
};

interface ExportStudentsButtonProps {
  students: ResolvedStudentDataWithYearGroup[];
  className?: string;
}

const ExportStudentsButton = function ExportStudentsButton({
  students,
  className,
}: ExportStudentsButtonProps) {
  const exportStudents = useCallback(() => {
    console.log("students: ", students);
    const exportableStudents = students.map<ExportableStudent>(
      ({ id, name, gender, entryYear, yearGroup, classes, flags }) => ({
        id,
        name,
        gender: gender || "",
        entryYear,
        yearGroup: Number(yearGroup),
        class: _.get(classes, yearGroup, ""),
        flags: flags
          ? flags.reduce(
              (all, current) => (all ? `${all}, ${current}` : current),
              ""
            )
          : "",
      })
    );
    writeSheet(exportableStudents, "Students");
  }, [students]);
  return (
    <Button onClick={exportStudents} variant="secondary" className={className}>
      Export
    </Button>
  );
};

export default ExportStudentsButton;
