import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./services/user";
import { RecoilRoot } from "recoil";

console.log("host", window.location.origin);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <UserProvider>
        <App />
      </UserProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
