import { YearGroup } from "services/years";
import { ResolvedStudentDataWithYearGroup } from "types/students";

export enum AssessmentScore {
  ILP = 0,
  WTS = 1,
  EXS = 2,
  GDP = 3,
}

export const assessmentScores: AssessmentScore[] = [
  AssessmentScore.GDP,
  AssessmentScore.EXS,
  AssessmentScore.WTS,
  AssessmentScore.ILP,
];

export type AssessmentTerm = "1" | "2" | "3" | "4" | "5" | "6";

export const assessmentTerms: AssessmentTerm[] = ["1", "2", "3", "4", "5", "6"];

export type AssessmentYear = {
  [term in AssessmentTerm]?: AssessmentScore;
};

export type AssessmentYears = {
  [year in YearGroup]?: AssessmentYear;
};

export interface AssessmentData {
  [subjectId: string]: AssessmentYears;
}

export interface ResolvedAssessmentData {
  [subjectId: string]: {
    [year in YearGroup]: Required<AssessmentYear>;
  };
}

export interface AssessmentsData {
  assessments: {
    [studentId: string]: AssessmentData;
  };
}

export interface SubjectYearAssessments {
  [studentId: string]: AssessmentYear;
}

export interface StudentWithAssessments
  extends ResolvedStudentDataWithYearGroup {
  assessments: ResolvedAssessmentData;
}

export interface StudentWithProgress extends StudentWithAssessments {
  previousScore: AssessmentScore;
  currentScore: AssessmentScore;
}
