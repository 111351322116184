import React from "react";
import classNames from "classnames";

interface SideBarSubPanelProps {
  children: React.ReactNode;
  active?: boolean;
  className?: string;
}

const SideBarSubPanel = function SideBarSubPanel({
  className,
  active = true,
  children,
}: SideBarSubPanelProps) {
  if (!active) {
    return null;
  }
  return <div className={classNames(className, "")}>{children}</div>;
};

export default SideBarSubPanel;
