import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import ObjectivesPage from "./pages/Objectives/ObjectivesPage";
import PlanPage from "./pages/Plan/PlanPage";
import AssessmentsPage from "pages/Dashboard/pages/Assessments/AssessmentsPage";
import StudentsPage from "./pages/Students/StudentsPage";
import {
  FirebaseCache,
  ObjectivesProvider,
  PrintProvider,
  DialogProvider,
} from "services";
import classNames from "classnames";
import { AcademicYearProvider } from "services/providers/AcademicYearProvider";
import ReportsPage from "pages/Dashboard/pages/Reports/ReportsPage";
import UsersPage from "pages/Dashboard/pages/Users/UsersPage";
import { useCurrentUser } from "services/user";

const DashboardPage = function DashboardPage() {
  const { role } = useCurrentUser();
  return (
    <FirebaseCache>
      <AcademicYearProvider>
        <ObjectivesProvider>
          <PrintProvider>
            {({ title, dialogOpen }) => (
              <DialogProvider>
                <div className="w-full h-full flex flex-row print:block">
                  {/* sidebar */}
                  <Navigation printTitle={title} />
                  {/* main app */}
                  <div
                    className={classNames("flex-1 relative print:block", {
                      "print:hidden": dialogOpen,
                    })}
                  >
                    <Routes>
                      <Route path="/plan/*" element={<PlanPage />} />
                      <Route path="/students/*" element={<StudentsPage />} />
                      <Route
                        path="/assessments/*"
                        element={<AssessmentsPage />}
                      />
                      <Route path="/reports/*" element={<ReportsPage />} />

                      {role === "admin" && (
                        <>
                          <Route
                            path="/objectives/*"
                            element={<ObjectivesPage />}
                          />
                          <Route path="/users/*" element={<UsersPage />} />
                        </>
                      )}
                      <Route
                        path="*"
                        element={
                          <Navigate
                            to={role === "admin" ? "./objectives" : "./plan"}
                            replace
                          />
                        }
                      />
                    </Routes>
                  </div>
                </div>
              </DialogProvider>
            )}
          </PrintProvider>
        </ObjectivesProvider>
      </AcademicYearProvider>
    </FirebaseCache>
  );
};

export default DashboardPage;
