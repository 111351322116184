import React, { useMemo } from "react";
import Select, { SelectOption } from "components/Controls/Select";
import Timeline from "pages/Dashboard/pages/Reports/components/Timeline";
import {
  useYearClasses,
  years,
  YearGroup,
  YearWithClasses,
  getClassName,
  useAssessedSubjects,
  usePrintOptions,
  PrintOptions,
  fromTermNumber,
} from "services";
import { assessmentTerms } from "types";
import ClassProgressTimeline from "./components/ClassProgressTimeline";
import SubjectProgressBreakdown from "pages/Dashboard/pages/Reports/reports/StudentProgressReport/components/SubjectProgressBreakdown";
import { useStudentsWithAssessments } from "services/hooks/useStudentsWithAssessments";

interface ReportOptions {
  year?: YearWithClasses;
  group?: SelectOption;
  from: number;
  to: number;
}

const getYearRange = (yearGroup: YearGroup) => {
  const index = years.findIndex((year) => year.id === yearGroup);
  const from = index * assessmentTerms.length + 1;
  const to = from + assessmentTerms.length - 1;
  return { from, to };
};

interface ReportViewProps extends Omit<ReportOptions, "year" | "group"> {
  year: YearWithClasses;
  group?: string;
}

const ReportView = ({ year, group, from, to }: ReportViewProps) => {
  // Load the assessed subjects
  const [subjects] = useAssessedSubjects();
  // Attach assessment data to each student
  const [allStudentsWithAssessments] = useStudentsWithAssessments(
    year.entryYear
  );
  // Get the selected students
  const studentsWithAssessments = useMemo(() => {
    if (group) {
      return allStudentsWithAssessments.filter(
        (student) => getClassName(student, year.id) === group
      );
    }
    // Otherwise
    return allStudentsWithAssessments;
  }, [allStudentsWithAssessments, group, year.id]);
  return (
    <div className="flex-1 p-2 overflow-y-scroll print:overflow-visible">
      <ClassProgressTimeline
        key={`${year.id}-${group}-${from}-${to}`}
        students={studentsWithAssessments}
        from={from}
        to={to}
      />
      {Array.isArray(subjects) &&
        subjects.map((subject, index) => (
          <SubjectProgressBreakdown
            key={subject.id}
            students={studentsWithAssessments}
            subject={subject}
            from={from}
            to={to}
            className="break-after-page break-inside-avoid"
          />
        ))}
    </div>
  );
};

const getReportRange = (reportOptions: ReportOptions) => {
  const [fromYear, fromTerm] = fromTermNumber(reportOptions.from);
  const [toYear, toTerm] = fromTermNumber(reportOptions.to);
  if (fromYear !== toYear || fromYear !== reportOptions.year?.id) {
    return `Term ${fromYear}-${fromTerm} to ${toYear}-${toTerm}`;
  }
  // Otherwise
  return `Term ${fromTerm} to ${toTerm}`;
};

const getReportSubTitle = (reportOptions: ReportOptions) => {
  if (reportOptions.year) {
    if (reportOptions.group) {
      return `${reportOptions.group.label} (Year ${reportOptions.year.id})`;
    }
    return `Year ${reportOptions.year.id}`;
  }
  return "";
};

const getReportTitle = (reportOptions: ReportOptions) => {
  const subTitle = getReportSubTitle(reportOptions);
  const range = getReportRange(reportOptions);
  return ["Student Progress Report", subTitle, range]
    .filter(Boolean)
    .join(" - ");
};

const StudentProgressReport = function StudentProgressReport() {
  const years = useYearClasses();
  const [reportOptions, setReportOptions] = React.useState<ReportOptions>({
    year: undefined,
    group: undefined,
    from: 0,
    to: 0,
  });
  usePrintOptions(
    useMemo<PrintOptions>(
      () => ({
        title: getReportTitle(reportOptions),
      }),
      [reportOptions]
    )
  );
  const classes = useMemo(() => {
    if (reportOptions.year) {
      return [
        ...reportOptions.year.classes
          .filter((groupName: string) => !!groupName)
          .map((groupName: string) => ({
            id: groupName,
            label: groupName,
          })),
      ];
    }
    return [];
  }, [reportOptions.year]);

  return (
    <>
      <div className="bg-gray-200 border-b border-gray-300 print:hidden">
        <div className="flex flex-row p-1">
          <Select
            name="year"
            label="Year Group"
            placeholder="Select a year group"
            options={years}
            value={reportOptions.year || null}
            onChange={(year: YearWithClasses) =>
              setReportOptions({
                year,
                group: undefined,
                ...getYearRange(year.id),
              })
            }
            labelClassName="flex-1 m-1"
          />
          <Select
            name="class"
            label="Class"
            placeholder="All Classes"
            options={classes}
            value={reportOptions.group || null}
            onChange={(selectedClass) =>
              setReportOptions((current) => ({
                ...current,
                group: selectedClass,
              }))
            }
            labelClassName="flex-1 m-1"
          />
        </div>
        <div className="p-2">
          <Timeline
            year={reportOptions.year?.id || "1"}
            from={reportOptions.from}
            to={reportOptions.to}
            onChange={(from, to) =>
              setReportOptions((current) => ({
                ...current,
                from: from || 0,
                to,
              }))
            }
          />
        </div>
      </div>
      {reportOptions.year ? (
        <ReportView
          year={reportOptions.year}
          group={reportOptions.group?.id}
          from={reportOptions.from}
          to={reportOptions.to}
        />
      ) : (
        <div className="p-16 text-center text-gray-600">
          Please select a year group to view the progress report
        </div>
      )}
    </>
  );
};

export default StudentProgressReport;
