import React from "react";
import { AssessmentScore } from "types";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdNorthEast,
  MdSouthEast,
} from "react-icons/md";
import { assessmentScoreName } from "services/utlities/assessmentScoreName";
import classNames from "classnames";

const getTrendIcon = (
  score?: AssessmentScore,
  expectedScore?: AssessmentScore
) => {
  if (score !== undefined && expectedScore !== undefined) {
    if (score > expectedScore) {
      return <MdNorthEast className="text-green-700" />;
    }
    // Otherwise
    if (score < expectedScore) {
      return <MdSouthEast className="text-red-700" />;
    }
  }
  // Otherwise
  return null;
};

interface AssessmentScoreDisplayProps {
  score?: AssessmentScore;
  expectedScore: AssessmentScore;
  previousScore?: AssessmentScore;
  onChange: (score: AssessmentScore) => void;
  className?: string;
}

const AssessmentScoreDisplay = function AssessmentScoreDisplay({
  score,
  expectedScore,
  previousScore,
  onChange,
}: AssessmentScoreDisplayProps) {
  const effectiveScore = score !== undefined ? score : expectedScore;
  const increment = () => {
    if (effectiveScore < 3) {
      onChange(effectiveScore + 1);
    }
  };
  const decrement = () => {
    if (effectiveScore > 0) {
      onChange(effectiveScore - 1);
    }
  };
  const active = score !== undefined;
  const buttonClasses = (enabled: boolean) =>
    classNames(active ? "border-sky-600" : "border-gray-300 text-gray-400", {
      "bg-sky-600 text-white/90": active,
      "bg-slate-100": !active,
      "hover:bg-sky-700 hover:border-sky-700 hover:text-white": enabled,
      "cursor-auto": !enabled,
    });
  const scoreClasses = classNames(
    "text-sm py-0.5 px-1 border-t border-b w-10 text-center overflow-hidden"
  );
  return (
    <div className="flex flex-row items-center">
      <div className={classNames("flex flex-row items-stretch")}>
        <button
          className={classNames(
            "border rounded-l text-lg",
            buttonClasses(effectiveScore > 0)
          )}
          onClick={decrement}
        >
          <MdArrowDropDown />
        </button>
        {score === undefined ? (
          <button
            className={classNames(
              scoreClasses,
              "border-gray-300 hover:text-sky-700"
            )}
            onClick={() => onChange(expectedScore)}
          >
            {assessmentScoreName(effectiveScore)}
          </button>
        ) : (
          <div className={classNames(scoreClasses, "border-sky-600")}>
            {assessmentScoreName(effectiveScore)}
          </div>
        )}
        <button
          className={classNames(
            "border rounded-r text-lg",
            buttonClasses(effectiveScore < 3)
          )}
          onClick={increment}
        >
          <MdArrowDropUp />
        </button>
      </div>
      <div className="w-4 pl-1">{getTrendIcon(score, previousScore)}</div>
    </div>
  );
};

export default AssessmentScoreDisplay;
