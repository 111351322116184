import React, { useCallback, useMemo, useState } from "react";
import TextBox, { TextBoxProps } from "components/Controls/TextBox";
import classNames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";
import _ from "lodash";

export interface SelectOption {
  id: string;
  label: string;
  group?: string;
  active?: boolean;
  complete?: boolean | React.ReactNode;
}

interface SelectProps<T extends SelectOption>
  extends Omit<TextBoxProps, "onChange" | "value"> {
  value: T | null;
  options: T[];
  onChange: (value: T) => void;
  row?: boolean;
  grouped?: boolean;
}

const MENU_OFFSET: [number, number] = [0, 0];
const DURATION: [number, number] = [275, 0];

const POPPER_OPTIONS: TippyProps["popperOptions"] = {
  modifiers: [
    {
      name: "sameWidth",
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        state.styles.popper.maxWidth = `${state.rects.reference.width}px`;
      },
      phase: "beforeWrite",
      requires: ["computeStyles"],
      effect: ({ state }) => {
        const target = state.elements.reference as Element;
        state.elements.popper.style.minWidth = `${target.clientWidth}px`;
        state.elements.popper.style.maxWidth = `${target.clientWidth}px`;
      },
    },
  ],
};

const Select = React.forwardRef(function InnerSelect<
  T extends SelectOption = SelectOption
>(
  {
    value,
    options,
    onChange,
    grouped = false,
    row = false,
    className,
    placeholder,
    ...others
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [search, setSearch] = useState("");
  const closeMenu = useCallback(() => {
    setMenuOpen(false);
    setSearch("");
  }, [setMenuOpen, setSearch]);
  const activeOptions = useMemo(
    () => options.filter(({ active }) => active !== false),
    [options]
  );
  const sortedOptions = useMemo(
    () =>
      _.sortBy(activeOptions, [
        "group",
        ({ complete }) => !!complete,
        ({ label }) => label.toLowerCase(),
      ]),
    [activeOptions]
  );
  const filteredOptions = useMemo(() => {
    if (search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();
      return sortedOptions.filter(
        (option) =>
          option.label.toLowerCase().indexOf(lowerCaseSearch) > -1 ||
          (option.group &&
            option.group.toLowerCase().indexOf(lowerCaseSearch) > -1)
      );
    }
    // Otherwise
    return sortedOptions;
  }, [sortedOptions, search]);
  const groupedOptions = useMemo(() => {
    if (grouped) {
      return _.sortBy(
        Object.entries(_.groupBy(filteredOptions, "group")).map(
          ([group, options], index) => ({
            group,
            options,
          })
        ),
        "group"
      );
    }
    // Otherwise
    return null;
  }, [filteredOptions, grouped]);
  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        if (event.target.value) {
          onChange(filteredOptions[0]);
          closeMenu();
          event.target.blur();
        }
        event.preventDefault();
      }
    },
    [filteredOptions, onChange, closeMenu]
  );

  const currentDisplayValue = value ? value.label : "";

  const onItemClick = useCallback(
    (option) => {
      onChange(option);
      closeMenu();
    },
    [onChange, closeMenu]
  );

  return (
    <Tippy
      content={useMemo(() => {
        if (grouped && groupedOptions) {
          return groupedOptions.map(({ group, options }, groupIndex) => (
            <React.Fragment key={group}>
              <div className="p-2 text-sm font-semibold text-sky-900 border-b border-gray-300 bg-white sticky top-0">
                {group}
              </div>
              {options.map((option, optionIndex) => (
                <div
                  className="p-1 group cursor-pointer"
                  key={option.id}
                  onClick={() => onItemClick(option)}
                >
                  <div
                    className={classNames(
                      "p-1 rounded group-hover:bg-sky-200",
                      {
                        "bg-sky-100":
                          option === value ||
                          (search && groupIndex === 0 && optionIndex === 0),
                        "text-gray-500": option.complete,
                      }
                    )}
                  >
                    {option.label}
                    {React.isValidElement(option.complete) && option.complete}
                  </div>
                </div>
              ))}
            </React.Fragment>
          ));
        }
        // Otherwise
        return filteredOptions.map((option) => (
          <div
            className="p-1 group cursor-pointer"
            key={option.id}
            onClick={() => onItemClick(option)}
          >
            <div
              className={classNames("p-1 rounded group-hover:bg-sky-200", {
                "bg-sky-100": option === value,
                "text-green-700": option.complete,
              })}
            >
              {option.label}
              {React.isValidElement(option.complete) && option.complete}
            </div>
          </div>
        ));
      }, [
        grouped,
        groupedOptions,
        search,
        onItemClick,
        value,
        filteredOptions,
      ])}
      theme="menu"
      arrow={false}
      visible={menuOpen}
      placement="bottom-start"
      offset={MENU_OFFSET}
      popperOptions={POPPER_OPTIONS}
      interactive={true}
      onClickOutside={closeMenu}
      appendTo={document.body}
      maxWidth="none"
      duration={DURATION}
    >
      <TextBox
        {...others}
        onKeyDown={onKeyDown}
        autoComplete="off"
        className={classNames(className, "cursor-pointer", {
          "rounded-b-none": menuOpen,
        })}
        onFocus={() => setMenuOpen(true)}
        value={menuOpen ? search : currentDisplayValue}
        placeholder={currentDisplayValue || placeholder}
        onChange={(event) => setSearch(event.target.value)}
        row={row}
        ref={ref}
      />
    </Tippy>
  );
}) as <T extends SelectOption = SelectOption>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => JSX.Element;

export default Select;
export type { SelectProps };
