import { useSubjects } from "services/hooks/useSubjects";
import { Subject } from "types";
import _ from "lodash";

export const useResolveSubjects = () => {
  const [subjects] = useSubjects();
  return (subjectIds: string[]): Subject[] => {
    return subjectIds
      .map((id) => _.find(subjects, { id }))
      .filter((subject) => subject !== undefined) as Subject[];
  };
};
