import React from "react";
import { Panel } from "components";
import { useParams } from "react-router-dom";
import { useIsSyncing, useSubject, useYear, Year } from "services";
import { useAssessments } from "services/hooks/useAssessments";
import AssessmentScoreDisplay from "./AssessmentScoreDisplay";
import {
  AssessmentScore,
  AssessmentTerm,
  assessmentTerms,
  AssessmentYear,
} from "types";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useStudentsWithAssessments } from "services/hooks/useStudentsWithAssessments";
import classNames from "classnames";

const getParams = ({
  yearId = "",
  className = "",
  subjectId = "",
}): { yearId: string; className: string; subjectId: string } => ({
  yearId,
  className,
  subjectId,
});

const getAssessmentScore = (
  term: AssessmentTerm,
  assessments?: AssessmentYear,
  expectedAssessments?: AssessmentYear
) => {
  if (!assessments || assessments[term] === undefined) {
    if (expectedAssessments) {
      return expectedAssessments[term];
    }
  }
  // Otherwise
  return assessments && assessments[term];
};

const SubjectAssessments = function SubjectAssessments() {
  const { yearId, className, subjectId } = getParams(useParams());
  const year = useYear(yearId) as Year;
  const [subject] = useSubject(subjectId);
  const [yearGroupStudents] = useStudentsWithAssessments(year?.entryYear);
  const students = yearGroupStudents.filter(
    (student) => student.classes?.[yearId] === className
  );
  const [assessments, setAssessments] = useAssessments(year, subjectId);
  console.log("assessments: ", assessments);
  console.log("students: ", yearGroupStudents);
  const isSyncing = useIsSyncing();
  const setStudentAssessmentScore = (
    studentId: string,
    term: AssessmentTerm,
    score: AssessmentScore
  ) => {
    setAssessments((current) => {
      return {
        ...current,
        [studentId]: {
          ...current[studentId],
          [term]: score,
        },
      };
    });
  };
  return (
    <>
      <div className="mb-2 print:hidden sticky flex flex-row">
        <div className="text-lg">
          Class {className}
          {subject && ` - ${subject.name}`}
        </div>
        {isSyncing && (
          <>
            <div className="flex-1" />
            <div className="text-sky-700 animate-pulse">
              <MdOutlineCloudUpload className="inline-block" />
              <span className="pl-1 text-sm">Saving changes...</span>
            </div>
          </>
        )}
      </div>
      <Panel className="h-full overflow-auto">
        <table className="border-separate border-spacing-0">
          <thead className="sticky top-0 shadow-sm">
            <tr className="bg-gray-50 text-left">
              <th className="p-2 font-normal text-black border-b border-r border-gray-400">
                Name
              </th>
              <th className="p-2 font-normal text-black border-b border-r border-gray-400">
                AP1
              </th>
              <th className="p-2 font-normal border-b border-r border-gray-400 ">
                AP2
              </th>
              <th className="p-2 font-normal border-b border-r border-gray-400">
                AP3
              </th>
              <th className="p-2 font-normal border-b border-r border-gray-400 ">
                AP4
              </th>
              <th className="p-2 font-normal border-b border-r border-gray-400 ">
                AP5
              </th>
              <th className="p-2 font-normal border-b border-gray-400 ">AP6</th>
            </tr>
          </thead>
          <tbody>
            {students.map(({ name, id, assessments: expectedAssessments }) => {
              const studentAssessment = assessments[id];
              const expectedAssessment =
                expectedAssessments[subjectId][year.id];
              return (
                <tr key={id} className="bg-white">
                  <td className="p-2 bg-gray-50 text-black border-b border-r border-gray-400">
                    <div>{name}</div>
                  </td>
                  {assessmentTerms.map((term, index) => {
                    const score = studentAssessment && studentAssessment[term];
                    const previousScore =
                      index > 0
                        ? getAssessmentScore(
                            assessmentTerms[index - 1],
                            studentAssessment,
                            expectedAssessment
                          )
                        : undefined;
                    return (
                      <td
                        key={term}
                        className={classNames(
                          "p-2 text-gray-600 border-b border-r border-gray-300"
                        )}
                      >
                        <AssessmentScoreDisplay
                          score={score}
                          expectedScore={expectedAssessment[term]}
                          previousScore={previousScore}
                          onChange={(newScore) =>
                            setStudentAssessmentScore(id, term, newScore)
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Panel>
    </>
  );
};

export default SubjectAssessments;
