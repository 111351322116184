import TextBox from "components/Controls/TextBox";
import logo from "assets/logo.png";
import Button from "../components/Button";
import CheckBox from "../components/Controls/CheckBox";
import { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
  sendSignInLink,
  signInWithLink,
  useCurrentUser,
} from "../services/user";
import { GoMailRead } from "react-icons/go";
import { CgSpinner } from "react-icons/cg";

const LoginState = function LoginState() {
  const [email, setEmail] = useState("");
  const onChangeEmail = useCallback(
    (event) => {
      setEmail(event.target.value);
    },
    [setEmail]
  );
  const [persist, setPersist] = useState(true);
  const onCheckedPersist = useCallback(() => {
    setPersist((current) => !current);
  }, [setPersist]);
  const [progressState, setProgressState] = useState({
    sending: false,
    sent: false,
  });
  const onComplete = useCallback(() => {
    setProgressState((current) => ({ ...current, sending: true }));
    sendSignInLink(email).then(
      () => {
        setProgressState((current) => ({ sent: true, sending: false }));
      },
      (error) => {
        console.error(error);
      }
    );
  }, [email, setProgressState]);

  if (progressState.sent) {
    return (
      <div className="w-full flex flex-col items-center text-center">
        <GoMailRead className="text-3xl mb-2 text-sky-700" />
        We've sent you an email with a login link, please check your inbox to
        continue
      </div>
    );
  }
  // Otherwise
  return (
    <>
      <TextBox
        name="email"
        label="Email"
        onChange={onChangeEmail}
        disabled={progressState.sending}
      />
      <CheckBox
        name="persist"
        label="Stay Signed In?"
        onChecked={onCheckedPersist}
        checked={persist}
        disabled={progressState.sending}
        helperText={"(Not recommended on a shared device)"}
      />
      <Button onClick={onComplete} loading={progressState.sending}>
        Sign-in
      </Button>
    </>
  );
};

const CompleteState = function CompleteState() {
  const navigate = useNavigate();
  useEffect(() => {
    signInWithLink().then(
      () => {
        navigate("/app");
      },
      (error) => {
        console.error(error);
      }
    );
  }, [navigate]);

  return (
    <div className="w-full flex justify-center">
      <CgSpinner className="text-2xl text-sky-700 animate-spin" />
    </div>
  );
};

const AuthState = function AuthState() {
  return (
    <>
      <div className="text-xl my-3">Not Authorised</div>
      <div className="w-full flex flex-col items-center text-center">
        Please ask your school administrator to grant you access to this
        application
      </div>
    </>
  );
};

const LoginPage = function LoginPage() {
  const { isLoggedIn, isAuthorized } = useCurrentUser();
  if (isLoggedIn && isAuthorized) {
    return <Navigate to="/app" replace />;
  }
  return (
    <div className="h-full w-full bg-slate-200 flex flex-col items-center justify-center">
      <img src={logo} className="w-40" alt="school logo" />
      {isLoggedIn ? (
        <AuthState />
      ) : (
        <>
          <div className="text-xl my-3">Sign-in to your account</div>
          <div className="w-full max-w-md bg-white border rounded-md drop-shadow border-gray-400 p-3">
            <Routes>
              <Route path="/" element={<LoginState />} />
              <Route path="/complete" element={<CompleteState />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;
