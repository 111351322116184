import { SetSyncDocument, useCachedSchoolDocument } from "services/providers";
import { AssessmentsData, SubjectYearAssessments } from "types/assessments";
import { Year } from "services/hooks/useYears";
import _ from "lodash";

export const useAssessmentsData = (entryYear: number) => {
  // Fetch the assessments for the given year
  return useCachedSchoolDocument<AssessmentsData>(`assessments/${entryYear}`);
};

const toSubjectYearAssessments = (
  assessmentsData: AssessmentsData | undefined,
  year: Year,
  subjectId: string
): SubjectYearAssessments => {
  if (assessmentsData) {
    return Object.entries(assessmentsData.assessments).reduce(
      (current, [studentId, assessmentData]) => {
        return {
          ...current,
          [studentId]: assessmentData[subjectId]
            ? assessmentData[subjectId][year.id]
            : {},
        };
      },
      {}
    );
  }
  // Otherwise
  return {};
};

const fromSubjectYearAssessments = (
  subjectYearAssessments: SubjectYearAssessments,
  year: Year,
  subjectId: string
): AssessmentsData => {
  return {
    assessments: Object.entries(subjectYearAssessments).reduce(
      (current, [studentId, assessmentYear]) => {
        return {
          ...current,
          [studentId]: {
            [subjectId]: {
              [year.id]: assessmentYear,
            },
          },
        };
      },
      {}
    ),
  };
};

export const useAssessments = (
  year: Year,
  subjectId: string
): [SubjectYearAssessments, SetSyncDocument<SubjectYearAssessments>] => {
  const [assessments, , , setAssessments] = useAssessmentsData(year.entryYear);

  const subjectAssessments: SubjectYearAssessments = assessments
    ? Object.entries(assessments.assessments).reduce(
        (current, [studentId, assessmentData]) => {
          return {
            ...current,
            [studentId]: assessmentData[subjectId]
              ? assessmentData[subjectId][year.id]
              : {},
          };
        },
        {}
      )
    : {};

  const updateSubjectAssessments: SetSyncDocument<SubjectYearAssessments> = (
    update
  ) => {
    setAssessments((currentAssessments) => {
      const currentSubjectYearAssessments = toSubjectYearAssessments(
        currentAssessments,
        year,
        subjectId
      );
      const newSubjectYearAssessments =
        typeof update === "function"
          ? update(currentSubjectYearAssessments)
          : update;
      const newAssessments = _.merge(
        {},
        currentAssessments,
        fromSubjectYearAssessments(newSubjectYearAssessments, year, subjectId)
      );
      return newAssessments;
    });
  };
  return [subjectAssessments, updateSubjectAssessments];
};

export const useAllAssessments = (academicYear?: number) => {};
