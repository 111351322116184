import { AssessmentData, AssessmentScore, assessmentTerms } from "types";
import years from "services/years";

export const getAssessmentTermScore = (
  assessment: AssessmentData,
  subjectId: string,
  term: number
): AssessmentScore => {
  if (assessment[subjectId]) {
    const yearIndex = Math.ceil(term / assessmentTerms.length) - 1;
    const year = years[yearIndex];
    if (year && assessment[subjectId][year.id]) {
      const assessmentYear = assessment[subjectId][year.id];
      const termIndex = (term - 1) % assessmentTerms.length;
      const assessmentTerm = assessmentTerms[termIndex];
      if (assessmentYear && assessmentTerm) {
        const score = assessmentYear[assessmentTerm];
        if (score !== undefined) {
          return score;
        }
      }
    }
  }
  // Otherwise
  console.warn("Assessment term score not found");
  return AssessmentScore.EXS;
};
