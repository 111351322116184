import * as XLSX from "xlsx";

const writeSheet = (
  records: Record<string, string | number>[],
  title: string
): void => {
  const worksheet = XLSX.utils.json_to_sheet(records);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  XLSX.writeFile(workbook, `${title}.xlsx`, { compression: true });
};

export default writeSheet;
