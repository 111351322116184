import { useAllStudents } from "services/hooks/useStudents";
import { useYears, Year } from "services/hooks/useYears";
import { useMemo } from "react";
import { ResolvedStudentData } from "types";
import { YearGroup } from "services/years";

export interface YearWithClasses extends Year {
  classes: string[];
}

const getClassName = (
  student: ResolvedStudentData,
  yearId: YearGroup
): string => {
  if (student.classes && student.classes[yearId]) {
    return student.classes[yearId];
  }
  // Otherwise return an empty string (no class)
  return "";
};

export const useYearClasses = () => {
  const [students] = useAllStudents();
  const years = useYears();
  return useMemo<YearWithClasses[]>(
    () =>
      years.map(({ id, label, entryYear }) => ({
        id,
        label,
        entryYear,
        classes: students
          .reduce<string[]>((classes, student) => {
            // Only use students from the correct year
            if (student.entryYear === entryYear) {
              // Get their current class
              const className = getClassName(student, id);
              // And the class isn't already in the list
              if (!classes.includes(className)) {
                // Add the class to the list
                return [...classes, className];
              }
            }
            return classes;
          }, [])
          .sort(),
      })),
    [students, years]
  );
};
