import React, { useCallback, useMemo, useState } from "react";
import {
  FilterOptions,
  FilterSelectOption,
  FilterValue,
} from "components/Filters/types";
import { SelectOption } from "components/Controls/Select";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import _ from "lodash";

interface OptionListProps {
  type: string;
  options: FilterSelectOption[];
  onSelect: (filter: FilterValue) => unknown;
}

const OptionList = ({ type, options, onSelect }: OptionListProps) => {
  return (
    <>
      {options.map(({ id, label }) => (
        <div
          key={id}
          className="p-1 group-one cursor-pointer"
          onClick={() => onSelect({ type, id })}
        >
          <div className="p-1 group-one-hover:bg-sky-100 rounded">{label}</div>
        </div>
      ))}
    </>
  );
};

interface ObjectiveOption {
  id: string;
  label: string;
}

interface GroupedObjectives {
  [group: string]: {
    id: string;
    label: string;
    options: ObjectiveOption[];
  };
}

const GroupedOptionList = ({ type, options, onSelect }: OptionListProps) => {
  const groups = useMemo(
    () =>
      Object.values(
        options.reduce<GroupedObjectives>(
          (current, { id, label, subgroup, subgroupId }) => ({
            ...current,
            [subgroupId || "ungrouped"]: {
              ...current[subgroupId || "ungrouped"],
              id: subgroupId || "ungrouped",
              label: subgroup || "Ungrouped",
              options: [
                ...(current[subgroupId || "ungrouped"]?.options || []),
                { id, label },
              ],
            },
          }),
          {}
        )
      ),
    [options]
  );
  return (
    <>
      {groups.map(({ id, label, options: groupOptions }) => (
        <>
          <div
            className={classNames(
              "flex items-center h-8",
              "border-b border-gray-300 bg-white",
              "p-2 text-md text-gray-500",
              "sticky top-20",
              {
                "cursor-pointer hover:bg-sky-100 hover:text-sky-800":
                  id !== "ungrouped",
              }
            )}
            onClick={
              id !== "ungrouped"
                ? () => onSelect({ type: `${type}-group`, id })
                : undefined
            }
          >
            {label}
          </div>
          <OptionList type={type} options={groupOptions} onSelect={onSelect} />
        </>
      ))}
    </>
  );
};

interface OptionsProps {
  type: string;
  options: SelectOption[];
  onSelect: (filter: FilterValue) => unknown;
  subGrouped: boolean;
}

const Options = ({ type, options, onSelect, subGrouped }: OptionsProps) => {
  if (subGrouped) {
    return (
      <GroupedOptionList type={type} options={options} onSelect={onSelect} />
    );
  }
  // Otherwise
  return <OptionList type={type} options={options} onSelect={onSelect} />;
};

interface AddFilterProps {
  filters: FilterOptions[];
  onSelect: (filter: FilterValue) => unknown;
  className?: string;
}

const AddFilter = function AddFilter({
  filters,
  onSelect,
  className,
}: AddFilterProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const onSearchChange = useCallback(
    (event) => setSearch(event.target.value),
    [setSearch]
  );
  const closeMenu = useCallback(() => {
    setOpen(false);
    setSearch("");
  }, [setOpen, setSearch]);
  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Tab") {
        closeMenu();
      }
    },
    [closeMenu]
  );
  const onOptionSelect = useCallback(
    (item) => {
      closeMenu();
      onSelect(item);
    },
    [closeMenu, onSelect]
  );
  const options = useMemo(() => {
    const lowerCaseSearch = search.trim().toLowerCase();
    const filteredOptions: FilterOptions[] =
      lowerCaseSearch.length > 0
        ? filters
            .map(({ type, label, labelPlural, options }) => ({
              type,
              label,
              labelPlural,
              options: options.filter(
                ({ label }) => label.toLowerCase().indexOf(lowerCaseSearch) > -1
              ),
            }))
            .filter(({ options }) => options.length > 0)
        : filters;
    const activeFilteredOptions = filteredOptions.filter(
      ({ options }) => options.length > 0
    );
    return (
      <div className="max-h-96 overflow-y-auto relative">
        {activeFilteredOptions.map(
          ({ type, labelPlural, options, grouped, subGrouped }) => (
            <React.Fragment key={type}>
              <div
                className={classNames(
                  "flex items-center h-10",
                  "border-t border-gray-300 first:border-t-0 first:rounded-t bg-white",
                  "p-2 text-md text-sky-900 font-semibold",
                  "sticky top-0",
                  {
                    "border-b": !grouped,
                  }
                )}
              >
                {labelPlural}
              </div>
              {grouped ? (
                Object.entries(_.groupBy(options, "group")).map(
                  ([group, groupOptions]) => (
                    <React.Fragment key={group}>
                      <div
                        className={classNames(
                          "flex items-center h-10",
                          "border-b border-t border-gray-300 first:border-t-0 first:rounded-t bg-white",
                          "p-2 text-md text-sky-800",
                          "sticky top-10 -mt-px"
                        )}
                      >
                        {group}
                      </div>
                      <Options
                        type={type}
                        options={groupOptions}
                        onSelect={onOptionSelect}
                        subGrouped={subGrouped === true}
                      />
                    </React.Fragment>
                  )
                )
              ) : (
                <Options
                  type={type}
                  options={options}
                  onSelect={onOptionSelect}
                  subGrouped={false}
                />
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }, [search, filters, onOptionSelect]);
  return (
    <Tippy
      content={options}
      visible={open}
      placement="bottom"
      theme="white"
      onClickOutside={closeMenu}
      interactive
    >
      <div
        className={classNames(
          "border border-gray-400 bg-gray-50 rounded px-2 py-1 text-sm m-0.5 max-w-xs",
          className
        )}
        onClick={() => setOpen(true)}
      >
        {open ? (
          <input
            id="edit-filter"
            name="edit-filter"
            type="text"
            title="Add Filter"
            value={search}
            onChange={onSearchChange}
            onKeyDown={onKeyDown}
            className="outline-none"
            autoFocus={true}
          />
        ) : (
          <span className="text-gray-500">+ Add Filter</span>
        )}
      </div>
    </Tippy>
  );
};

export default AddFilter;
