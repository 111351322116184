import React from "react";
import classNames from "classnames";
import { FaCircle, FaRegCircle } from "react-icons/fa";

interface ProgressCircleProps {
  progress?: number;
  size?: "regular" | "large";
  className?: string;
}

const ProgressCircle = function ProgressCircle({
  progress = 0,
  size = "regular",
}: ProgressCircleProps) {
  const progressDegrees = `${Math.floor(progress * 360)}deg`;
  const iconStyle = classNames(
    size === "regular" ? "w-3 h-3 m-0.5" : "w-4 h-4",
    "text-sky-700"
  );
  if (progress < 1 && progress > 0) {
    return (
      <div
        className={classNames("relative", {
          "w-3 h-3 m-0.5": size === "regular",
          "w-4 h-4": size === "large",
        })}
      >
        {progress < 1 && progress > 0 && (
          <div
            className="h-full w-full rounded-full"
            style={{
              borderRadius: "50%",
              background: `conic-gradient(#0E7490 ${progressDegrees}, white ${progressDegrees})`,
            }}
          />
        )}
        <FaRegCircle
          className={classNames("absolute inset-0 w-full h-full text-sky-700")}
        />
      </div>
    );
  }
  // Otherwise
  if (progress >= 1) {
    return <FaCircle className={iconStyle} />;
  }
  // Otherwise
  return <FaRegCircle className={iconStyle} />;
};

export default ProgressCircle;
