import React, { useMemo, useState } from "react";
import Panel from "components/Panel/Panel";
import PanelHeader from "components/Panel/PanelHeader";
import PanelBody from "components/Panel/PanelBody";
import { List, ListItem } from "types";
import Button from "components/Button";
import { GroupedItems, PendingList, PendingListItem } from "./types";
import EditItem from "components/EditList/EditItem";
import { MdClose, MdOutlineLabel } from "react-icons/md";
import Tippy from "@tippyjs/react";
import _ from "lodash";
import TextBox from "components/Controls/TextBox";
import EditGroupsDialog from "components/EditList/EditGroupsDialog";
import Dialog from "components/Dialog";
import IconButton from "components/IconButton";
import { v4 as uuid4 } from "uuid";
import { formatItem } from "./utilities";

interface EditListProps {
  title: string;
  items: List;
  onEdit?: (list: List) => unknown;
  onItemSelect?: (item: ListItem) => unknown;
  groups?: List;
  onGroupsEdit?: (list: List) => unknown;
  onSelect?: (item: ListItem) => unknown;
  dialog?: boolean;
  onClose?: () => unknown;
  type?: string;
}

interface ResolvedGroups {
  [id: string]: { id: string; label: string; items: PendingListItem[] };
  ungrouped: { id: "ungrouped"; label: "Ungrouped"; items: PendingListItem[] };
}

interface EditState {
  saving: boolean;
  error?: unknown;
}

const EditList = function EditList({
  title,
  items,
  onEdit,
  onItemSelect,
  groups,
  onGroupsEdit,
  dialog = false,
  onClose,
  type = "Items",
}: EditListProps) {
  const [pendingItems, setPendingItems] = useState<PendingList | null>(null);
  const activeItems = useMemo(
    () =>
      pendingItems !== null
        ? pendingItems
        : items.filter(({ archived }) => !archived),
    [pendingItems, items]
  );
  const orderedItems = useMemo(
    () =>
      _.sortBy(activeItems, [
        ({ archived }) => (archived ? 1 : 0),
        ({ label }) => `${label}`.toLowerCase(),
      ]),
    [activeItems]
  );
  const onStartEdit = () => {
    setPendingItems(items);
  };
  const onCancel = () => {
    setPendingItems(null);
    setSearch("");
  };
  const [state, setState] = useState<EditState>({
    saving: false,
  });
  const onSave = async () => {
    if (pendingItems && onEdit) {
      setState({ saving: true });
      try {
        await onEdit(pendingItems.map(({ pending, ...item }) => item));
        setState({ saving: false });
        setPendingItems(null);
        setSearch("");
      } catch (error) {
        setState({
          saving: false,
          error,
        });
      }
    }
  };
  const onItemUpdate = (item: PendingListItem) => {
    setPendingItems((currentItems) =>
      currentItems !== null
        ? currentItems.map((currentItem) => {
            if (currentItem.id === item.id) {
              return item;
            }
            // Otherwise
            return currentItem;
          })
        : null
    );
  };
  const onItemDelete = (itemId: string) => {
    setPendingItems((currentItems) =>
      currentItems === null
        ? null
        : currentItems.filter(({ id }) => id !== itemId)
    );
  };
  const itemsSelected = useMemo(() => {
    if (pendingItems === null) {
      return false;
    }
    // Otherwise
    return pendingItems.filter(({ selected }) => !!selected).length > 0;
  }, [pendingItems]);
  const [search, setSearch] = useState("");
  const filteredItems = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();
    return orderedItems.filter(
      ({ label }) => `${label}`.toLowerCase().indexOf(lowerCaseSearch) > -1
    );
  }, [orderedItems, search]);
  const groupedItems = useMemo<GroupedItems[]>(() => {
    // Attempt to group items?
    if (groups) {
      const grouped = Object.values(
        Object.entries(
          _.groupBy(filteredItems, "group")
        ).reduce<ResolvedGroups>(
          (currentGroupedItems, [groupId, items]) => {
            const group = _.find(groups, { id: groupId });
            if (group) {
              return {
                ...currentGroupedItems,
                [groupId]: {
                  id: groupId,
                  label: group.label,
                  items,
                },
              };
            }
            // Otherwise
            return {
              ...currentGroupedItems,
              ungrouped: {
                id: "ungrouped",
                label: "Ungrouped",
                items: [...currentGroupedItems.ungrouped.items, ...items],
              },
            };
          },
          { ungrouped: { id: "ungrouped", label: "Ungrouped", items: [] } }
        )
      );
      return _.sortBy(
        grouped.filter(({ items }) => items.length > 0),
        [
          ({ id }) => (id === "ungrouped" ? 1 : 0),
          ({ label }) => `${label}`.toLowerCase(),
        ]
      );
    }
    // Otherwise
    if (filteredItems.length > 0) {
      return [
        {
          id: "ungrouped",
          label: "Ungrouped",
          items: filteredItems,
        },
      ];
    }
    // Otherwise
    return [];
  }, [filteredItems, groups]);
  // Handle pasting multiple items
  const onPaste: React.ClipboardEventHandler = (event) => {
    // Get the value from the clipboard
    const value: string = event.clipboardData.getData("text");
    // And check if the clipboard value contains a bulleted list
    const newItems = value
      .split(/[\u2022\u2023\u25E6\u2043\u2219]/gi)
      .map(formatItem)
      .filter((newItem) => newItem.length > 0);
    // If it does (and the pasted values are valid)
    if (newItems.length > 0) {
      // Then automatically add the items, and prevent default handling
      setPendingItems((current) => {
        if (current === null) {
          return null;
        }
        // Otherwise
        return [
          ...current,
          ...newItems.map((item) => ({
            id: uuid4(),
            label: item,
            pending: true,
          })),
        ];
      });
      event.preventDefault();
    }
  };
  const assignGroup = ({ id }: ListItem) => {
    setGroupsDialogOpen(false);
    setPendingItems((currentItems) =>
      currentItems === null
        ? null
        : currentItems.map((currentItem) => {
            if (currentItem.selected) {
              return {
                ...currentItem,
                selected: false,
                group: id,
              };
            }
            // Otherwise
            return currentItem;
          })
    );
  };
  // Handle adding single items
  const addItem = () => {
    const newItem = formatItem(search);
    // Submit new objective
    if (newItem.length > 0) {
      setPendingItems((current) =>
        current === null
          ? null
          : [...current, { id: uuid4(), label: newItem, pending: true }]
      );
    }
    // Clear input
    setSearch("");
  };
  // Allow adding items by using the enter key
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      addItem();
    }
  };
  const empty = activeItems.length === 0;
  const [groupsDialogOpen, setGroupsDialogOpen] = useState(false);
  const contents = (
    <>
      <PanelHeader>
        <div className="flex-1">{title}</div>
        <div className="flex flex-row items-stretch">
          {pendingItems === null ? (
            <Button
              onClick={onStartEdit}
              color="grey"
              variant="secondary"
              className="opacity-0 group-hover:opacity-100 transition-opacity"
            >
              Edit
            </Button>
          ) : (
            <>
              <Tippy content="Assign Group" placement="bottom">
                <Button
                  variant="secondary"
                  color="grey"
                  className="mr-3 transition-all"
                  disabled={!itemsSelected || state.saving}
                  onClick={() => setGroupsDialogOpen(true)}
                >
                  <MdOutlineLabel />
                </Button>
              </Tippy>
              <Button
                variant="secondary"
                color="grey"
                className="mr-1"
                onClick={onCancel}
                disabled={state.saving}
              >
                Cancel
              </Button>
              <Button onClick={onSave} loading={state.saving}>
                Save
              </Button>
            </>
          )}
          {onClose && (
            <IconButton onClick={onClose}>
              <MdClose />
            </IconButton>
          )}
        </div>
      </PanelHeader>
      <PanelBody>
        {pendingItems !== null && (
          <div className="p-2 bg-gray-200 border-b border-gray-300">
            <div className="flex flex-row items-center">
              <TextBox
                name="search"
                label="Search or Add"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                labelDisplay="title"
                placeholder="Search or Add"
                spacing={0}
                className="flex-1"
              />
              <Button className="ml-2" onClick={addItem}>
                Add
              </Button>
            </div>
          </div>
        )}
        {groups
          ? groupedItems.map(({ id, label, items }) => (
              <React.Fragment key={id}>
                <div className="p-2 text-sm font-semibold text-sky-900 border-b border-gray-300 bg-white sticky top-0">
                  {label}
                </div>
                {items.map((item) => (
                  <EditItem
                    key={item.id}
                    item={item}
                    onUpdate={onItemUpdate}
                    onDelete={onItemDelete}
                    selectable={!!groups}
                    onClick={
                      onItemSelect ? () => onItemSelect(item) : undefined
                    }
                    editable={pendingItems !== null}
                  />
                ))}
              </React.Fragment>
            ))
          : filteredItems.map((item) => (
              <EditItem
                key={item.id}
                item={item}
                onUpdate={onItemUpdate}
                onDelete={onItemDelete}
                selectable={!!groups}
                onClick={onItemSelect}
                editable={pendingItems !== null}
              />
            ))}
        {filteredItems.length === 0 && (
          <div className="p-4 flex flex-col items-center">
            <div className="text-gray-500">
              {empty
                ? `There are no ${type} yet, ${
                    pendingItems === null
                      ? "click edit to add some"
                      : "add some above"
                  }`
                : `No matching ${type} found`}
            </div>
            {pendingItems === null && (
              <Button className="mt-1" onClick={onStartEdit}>
                Edit
              </Button>
            )}
          </div>
        )}
      </PanelBody>
    </>
  );
  return (
    <>
      {dialog ? (
        <Dialog className="w-full h-full sm:w-1/2 h-96 max-h-full">
          {contents}
        </Dialog>
      ) : (
        <Panel className="mb-2">{contents}</Panel>
      )}
      {groupsDialogOpen && groups && (
        <EditGroupsDialog
          groups={groups}
          onEdit={onGroupsEdit}
          onSelect={assignGroup}
          onClose={() => setGroupsDialogOpen(false)}
        />
      )}
    </>
  );
};

export default EditList;
