import { useCachedSchoolCollection } from "services/providers/FirebaseCache";
import { Subject } from "types";
import { useMemo } from "react";

const useSubjects = () => useCachedSchoolCollection<Subject>("subjects");

const useAssessedSubjects = (): [undefined | Subject[], boolean, boolean] => {
  const [subjects, loading, error] = useSubjects();
  return useMemo(() => {
    if (loading || error || !subjects) {
      return [undefined, loading, error];
    }
    // Otherwise
    const assessedSubjects = subjects.filter((subject) => subject.assessed);
    return [assessedSubjects, loading, error];
  }, [subjects, loading, error]);
};

const useSubject = (subjectId: string): [Subject | null, boolean, boolean] => {
  const [subjects, loading, error] = useSubjects();
  return useMemo(() => {
    if (error) {
      return [null, false, true];
    }
    // Otherwise
    if (subjects) {
      return [
        subjects.find((subject) => subject.id === subjectId) || null,
        loading,
        error,
      ];
    }
    // Otherwise
    return [null, true, false];
  }, [subjects, loading, error, subjectId]);
};

export { useSubjects, useSubject, useAssessedSubjects };
