import React from "react";
import { useField } from "formik";
import TextArea from "components/Controls/TextArea";

interface TextAreaFieldProps {
  name: string;
  label: string;
  className?: string;
}

const TextAreaField = function TextAreaField({
  name,
  label,
  className,
}: TextAreaFieldProps) {
  const [field] = useField(name);
  return <TextArea label={label} className={className} {...field} />;
};

export default TextAreaField;
