export const createGetFastId = () => {
  let current = 0;
  return () => {
    if (current >= Number.MAX_SAFE_INTEGER) {
      current = 0;
    } else {
      current += 1;
    }
    return current;
  };
};
