import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { auth, functions, firestore } from "./firebaseApp";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import md5 from "./md5";
import environment from "services/environment";

const userContext = createContext({
  isLoading: true,
  error: false,
  user: null,
  profile: null,
  school: null,
  profileImage: null,
  isLoggedIn: false,
  isAuthorized: false,
  role: "none",
});

const Provider = userContext.Provider;

const getSchool = (user) => {
  if (user !== null) {
    if (
      user.email === "caitdowns@googlemail.com"
      // || user.email === "davehall81@googlemail.com"
    ) {
      return "silverhill.derby.sch.uk";
    }
    return `${user.email}`.split("@")[1];
  }
  // Otherwise
  return null;
};

const getProfileImage = (user) => {
  if (user !== null) {
    const email = `${user.email}`.trim().toLowerCase();
    const hash = md5(email);
    return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
  }
  // Otherwise
  return null;
};

export const UserProvider = ({ children }) => {
  // Get the current auth state
  const [user, loading, error] = useAuthState(auth);
  const target = useRef();
  // Get the current users profile data
  const school = getSchool(user);
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    if (user !== null) {
      const profileDoc = doc(firestore, `schools/${school}/users/${user.uid}`);
      const unsubscribe = onSnapshot(profileDoc, (doc) => {
        if (doc.exists()) {
          setProfile(doc.data());
        } else {
          // If we haven't already set the profile, then set it to the default
          if (target.current !== user.uid) {
            target.current = user.uid;
            setDoc(
              profileDoc,
              { email: user.email, role: "none" },
              { merge: true }
            ).catch((error) => {
              console.error("couldn't set profile", error);
            });
          }
          setProfile({
            email: user.email,
            role: "none",
          });
        }
      });
      return () => {
        unsubscribe();
        setProfile(null);
      };
    }
  }, [user, school]);
  // Memoize as an object that can be shared
  const userState = useMemo(
    () => ({
      user,
      profile,
      school: getSchool(user),
      isLoading: loading || (user !== null && profile === null),
      error,
      profileImage: getProfileImage(user),
      isLoggedIn: user !== null && !loading,
      isAuthorized: _.get(profile, "role", "none") !== "none",
      role: _.get(profile, "role", "none"),
    }),
    [user, loading, error, profile]
  );
  // Return the user state provider
  return <Provider value={userState}>{children}</Provider>;
};

export const useCurrentUser = () => useContext(userContext);

const sendSignInLinkFunction = httpsCallable(functions, "sendSignInLink");
console.log("sign in link function: ", sendSignInLinkFunction);
export const sendSignInLink = async (email) => {
  window.localStorage.setItem("email", email);
  if (environment.isDevelopment || environment.isCodeSandbox) {
    console.log("sending sign in link email using firebase");
    await sendSignInLinkToEmail(auth, email, {
      url: `${window.location.origin}/login/complete`,
      handleCodeInApp: true,
    });
  } else {
    console.log("sending sign in link email using cloud function");
    await sendSignInLinkFunction({ email, origin: window.location.origin });
  }
};

export const signInWithLink = () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const email = window.localStorage.getItem("email");
    return signInWithEmailLink(auth, email, window.location.href);
  }
  // Otherwise
  return Promise.reject("not a valid link");
};
