import React, { useMemo } from "react";
import classNames from "classnames";
import Panel from "components/Panel/Panel";
import PanelHeader, { Action } from "components/Panel/PanelHeader";
import { useNavigate } from "react-router-dom";
import WeekSummary from "pages/Dashboard/pages/Plan/pages/YearPage/components/WeekSummary";
import { Droppable } from "react-beautiful-dnd";
import PanelBody from "components/Panel/PanelBody";
import getTermName from "services/getTermName";
import { FilteredTermDataResolved, Subject } from "types";

interface TermProps {
  termNumber: number;
  term: FilteredTermDataResolved;
  filteredSubjects?: Subject[];
  filtered?: boolean;
  initiallyExpanded?: boolean;
  dragEnabled?: boolean;
  className?: string;
}

const Term = function Term({
  termNumber,
  term,
  filteredSubjects,
  filtered = false,
  initiallyExpanded = false,
  dragEnabled = false,
  className,
}: TermProps) {
  const navigate = useNavigate();
  const actions = useMemo<Action[]>(
    () => [
      {
        name: "add",
        label: "Add Week",
        color: "grey",
        onClick: () => {
          navigate(`${termNumber}/new`);
        },
      },
    ],
    [navigate, termNumber]
  );
  const items =
    term.orderedWeeks.length > 0 ? (
      term.filteredWeeks.length > 0 ? (
        term.filteredWeeks.map(({ id, index, week }) => (
          <WeekSummary
            key={id}
            id={id}
            index={index}
            termNumber={termNumber}
            week={week}
            filteredSubjects={filteredSubjects}
            dragEnabled={dragEnabled && !filtered}
          />
        ))
      ) : (
        <div className="p-2 text-gray-500 print:py-0">
          <div>No results found</div>
        </div>
      )
    ) : (
      <div className="p-2 text-gray-500 print:py-0">
        <div>No weeks added yet</div>
      </div>
    );
  return (
    <Panel
      className={classNames(className, {
        "opacity-40 pointer-events-none":
          filtered && term.filteredWeeks.length === 0,
        "print:hidden": term.filteredWeeks.length === 0,
      })}
      expandable
      initiallyExpanded={initiallyExpanded}
    >
      <PanelHeader actions={actions}>{getTermName(termNumber)}</PanelHeader>
      <PanelBody>
        {dragEnabled ? (
          <Droppable droppableId={`${termNumber}`}>
            {(provided) => (
              <ol
                className="pt-1"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items}
                {provided.placeholder}
              </ol>
            )}
          </Droppable>
        ) : (
          <ol className="pt-1">{items}</ol>
        )}
      </PanelBody>
    </Panel>
  );
};

export default Term;
