import React from "react";
import classNames from "classnames";
import Page from "components/Page";
import { useNavigate, useParams } from "react-router-dom";
import AllYears from "pages/Dashboard/pages/Plan/pages/YearPage/AllYears";
import SingleYear from "pages/Dashboard/pages/Plan/pages/YearPage/SingleYear";

const years = ["all", 1, 2, 3, 4, 5, 6];

interface YearViewProps {
  academicYear?: string;
  yearGroup?: string;
}

const YearView = ({ academicYear, yearGroup }: YearViewProps) => {
  if (academicYear && yearGroup) {
    if (yearGroup === "all") {
      return <AllYears academicYear={academicYear} />;
    }
    // Otherwise
    return <SingleYear academicYear={academicYear} yearGroup={yearGroup} />;
  }
  // Otherwise
  return <div>Please select a year</div>;
};

const YearPage = function YearPage() {
  const navigate = useNavigate();
  const { year: currentYear, academicYear } = useParams();
  return (
    <Page title="Planning">
      <div className="h-full w-full flex flex-row print:block">
        <div className="w-64 bg-gray-200 border-r border-gray-300 shadow print:hidden">
          {years.map((year, index) => (
            <div
              key={year}
              className={classNames("p-2 cursor-pointer", {
                "bg-sky-700 text-white/90": `${year}` === currentYear,
                "text-gray-800": `${year}` !== currentYear,
              })}
              onClick={() => {
                navigate(`/app/plan/${academicYear}/${year}`, {
                  replace: true,
                });
              }}
            >
              {year === "all" ? "All Years" : `Year ${year}`}
            </div>
          ))}
        </div>
        <div className="flex-1 overflow-y-auto p-3 print:block">
          <YearView academicYear={academicYear} yearGroup={currentYear} />
        </div>
      </div>
    </Page>
  );
};

export default YearPage;
