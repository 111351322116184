import { YearGroup, years } from "services/years";
import { AssessmentTerm, assessmentTerms } from "types";

export const toTermNumber = (year: YearGroup, term: AssessmentTerm): number => {
  const yearIndex = years.findIndex(({ id }) => id === year);
  const termIndex = assessmentTerms.findIndex((current) => current === term);
  return yearIndex * assessmentTerms.length + termIndex + 1;
};

export const fromTermNumber = (term: number): [YearGroup, AssessmentTerm] => {
  const yearIndex = Math.ceil(term / assessmentTerms.length) - 1;
  const year = years[yearIndex];
  if (year) {
    const termIndex = (term - 1) % assessmentTerms.length;
    const assessmentTerm = assessmentTerms[termIndex];
    if (assessmentTerm) {
      return [year.id, assessmentTerm];
    }
    // Otherwise
    return [year.id, assessmentTerms[0]];
  }
  // Otherwise
  return [years[0].id, assessmentTerms[0]];
};
