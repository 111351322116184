import React from "react";
import classNames from "classnames";

export interface SideBarItemProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  active?: boolean;
  selected?: boolean;
  className?: string;
}

const SideBarItem = function SideBarItem({
  className,
  children,
  active = false,
  selected = false,
  ...others
}: SideBarItemProps) {
  return (
    <div
      className={classNames(
        "px-2 py-1 my-1 rounded cursor-pointer",
        {
          "bg-sky-700 text-white/90": selected,
          "bg-slate-300": active,
        },
        className
      )}
      {...others}
    >
      {children}
    </div>
  );
};

export default SideBarItem;
