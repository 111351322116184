import React from "react";
import Page from "components/Page";
import { Panel } from "components";
import { useSchoolCollection } from "services/firebaseHooks";
import Select, { SelectOption } from "components/Controls/Select";
import { BsShieldFillCheck, BsShieldFillX } from "react-icons/bs";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "services/firebaseApp";
import { useCurrentUser } from "services/user";

interface User {
  id: string;
  email: string;
  role: "admin" | "teacher" | "none";
}

const roleOptions: SelectOption[] = [
  { id: "admin", label: "Admin", group: "Active" },
  { id: "teacher", label: "Teacher", group: "Active" },
  { id: "none", label: "None", group: "Inactive" },
];

const getRoleOption = (role: "admin" | "teacher" | "none"): SelectOption => {
  const roleOption = roleOptions.find((r) => r.id === role);
  if (roleOption) {
    return roleOption;
  }
  // Otherwise
  return roleOptions[2];
};

const UsersPage = function UsersPage() {
  const { school } = useCurrentUser();
  const [users, loading, error] = useSchoolCollection<User>("users");
  const updateRole = (user: string, role: SelectOption) => {
    const userRef = doc(firestore, `schools/${school}/users/${user}`);
    return setDoc(userRef, { role: role.id }, { merge: true });
  };
  console.log("users: ", users, loading, error);
  return (
    <Page title="User Access" className="p-2">
      <Panel className="h-full overflow-auto">
        <table className="border-separate border-spacing-0">
          <thead className="sticky top-0 shadow-sm">
            <tr className="bg-gray-50 text-left">
              <th className="p-2 font-normal text-black border-b border-gray-400">
                Email
              </th>
              <th className="p-2 font-normal text-black border-b border-gray-400">
                Role
              </th>
              <th className="p-2 font-normal text-black border-b border-gray-400 text-center">
                Active
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) &&
              users.map(({ id, email, role }) => (
                <tr key={id}>
                  <td className="p-2 border-b border-gray-400">{email}</td>
                  <td className="p-2 border-b border-gray-400">
                    <div>
                      <Select
                        name={`${email}-role`}
                        label="Role"
                        labelDisplay="title"
                        value={getRoleOption(role)}
                        options={roleOptions}
                        onChange={(newRole) => updateRole(id, newRole)}
                        grouped
                      />
                    </div>
                  </td>
                  <td className="p-2 border-b border-gray-400 text-center">
                    {role !== "none" ? (
                      <BsShieldFillCheck className="text-green-700 inline-block" />
                    ) : (
                      <BsShieldFillX className="text-red-700 inline-block" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Panel>
    </Page>
  );
};

export default UsersPage;
