import React from "react";
import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  name?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
  className?: string;
  loading?: boolean;
  variant?: "primary" | "secondary" | "text";
  color?: "grey" | "blue" | "red";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const getVariantStyles = (
  variant: ButtonProps["variant"],
  color: ButtonProps["color"]
) => {
  if (variant === "text") {
    return classNames("font-semibold", {
      "text-sky-700 hover:bg-sky-200 hover:text-sky-900": color === "blue",
      "text-gray-700 hover:bg-gray-200 hover:text-gray-900": color === "grey",
      "text-red-700 hover:bg-red-200 hover:text-red-900": color === "red",
    });
  }
  // Otherwise
  if (variant === "primary") {
    return {
      "bg-sky-600 hover:bg-sky-700 text-white": color === "blue",
      "bg-gray-600 hover:bg-gray-700 text-white": color === "grey",
      "bg-red-600 hover:bg-red-700 text-white": color === "red",
    };
  }
  // Otherwise
  return {
    "border border-sky-400 text-sky-700 hover:border-sky-700 hover:text-sky-900":
      color === "blue",
    "border border-gray-400 text-gray-700 hover:border-gray-700 hover:text-gray-900":
      color === "grey",
    "border border-red-400 text-red-700 hover:border-red-700 hover:text-red-900":
      color === "red",
  };
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      onClick = undefined,
      loading = false,
      children,
      variant = "primary",
      color = "blue",
      disabled = false,
      type = "button",
      ...others
    },
    ref
  ) => {
    const [waiting, setWaiting] = React.useState(false);
    const onInternalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick && !loading && !disabled) {
        const result = onClick(event);
        if (result instanceof Promise) {
          setWaiting(true);
          result.finally(() => setWaiting(false));
        }
      }
    };
    const loadingOrWaiting = loading || waiting;
    return (
      <button
        onClick={onInternalClick}
        className={classNames(
          className,
          "rounded px-3 py-1 transition-colors flex flex-row items-center justify-center",
          getVariantStyles(variant, color),
          {
            "cursor-auto": loadingOrWaiting,
            "text-gray-500 bg-gray-100 border-gray-300 hover:border-gray-300 cursor-not-allowed":
              disabled,
          }
        )}
        ref={ref}
        type={type}
        {...others}
      >
        {loadingOrWaiting && (
          <div className="pr-2 inline-block">
            <CgSpinner className="animate-spin" />
          </div>
        )}
        {children}
      </button>
    );
  }
);

export default Button;
