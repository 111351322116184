import React, { useMemo } from "react";
import { useField } from "formik";
import _ from "lodash";
import Select, { SelectOption } from "components/Controls/Select";
import Label from "components/Controls/Label";
import Tag from "components/Controls/Tag";
import classNames from "classnames";

interface TagsFieldProps {
  name: string;
  label: string;
  options: SelectOption[];
  className?: string;
}

const TagsField = function TagsField({
  name,
  label,
  options,
  className,
}: TagsFieldProps) {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const [selectedOptions, unselectedOptions] = useMemo(() => {
    const grouped = _.groupBy(options, ({ id }) =>
      _.indexOf(value, id) > -1 ? "selected" : "unselected"
    );
    return [grouped.selected || [], grouped.unselected || []];
  }, [value, options]);
  const selectOption = ({ id }: SelectOption) => {
    setValue([...value, id]);
  };
  const deselectOption = ({ id }: SelectOption) => {
    setValue(value.filter((currentId: string) => currentId !== id));
  };
  return (
    <div className={classNames(className)}>
      <Label>{label}</Label>
      {selectedOptions.length > 0 && (
        <div className="flex flex-row mb-1">
          {selectedOptions.map((tag) => (
            <Tag
              key={tag.id}
              id={tag.id}
              onDismiss={() => deselectOption(tag)}
              className="mr-1"
            >
              {tag.label}
            </Tag>
          ))}
        </div>
      )}
      <Select
        name={name}
        label={label}
        value={null}
        options={unselectedOptions}
        onChange={selectOption}
        labelDisplay="title"
        placeholder="Add Thread..."
        variant="inline"
      />
    </div>
  );
};

export default TagsField;
