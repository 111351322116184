import React, { useCallback, useMemo } from "react";
import Page from "components/Page";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import PanelHeader from "components/Panel/PanelHeader";
import Panel from "components/Panel/Panel";
import { Form, Formik } from "formik";
import ObjectivesField from "pages/Dashboard/pages/Plan/pages/WeekPage/components/ObjectivesField";
import TextField from "components/Fields/TextField";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import Button from "components/Button";
import {
  useSetSchoolDocument,
  useUpdateSchoolDocument,
} from "services/firebaseHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { v4 as uuid4 } from "uuid";
import { arrayRemove, arrayUnion, deleteField } from "firebase/firestore";
import _ from "lodash";
import ThreadsField from "pages/Dashboard/pages/Plan/pages/WeekPage/components/ThreadsField";
import { useCachedSchoolDocument } from "services/providers/FirebaseCache";
import ProgressButton from "components/ProgressButton";
import { WeekData } from "types";
import TextAreaField from "components/Fields/TextAreaField";

interface Term {
  weeks: {
    [id: string]: WeekData;
  };
  order: string[];
}

const WeekPage = function WeekPage() {
  const { academicYear, year, term, week } = useParams();
  const termPath = `terms/${academicYear}-${year}-${term}`;
  const [termDocument, loading] = useCachedSchoolDocument<Term>(termPath);
  const weekData: { id: string; data: WeekData } = useMemo(() => {
    if (week !== "new" && termDocument) {
      const weekNumber = Number(week);
      const weekId = _.get(termDocument.order, weekNumber - 1);
      const weekData = _.get(termDocument.weeks, weekId);
      return { id: weekId, data: { ...weekData } };
    }
    // Otherwise
    return {
      id: "new",
      data: { title: "", description: "", threads: [], objectives: [] },
    };
  }, [termDocument, week]);
  console.log("week data: ", weekData);
  const updateTermDocument = useSetSchoolDocument(termPath, true);
  const navigate = useNavigate();
  const onCancel = useCallback(() => {
    navigate(`/app/plan/${academicYear}/${year}`);
  }, [navigate, academicYear, year]);
  const updateDocument = useUpdateSchoolDocument(termPath);
  const weekId = weekData.id;
  const onDelete = useCallback(async () => {
    await updateDocument({
      order: arrayRemove(weekId),
      [`weeks.${weekId}`]: deleteField(),
    });
    onCancel();
  }, [updateDocument, weekId, onCancel]);
  return (
    <Page title="Planning" className="p-3 flex flex-col">
      <div className="text-sm text-gray-700 mb-3 flex-none">
        <NavLink
          to={`/app/plan/${academicYear}/${year}`}
          className="inline-block text-sky-500 hover:text-sky-800"
        >
          {academicYear} - Year {year}
        </NavLink>
        <span className="px-2">/</span>
        <span>Term {term}</span>
        {" - "}
        <span className="inline-block">New Week</span>
      </div>
      <Panel className="flex-1 overflow-hidden">
        <PanelHeader className="items-center">
          <div className="flex-1">
            {week === "new" ? "New Week" : `Week ${week}`}
          </div>
          {weekId && weekId !== "new" && (
            <ProgressButton color="red" variant="secondary" onClick={onDelete}>
              Delete
            </ProgressButton>
          )}
        </PanelHeader>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Formik<WeekData>
            initialValues={weekData.data}
            onSubmit={(values) => {
              console.log("week data: ", values);
              // Perform update based on id
              if (weekData.id === "new") {
                const weekId = uuid4();
                return updateTermDocument({
                  weeks: {
                    [weekId]: values,
                  },
                  order: arrayUnion(weekId),
                }).then(() => navigate(`/app/plan/${academicYear}/${year}`));
              } else {
                return updateTermDocument({
                  weeks: {
                    [weekData.id]: values,
                  },
                }).then(() => navigate(`/app/plan/${academicYear}/${year}`));
              }
            }}
          >
            <Form className="flex-1 flex flex-col overflow-hidden">
              <PanelBody className="p-2">
                <TextField name="title" label="Title" />
                <TextAreaField name="description" label="Description" />
                <ThreadsField />
                <ObjectivesField
                  academicYear={academicYear || ""}
                  year={year || ""}
                />
              </PanelBody>
              <PanelFooter className="justify-between">
                <Button color="grey" variant="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </PanelFooter>
            </Form>
          </Formik>
        )}
      </Panel>
    </Page>
  );
};

export default WeekPage;
