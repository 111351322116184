import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import Panel from "components/Panel/Panel";
import { usePrintOptions } from "services";

interface DialogProps {
  children: React.ReactNode;
  pageTitle?: string;
  className?: string;
}

const Dialog = function Dialog({
  children,
  pageTitle = "",
  className,
}: DialogProps) {
  const dialogContainer = useRef<HTMLDivElement>();
  if (dialogContainer.current === undefined) {
    dialogContainer.current = document.createElement("div");
    dialogContainer.current.className =
      "fixed w-full h-full bg-gray-900 bg-opacity-80 inset-0 z-10 flex items-center justify-center " +
      "print:static print:block";
  }

  usePrintOptions({ title: pageTitle, dialogOpen: true });

  useEffect(() => {
    const container = dialogContainer.current as HTMLDivElement;
    document.body.append(container);
    return () => container.remove();
  }, []);

  return ReactDOM.createPortal(
    <Panel className={classNames(className, "shadow-xl")}>{children}</Panel>,
    dialogContainer.current
  );
};

export default Dialog;
