import { useCallback } from "react";
import { TermData, TermDataResolved } from "types";
import { useResolveWeek } from "services/hooks/useResolveWeek";

const EMPTY_TERM = {
  weeks: {},
  order: [],
  orderedWeeks: [],
};

export const useResolveTerm = () => {
  const resolveWeek = useResolveWeek();
  return useCallback(
    (
      yearGroup: string,
      termNumber: number,
      term?: TermData
    ): TermDataResolved => {
      if (term) {
        return {
          ...term,
          yearGroup,
          termNumber,
          orderedWeeks: term.order.map((weekId, index) => ({
            id: weekId,
            index,
            week: resolveWeek(term.weeks[weekId]),
          })),
        };
      }
      // Otherwise
      return { ...EMPTY_TERM, yearGroup, termNumber };
    },
    [resolveWeek]
  );
};
