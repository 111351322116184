import React, { useMemo } from "react";
import SelectListField from "components/Fields/SelectListField";
import { useObjectives, useTerms, useTermStats } from "services";
import { formatCompletedWeeks } from "services/utlities/formatCompletedWeeks";

interface ObjectivesFieldProps {
  academicYear: string;
  year: string;
}

const ObjectivesField = function ObjectivesField({
  academicYear,
  year: targetYear,
}: ObjectivesFieldProps) {
  const [terms] = useTerms(academicYear, targetYear);
  const { completedObjectiveDetails } = useTermStats(terms);
  const [objectives] = useObjectives();
  const objectiveOptions = useMemo(
    () =>
      objectives === null
        ? []
        : objectives
            .filter(({ year }) => year === targetYear)
            .map(({ id, label, subjectName, groupName }) => ({
              id,
              label,
              group: groupName ? `${subjectName} - ${groupName}` : subjectName,
              complete: completedObjectiveDetails[id]
                ? formatCompletedWeeks(completedObjectiveDetails[id])
                : false,
            })),
    [objectives, targetYear, completedObjectiveDetails]
  );
  return (
    <SelectListField
      name="objectives"
      label="Objectives"
      placeholder="Add Objective..."
      options={objectiveOptions}
    />
  );
};

export default ObjectivesField;
