import React, { useMemo } from "react";
import Filters from "components/Filters";
import { FilterOptions, FilterValue } from "components/Filters/types";
import { useThreads, useObjectives } from "services";
import _ from "lodash";
import { TermData } from "types";
import { SelectOption } from "components/Controls/Select";

interface WeekFiltersProps {
  terms: TermData[];
  value: FilterValue[];
  onChange: (filters: FilterValue[]) => unknown;
  search: string;
  onSearchChange: (search: string) => unknown;
  year?: string;
}

const WeekFilters = function WeekFilters({
  terms,
  value,
  onChange,
  search,
  onSearchChange,
}: WeekFiltersProps) {
  const [objectives] = useObjectives();
  const [threads] = useThreads();
  const activeObjectives = useMemo(
    () =>
      _.uniq(
        terms?.reduce<string[]>(
          (current, { weeks }) => [
            ...current,
            ...Object.entries(weeks).reduce<string[]>(
              (termObjectives, [, { objectives: weekObjectives }]) => [
                ...termObjectives,
                ...weekObjectives,
              ],
              []
            ),
          ],
          []
        )
      ),
    [terms]
  );
  const activeThreads = useMemo(
    () =>
      _.uniq(
        terms?.reduce<string[]>(
          (current, { weeks }) => [
            ...current,
            ...Object.entries(weeks).reduce<string[]>(
              (termThreads, [, { threads: weekThreads }]) => [
                ...termThreads,
                ...weekThreads,
              ],
              []
            ),
          ],
          []
        )
      ),
    [terms]
  );
  const objectiveOptions = useMemo(
    () =>
      objectives
        ? objectives
            .filter(({ id }) => activeObjectives.indexOf(id) > -1)
            .map(({ id, label, subjectName, subject, group, groupName }) => ({
              id,
              label,
              subject,
              group: subjectName,
              subgroupId: group,
              subgroup: groupName,
            }))
        : [],
    [objectives, activeObjectives]
  );
  const subjectOptions = useMemo<SelectOption[]>(
    () =>
      Object.entries(
        objectiveOptions.reduce(
          (current, objective) => ({
            ...current,
            [objective.subject]: objective.group,
          }),
          {}
        )
      ).map<SelectOption>(([subjectId, subjectName]) => ({
        id: subjectId,
        label: (subjectName || "") as string,
      })),
    [objectiveOptions]
  );
  const filters = useMemo<FilterOptions[]>(() => {
    return [
      {
        type: "subject",
        label: "Subject",
        labelPlural: "Subjects",
        options: subjectOptions,
      },
      {
        type: "thread",
        label: "Thread",
        labelPlural: "Threads",
        options: threads
          ? threads
              .filter(({ id }) => activeThreads?.indexOf(id) > -1)
              .map(({ id, label }) => ({
                id,
                label,
              }))
          : [],
      },
      {
        type: "objective",
        label: "Objective",
        labelPlural: "Objectives",
        grouped: true,
        subGrouped: true,
        options: objectiveOptions,
      },
    ];
  }, [objectiveOptions, subjectOptions, threads, activeThreads]);
  return (
    <Filters
      filters={filters}
      value={value}
      onChange={onChange}
      search={search}
      onSearchChange={onSearchChange}
      className="pb-2"
    />
  );
};

export default WeekFilters;
