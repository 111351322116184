import React from "react";
import classNames from "classnames";

interface SideBarContainerProps {
  children: React.ReactNode;
  className?: string;
}

const SideBarContainer = function SideBarContainer({
  className,
  children,
}: SideBarContainerProps) {
  return (
    <div
      className={classNames(
        "h-full w-full flex flex-row print:block",
        className
      )}
    >
      {children}
    </div>
  );
};

export default SideBarContainer;
