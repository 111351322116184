import React from "react";
import classNames from "classnames";
import { usePanelContext } from "components/Panel/Panel";

interface PanelBodyProps {
  children: React.ReactNode;
  alwaysPrint?: boolean;
  className?: string;
}

const PanelBody = function PanelBody({
  children,
  className,
  alwaysPrint,
}: PanelBodyProps) {
  const { expanded } = usePanelContext();
  return (
    <div
      data-testid="panel-body"
      className={classNames(
        className,
        "flex-1 overflow-y-auto overflow-hidden",
        expanded ? "print:block" : "hidden",
        { "print:block": alwaysPrint }
      )}
    >
      {children}
    </div>
  );
};

export default PanelBody;
