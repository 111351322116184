import React from "react";
import classNames from "classnames";

interface TableRowProps {
  children: React.ReactNode;
  className?: string;
}

const TableRow = function TableRow({ className, children }: TableRowProps) {
  return <tr className={classNames(className, "bg-white")}>{children}</tr>;
};

export default TableRow;
