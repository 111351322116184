import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface PrintOptions {
  title: string;
  dialogOpen?: boolean;
}

interface PrintContext {
  options: PrintOptions;
  setOptions: (options: PrintOptions) => () => void;
}

const DEFAULT_OPTIONS: PrintOptions = { title: "", dialogOpen: false };
const printContext = React.createContext<PrintContext>({
  options: { title: "", dialogOpen: false },
  setOptions: () => {
    throw new Error(
      "No print context found, ensure a print context is available"
    );
  },
});
const Provider = printContext.Provider;

interface PrintProviderProps {
  children: (props: PrintOptions) => React.ReactNode;
}

const PrintProvider = function PrintProvider({ children }: PrintProviderProps) {
  const [printOptions, setPrintOptions] = useState<PrintOptions[]>([
    DEFAULT_OPTIONS,
  ]);
  const setOptions = useCallback(
    (options: PrintOptions) => {
      setPrintOptions((current) => [...current, options]);
      return () => {
        setPrintOptions((current) =>
          current.filter((entry) => entry !== options)
        );
      };
    },
    [setPrintOptions]
  );
  const activePrintOptions = useMemo(
    () => printOptions[printOptions.length - 1],
    [printOptions]
  );
  const context = useMemo<PrintContext>(
    () => ({
      options: activePrintOptions,
      setOptions,
    }),
    [activePrintOptions, setOptions]
  );
  return <Provider value={context}>{children(activePrintOptions)}</Provider>;
};

const usePrintContext = () => useContext<PrintContext>(printContext);
const usePrintOptions = ({ title, dialogOpen = false }: PrintOptions) => {
  const memoOptions = useMemo<PrintOptions>(
    () => ({
      title: title,
      dialogOpen: dialogOpen,
    }),
    [title, dialogOpen]
  );
  const { setOptions } = usePrintContext();
  useEffect(() => setOptions(memoOptions), [memoOptions, setOptions]);
};

export { PrintProvider, usePrintOptions, usePrintContext };
