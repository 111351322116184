import React from "react";
import TermList from "pages/Dashboard/pages/Plan/pages/YearPage/components/TermList";
import { usePrintOptions } from "services";

interface SingleYearProps {
  academicYear: string;
  yearGroup: string;
}

const SingleYear = function SingleYearPage({
  academicYear,
  yearGroup,
}: SingleYearProps) {
  usePrintOptions({
    title: `Year ${yearGroup} Plan`,
  });
  return <TermList academicYear={academicYear} yearGroup={yearGroup} />;
};

export default SingleYear;
