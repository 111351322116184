import React from "react";
import { StudentWithScore } from "../types";
import {
  Panel,
  PanelBody,
  PanelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "components";
import _ from "lodash";
import { AssessmentScore, assessmentScores } from "types";
import { assessmentScoreName } from "services/utlities/assessmentScoreName";

// Breakdown -> Key Stage -> Year - >

const asPercent = (num: number) => {
  // Display as percent with 1 decimal place if needed
  const percent = num * 100;
  return percent % 1 === 0 ? `${percent}%` : `${percent.toFixed(1)}%`;
};

interface Breakdown {
  male: number;
  female: number;
  [flag: string]: number;
}

const getBreakdown = (students: StudentWithScore[], flags: string[]) =>
  flags.reduce<Breakdown>(
    (breakdown, flag) => ({
      ...breakdown,
      [flag]: students.filter((student) =>
        _.castArray(student.flags).includes(flag)
      ).length,
    }),
    {
      male: students.filter((student) => student.gender === "male").length,
      female: students.filter((student) => student.gender === "female").length,
    }
  );

interface StudentAttainmentBreakdownRowProps {
  title: string;
  students: StudentWithScore[];
  flags: string[];
  summaryBreakdown?: Breakdown;
  totalStudents?: number;
  className?: string;
}

const StudentAttainmentBreakdownRow = function StudentAttainmentBreakdownRow({
  title,
  students,
  flags,
  summaryBreakdown,
  totalStudents,
  className,
}: StudentAttainmentBreakdownRowProps) {
  const breakdown = getBreakdown(students, flags);
  return (
    <TableRow className={className}>
      <TableColumn header className="print:font-semibold print:bg-gray-200">
        {title}
      </TableColumn>
      <TableColumn className="text-center" header>
        {students.length}
        {students.length > 0 &&
        totalStudents !== undefined &&
        totalStudents > 0 ? (
          <span className="text-sm text-gray-700 print:text-sky-700">{` (${asPercent(
            students.length / totalStudents
          )})`}</span>
        ) : null}
      </TableColumn>
      {["male", "female", ...flags].map((flag) => (
        <TableColumn className="text-center" key={flag}>
          {breakdown[flag]}
          {breakdown[flag] > 0 &&
            summaryBreakdown &&
            summaryBreakdown[flag] > 0 && (
              <span className="text-sm text-gray-600 print:text-sky-700">
                {` (${asPercent(breakdown[flag] / summaryBreakdown[flag])})`}
              </span>
            )}
        </TableColumn>
      ))}
    </TableRow>
  );
};

interface StudentAttainmentBreakdownProps {
  title: string;
  className?: string;
  color?: "gray" | "green" | "purple";
  students: StudentWithScore[];
  flags: string[];
  expectedOnly?: boolean;
}

const StudentAttainmentBreakdown = function StudentAttainmentBreakdown({
  title,
  className,
  color = "gray",
  students,
  flags,
  expectedOnly = false,
}: StudentAttainmentBreakdownProps) {
  const breakdown = getBreakdown(students, flags);
  return (
    <Panel
      className={`mb-2 print:break-inside-avoid ${className}`}
      color={color}
    >
      <PanelHeader>{title}</PanelHeader>
      <PanelBody>
        <Table className="w-full print:border-l print:border-t border-gray-700">
          <TableHeader className="shadow-sm">
            <TableHeaderColumn
              header
              className="text-gray-600 font-bold w-20"
            />
            <TableHeaderColumn header className="w-24 text-center">
              All
            </TableHeaderColumn>
            <TableHeaderColumn className="w-24 text-center">
              Male
            </TableHeaderColumn>
            <TableHeaderColumn className="w-24 text-center">
              Female
            </TableHeaderColumn>
            {flags.map((flag) => (
              <TableHeaderColumn key={flag} className="w-24 text-center">
                {flag}
              </TableHeaderColumn>
            ))}
          </TableHeader>
          <TableBody>
            <StudentAttainmentBreakdownRow
              title="Total"
              students={students}
              flags={flags}
            />
            <StudentAttainmentBreakdownRow
              title="EXS+"
              students={students.filter(
                (student) => student.score >= AssessmentScore.EXS
              )}
              flags={flags}
              summaryBreakdown={breakdown}
              totalStudents={students.length}
            />
            {assessmentScores.map((score) => {
              if (expectedOnly && score < AssessmentScore.EXS) {
                return null;
              }
              return (
                <StudentAttainmentBreakdownRow
                  key={score}
                  title={assessmentScoreName(score)}
                  students={students.filter(
                    (student) => student.score === score
                  )}
                  flags={flags}
                  summaryBreakdown={breakdown}
                  totalStudents={students.length}
                />
              );
            })}
          </TableBody>
        </Table>
      </PanelBody>
    </Panel>
  );
};

export default StudentAttainmentBreakdown;
