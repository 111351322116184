import React, { useMemo } from "react";
import Page from "components/Page";
import { Navigate, NavLink, useParams } from "react-router-dom";
import { useStudentsWithAssessments } from "services/hooks/useStudentsWithAssessments";
import LoadingIndicator from "components/LoadingIndicator";
import { AutoComplete, Panel, PanelBody, PanelHeader } from "components";
import Label from "components/Controls/Label";
import Tag from "components/Controls/Tag";
import TextBox from "components/Controls/TextBox";
import Select, { SelectOption } from "components/Controls/Select";
import { Action } from "components/Panel/PanelHeader";
import { useUpdateSchoolDocument } from "services/firebaseHooks";

const flagOptions = ["SEN", "Pakistani", "Pupil Premium", "Summer Born"];

const GENDER_OPTIONS: SelectOption[] = [
  { id: "male", label: "Male" },
  { id: "female", label: "Female" },
];

interface StudentEdits {
  name?: string;
  gender: SelectOption;
  flags: string[];
  id?: string;
  yearGroup?: SelectOption;
}

interface StudentProperties {
  id: string;
  name?: string;
  entryYear?: number;
  yearGroup?: string;
  gender?: string;
  flags?: string[];
}

const StudentPanel = function StudentPanel({
  student,
}: {
  student: StudentProperties;
}) {
  const [editMode, setEditMode] = React.useState(student.id === "new");
  const createMode = student.id === "new";
  const [studentEdits, setStudentEdits] = React.useState<StudentEdits>({
    name: student.name,
    gender: student.gender === "male" ? GENDER_OPTIONS[0] : GENDER_OPTIONS[1],
    flags: student.flags || [],
  });
  const flags = editMode ? studentEdits.flags : student.flags;
  const updateStudents = useUpdateSchoolDocument(
    `students/${student.entryYear}`
  );

  const actions = useMemo<Action[]>(
    () =>
      editMode || createMode
        ? [
            {
              name: "cancel",
              label: "Cancel",
              variant: "primary",
              color: "grey",
              onClick: () => {
                setEditMode(false);
                setStudentEdits({
                  name: student.name,
                  gender:
                    student.gender === "male"
                      ? GENDER_OPTIONS[0]
                      : GENDER_OPTIONS[1],
                  flags: student.flags || [],
                });
              },
            },
            {
              name: "save",
              label: "Save",
              variant: "primary",
              onClick: async () => {
                console.log("applying updates: ", {
                  [`students.${student.id}.name`]: studentEdits.name,
                  [`students.${student.id}.gender`]: studentEdits.gender.id,
                  [`students.${student.id}.flags`]: studentEdits.flags,
                });
                await updateStudents({
                  [`students.${student.id}.name`]: studentEdits.name,
                  [`students.${student.id}.gender`]: studentEdits.gender.id,
                  [`students.${student.id}.flags`]: studentEdits.flags,
                });
                setEditMode(false);
              },
            },
          ]
        : [
            {
              name: "edit",
              label: "Edit",
              variant: "primary",
              onClick: () => setEditMode((current) => !current),
            },
          ],
    [student, studentEdits, updateStudents, editMode, createMode]
  );
  return (
    <Panel>
      <PanelHeader actions={actions}>Student Details</PanelHeader>
      <PanelBody className="p-2">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
          {editMode ? (
            <>
              <TextBox
                name="student-name"
                label="Name"
                value={studentEdits.name}
                onChange={(event) =>
                  setStudentEdits((current) => ({
                    ...current,
                    name: event.target.value,
                  }))
                }
              />
              <Select
                name="student-gender"
                label="Gender"
                options={GENDER_OPTIONS}
                value={studentEdits.gender}
                onChange={(gender) =>
                  setStudentEdits((current) => ({ ...current, gender }))
                }
              />
            </>
          ) : (
            <>
              <div>
                <Label>Name</Label>
                <div>{student.name}</div>
              </div>
              <div>
                <Label>Gender</Label>
                <div>
                  {student.gender === undefined
                    ? "-"
                    : student.gender === "male"
                    ? "Male"
                    : "Female"}
                </div>
              </div>
            </>
          )}

          <div>
            <Label>Year Group</Label>
            <div>{student.yearGroup}</div>
          </div>
          <div>
            <Label>UPN</Label>
            <div>{student.id}</div>
          </div>
        </div>
        <div className="w-full h-px bg-gray-200 my-3 hidden md:block" />
        <div className="mt-2">
          <Label>Flags</Label>
          {flags && flags.length > 0 ? (
            <div className="flex flex-row flex-wrap mt-1">
              {flags.map((flag) => (
                <Tag
                  key={flag}
                  className="inline-block mr-1"
                  onDismiss={
                    editMode
                      ? () =>
                          setStudentEdits((current) => ({
                            ...current,
                            flags: current.flags?.filter((f) => f !== flag),
                          }))
                      : undefined
                  }
                >
                  {flag}
                </Tag>
              ))}
            </div>
          ) : (
            <div className="text-gray-700">-</div>
          )}
          {editMode && (
            <div className="flex flex-row pt-1">
              <AutoComplete
                name="student-flag"
                label="Add Flag"
                labelDisplay="title"
                placeholder="Add flag..."
                options={flagOptions}
                spacing={0}
                className="mr-1"
                value={studentEdits.flags}
                onChange={(flags) =>
                  setStudentEdits({ ...studentEdits, flags })
                }
                allowNew
              />
            </div>
          )}
        </div>
      </PanelBody>
    </Panel>
  );
};

const StudentLoader = function StudentLoader({
  studentId,
  entryYear,
}: {
  studentId: string;
  entryYear: number;
}) {
  // Get all students from the specified entry year
  const [students, loading, error] = useStudentsWithAssessments(entryYear);
  console.log("loaded students: ", students);
  // And try to find the specified student
  const student = students.find((student) => student.id === studentId);
  if (loading) {
    return <LoadingIndicator />;
  }
  // Otherwise
  if (!student) {
    console.log(`unable to find student ${studentId} in students: `, students, {
      loading,
      error,
    });
    return <Navigate to="../" replace />;
  }
  // Otherwise
  return (
    <>
      <div className="text-sm text-gray-700 mb-3 flex-none">
        <NavLink
          to={`../`}
          className="inline-block text-sky-500 hover:text-sky-800"
        >
          Students
        </NavLink>
        <span className="px-2">/</span>
        <span>{student?.name}</span>
      </div>
      <StudentPanel student={student} />
    </>
  );
};

const StudentPage = function StudentPage() {
  // Get the student ID and entry year from the URL
  const { studentId, entryYear } = useParams();
  const entryYearNumber = Number(entryYear);
  if (!studentId || Number.isNaN(entryYearNumber)) {
    console.log("invalid entry year or id");
    return <Navigate to="../" replace />;
  }
  // Otherwise
  return (
    <Page title={`Students`} className="p-3 flex flex-col">
      <StudentLoader studentId={studentId} entryYear={entryYearNumber} />
    </Page>
  );
};

export default StudentPage;
