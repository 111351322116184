import React from "react";
import Select, { SelectOption, SelectProps } from "components/Controls/Select";
import { useYears, Year } from "services";

interface YearSelectProps
  extends Omit<SelectProps<SelectOption>, "value" | "onChange" | "options"> {
  value: Year;
  onChange: (year: Year) => void;
}

const YearSelect = React.forwardRef<HTMLInputElement, YearSelectProps>(
  function YearSelect(
    { value, onChange, label = "Year", ...props }: YearSelectProps,
    ref
  ) {
    const yearOptions = useYears();
    return (
      <Select
        value={value}
        onChange={onChange}
        options={yearOptions}
        label={label}
        {...props}
        ref={ref}
      />
    );
  }
);

export default YearSelect;
