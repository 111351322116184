import React from "react";
import classNames from "classnames";

interface TableColumnProps {
  children: React.ReactNode;
  header?: boolean;
  className?: string;
}

const TableColumn = function TableColumn({
  className,
  header,
  children,
}: TableColumnProps) {
  return (
    <td
      className={classNames(
        className,
        "p-2 text-black border-b border-gray-300",
        "print:p-1 print:text-sm print:border print:border-gray-700",
        {
          "border-r bg-gray-100/75": header,
        }
      )}
    >
      {children}
    </td>
  );
};

export default TableColumn;
