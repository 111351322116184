import React, { useCallback, useMemo } from "react";
// import { MdSearch } from "react-icons/md";
import { FilterOptions, FilterValue } from "components/Filters/types";
import Filter from "components/Filters/Filter";
import _ from "lodash";
import AddFilter from "components/Filters/AddFilter";
import FilterSearchBox from "components/Filters/FilterSearchBox";
import classNames from "classnames";

interface FiltersProps {
  filters: FilterOptions[];
  value: FilterValue[];
  onChange: (filters: FilterValue[]) => unknown;
  search?: string;
  onSearchChange?: (search: string) => unknown;
  className?: string;
}

const Filters = function Filters({
  filters,
  value,
  onChange,
  search,
  onSearchChange,
  className,
}: FiltersProps) {
  const resolveItem = useCallback(
    ({ type, id }: FilterValue) => {
      const filter = _.find(filters, { type });
      if (filter) {
        const option = _.find(filter.options, { id });
        if (option) {
          return {
            id: id,
            typeLabel: filter.label,
            optionLabel: option.label,
          };
        }
      }
      const groupFilter = _.find(filters, {
        type: type.split("-")[0],
        subGrouped: true,
      });
      if (groupFilter) {
        const option = _.find(groupFilter.options, { subgroupId: id });
        if (option) {
          const group = option.group;
          const subGroup = option.subgroup || "Ungrouped";
          return {
            id,
            typeLabel: `${groupFilter.label} Group`,
            optionLabel: `${group} - ${subGroup}`,
          };
        }
      }
      // Otherwise
      return null;
    },
    [filters]
  );
  const currentFilters = useMemo(
    () => (value || []).map((filterValue) => resolveItem(filterValue)),
    [resolveItem, value]
  );
  const availableFilters = useMemo(
    () =>
      filters.map<FilterOptions>(({ type, options, ...others }) => ({
        ...others,
        type,
        options: options.filter(
          ({ id }) => _.findIndex(value, { type, id }) < 0
        ),
      })),
    [filters, value]
  );
  const onFilterAdded = (filter: FilterValue) => {
    onChange([...value, filter]);
  };
  const onFilterRemoved = (targetIndex: number) => {
    onChange(value.filter((value, index) => index !== targetIndex));
  };
  return (
    <div
      className={classNames(
        "-m-0.5 flex flex-row flex-wrap print:hidden",
        className
      )}
    >
      {onSearchChange && (
        <FilterSearchBox value={search || ""} onChange={onSearchChange} />
      )}
      {currentFilters.map((filter, index) => {
        if (filter !== null) {
          return (
            <Filter
              key={filter.id}
              value={filter}
              onDismiss={() => onFilterRemoved(index)}
            />
          );
        }
        // Otherwise
        return null;
      })}
      <AddFilter filters={availableFilters} onSelect={onFilterAdded} />
    </div>
  );
};

export default Filters;
