import React, { useMemo } from "react";
import { MdDragHandle, MdOutlineCalendarToday } from "react-icons/md";
import _ from "lodash";
import Tag from "components/Controls/Tag";
import {
  Draggable,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import classNames from "classnames";
import Label from "components/Controls/Label";
import { useNavigate } from "react-router-dom";
import { Objective, Subject, Thread, WeekDataResolved } from "types";

const ObjectivesList = ({ objectives }: { objectives: Objective[] }) => {
  const groupedObjectives = _.groupBy(objectives, "subjectName");
  return (
    <>
      {Object.entries(groupedObjectives).map(([subject, objectives], index) => (
        <div key={subject} className="mt-2">
          <Label textStyle="text-gray-500">{subject} Objectives</Label>
          <ul className="ml-4 mb-1">
            {objectives.map(({ id, label, subjectName }) => (
              <li key={id} className="list-disc break-inside-avoid">
                {label}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

const weekStyle = {
  minHeight: 60,
};

interface WeekSummaryViewProps extends React.HTMLProps<HTMLLIElement> {
  title: string;
  description?: string;
  threads: Thread[];
  objectives: Objective[];
  termNumber: number;
  weekNumber: number;
  dragEnabled: boolean;
  isDragging: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const WeekSummaryView = React.forwardRef<HTMLLIElement, WeekSummaryViewProps>(
  (
    {
      title,
      description,
      threads,
      objectives,
      termNumber,
      weekNumber,
      dragEnabled,
      isDragging,
      dragHandleProps,
      ...others
    }: WeekSummaryViewProps,
    ref
  ) => {
    const navigate = useNavigate();
    return (
      <li
        data-testid="week"
        className={classNames(
          "ml-8 relative pl-6 pb-3 border-l group-one cursor-pointer",
          isDragging ? "border-white" : "border-slate-600 last:border-white",
          "print:ml-0 print:pl-0 print:block print:border-0"
        )}
        onClick={() => {
          navigate(`${termNumber}/${weekNumber}`);
        }}
        {...others}
        ref={ref}
      >
        {isDragging && (
          <div className="absolute inset-0 -left-8 -top-px bg-white -z-10 rounded border border-gray-300" />
        )}
        <div className="flex flex-col items-center absolute w-16 -left-8 print:hidden">
          <div className="p-1 bg-white">
            <div className="p-1.5 rounded-full bg-slate-800 text-white">
              <MdOutlineCalendarToday />
            </div>
          </div>
          <div className="text-sm text-gray-600 leading-none bg-white pb-1">
            Week {weekNumber}
          </div>
        </div>
        {/* Print View Header: start */}
        <div className="hidden print:block p-2 border border-b-0 border-gray-500 bg-gray-100">
          Week {weekNumber}
        </div>
        {/* Print View Header: end */}
        <div className="flex flex-row print:block print:border border-gray-500">
          <div
            data-testid="week-details"
            className="flex-1 pl-4 pt-1 print:block"
            style={weekStyle}
          >
            <div
              data-testid="week-learning-objective"
              className="font-semibold group-one-hover:text-sky-700"
            >
              {title ? title : `Week ${weekNumber}`}
            </div>
            {threads && threads.length > 0 && (
              <div
                data-testid="week-threads"
                className="flex flex-row flex-wrap my-1"
              >
                {threads.map(({ id, label }) => (
                  <Tag key={id} id={id} size="small" className="mr-1">
                    {label}
                  </Tag>
                ))}
              </div>
            )}
            {description && (
              <>
                <div className="text-sm italic text-gray-500 whitespace-pre-wrap">
                  {description}
                </div>
              </>
            )}
            <ObjectivesList objectives={objectives} />
          </div>
          {dragEnabled && dragHandleProps && (
            <div data-testid="week-actions" className="print:hidden">
              <div className="p-1">
                <div
                  {...dragHandleProps}
                  className={classNames(
                    "p-1.5 rounded-full",
                    "hover:text-gray-900 hover:bg-gray-100",
                    isDragging
                      ? "text-gray-900"
                      : "text-gray-500 transition-opacity opacity-0 group-one-hover:opacity-100"
                  )}
                >
                  <MdDragHandle />
                </div>
              </div>
            </div>
          )}
        </div>
      </li>
    );
  }
);

interface WeekSummaryProps {
  id: string;
  index: number;
  termNumber: number;
  week: WeekDataResolved;
  filteredSubjects?: Subject[];
  dragEnabled?: boolean;
}

const WeekSummary = function WeekSummary({
  id,
  index,
  termNumber,
  week,
  filteredSubjects,
  dragEnabled = false,
}: WeekSummaryProps) {
  const weekObjectives = useMemo<Objective[]>(
    () =>
      filteredSubjects && filteredSubjects.length > 0
        ? week.resolvedObjectives.filter(
            (objective) =>
              _.findIndex(filteredSubjects, {
                id: objective.subject,
              }) > -1
          )
        : week.resolvedObjectives,
    [week.resolvedObjectives, filteredSubjects]
  );
  if (dragEnabled) {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <WeekSummaryView
            title={week.title}
            description={week.description}
            threads={week.resolvedThreads}
            objectives={weekObjectives}
            termNumber={termNumber}
            weekNumber={index + 1}
            dragEnabled={true}
            dragHandleProps={provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            {...provided.draggableProps}
            ref={provided.innerRef}
          />
        )}
      </Draggable>
    );
  }
  // Otherwise
  return (
    <WeekSummaryView
      title={week.title}
      description={week.description}
      threads={week.resolvedThreads}
      objectives={weekObjectives}
      termNumber={termNumber}
      weekNumber={index + 1}
      dragEnabled={false}
      isDragging={false}
    />
  );
};

export default WeekSummary;
