import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";

export interface TextBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  labelDisplay?: "label" | "title";
  variant?: "default" | "inline";
  row?: boolean;
  spacing?: 0 | 1 | 2;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  rightAdornment?: React.ReactNode;
  required?: boolean;
  error?: FieldError | string;
}

const TextBox = React.forwardRef<HTMLInputElement, TextBoxProps>(
  function TextBox(
    {
      name,
      label,
      labelDisplay = "label",
      variant = "default",
      row = false,
      spacing = 2,
      disabled,
      className,
      labelClassName,
      required = false,
      error,
      ...props
    }: TextBoxProps,
    ref
  ) {
    const input = (
      <input
        name={name}
        id={name}
        className={classNames(
          "block p-1 px-2 border rounded outline-none text-base text-black font-normal",
          row ? "inline-block ml-2" : "block w-full",
          {
            "mt-0.5": labelDisplay === "label",
            "bg-gray-100": disabled,
            "focus:shadow transition-colors": !disabled,
            "border-red-300 focus:border-red-600 focus:shadow-red-200":
              !disabled && error,
            "border-gray-300 hover:border-sky-300 focus:border-sky-600 focus:shadow-sky-200":
              !disabled && !error,
          },
          className
        )}
        disabled={disabled}
        title={labelDisplay === "title" ? label : undefined}
        aria-label={labelDisplay !== "label" ? label : undefined}
        required={required}
        {...props}
        ref={ref}
      />
    );
    if (labelDisplay === "label") {
      return (
        <label
          className={classNames(
            "text-sm font-semibold text-gray-800",
            row ? "inline-block" : "block",
            labelClassName,
            {
              "mb-2": spacing === 2,
              "mb-1": spacing === 1,
              "text-gray-500": disabled,
            }
          )}
        >
          {label}
          {required ? <sup>*</sup> : null}
          {input}
        </label>
      );
    }
    // Otherwise
    return input;
  }
);

export default TextBox;
