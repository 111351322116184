import React, { useState } from "react";
import classNames from "classnames";
import Page from "components/Page";
import Panel from "components/Panel/Panel";
import Filters from "components/Filters";
import { FilterOptions, FilterValue } from "components/Filters/types";
import years, { YearGroup } from "services/years";
import { useAcademicYear } from "services/providers/AcademicYearProvider";
import { useAllStudents } from "services/hooks/useStudents";
import ImportStudentsButton from "pages/Dashboard/pages/Students/components/ImportStudentsButton";
import { useNavigate } from "react-router-dom";
import ExportStudentsButton from "../components/ExportStudentButton";
import AddStudentButton from "../components/AddStudentButton";
import { ResolvedStudentDataWithYearGroup } from "types";

const studentTextSearch = (
  search: string,
  { id, name, gender, flags }: ResolvedStudentDataWithYearGroup
) => {
  // Search in id
  if (id && id.toLowerCase().indexOf(search) > -1) {
    return true;
  }
  // Search in name
  if (name && name.toLowerCase().indexOf(search) > -1) {
    return true;
  }
  // Search in gender
  if (gender && gender.toLowerCase().indexOf(search) > -1) {
    return true;
  }
  // Search in flags
  if (flags?.find((flag) => flag.toLowerCase().indexOf(search) > -1)) {
    return true;
  }
  // Otherwise
  return false;
};

const buildStudentMatcher = (filters: FilterValue[], search: string) => {
  const searchTerm = search.trim().toLowerCase();
  // Build the appropriate filter matchers
  if (filters.length > 0) {
    const yearGroups: YearGroup[] = filters
      .filter(({ type }) => type === "year")
      .reduce<YearGroup[]>(
        (current, { id }) => [...current, id as YearGroup],
        []
      );

    return (student: ResolvedStudentDataWithYearGroup) => {
      if (yearGroups.length > 0 && !yearGroups.includes(student.yearGroup)) {
        return false;
      }
      // Otherwise
      if (searchTerm) {
        return studentTextSearch(searchTerm, student);
      }
      // Otherwise
      return true;
    };
  }
  // Otherwise
  if (searchTerm) {
    // Return the text matcher
    return (student: ResolvedStudentDataWithYearGroup) =>
      studentTextSearch(searchTerm, student);
  }
  // Otherwise, match all
  return () => true;
};

interface AllStudentsPageProps {
  className?: string;
}

const studentFilters: FilterOptions[] = [
  {
    type: "year",
    label: "Year",
    labelPlural: "Years",
    options: years,
  },
];

const AllStudentsPage = function AllStudentsPage({
  className,
}: AllStudentsPageProps) {
  // Get students for the current academic year (rather than the selected year)
  const { current: currentAcademicYear } = useAcademicYear();
  const [students] = useAllStudents(currentAcademicYear.value);
  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const studentMatcher = buildStudentMatcher(filters, search);
  const filteredStudents = students.filter((student) =>
    studentMatcher(student)
  );

  return (
    <Page title="Students" className={classNames(className, "p-3")}>
      <div className="flex flex-row items-center pb-2">
        <div className="flex-1">
          <Filters
            filters={studentFilters}
            value={filters}
            onChange={setFilters}
            search={search}
            onSearchChange={setSearch}
          />
        </div>
        <ExportStudentsButton students={filteredStudents} className="mr-1" />
        <ImportStudentsButton className="mr-1" />
        <AddStudentButton />
        {/*<Button onClick={() => navigate("new")}>
          <MdAdd className="mr-0.5" /> New Student
        </Button>*/}
      </div>
      <Panel className="h-full overflow-auto">
        <table className="border-separate border-spacing-0">
          <thead className="sticky top-0 shadow-sm">
            <tr className="bg-gray-50 text-left">
              <th className="p-2 font-normal text-black border-b border-gray-400">
                Name
              </th>
              <th className="p-2 font-normal text-black border-b border-gray-400 w-20 text-center">
                Year
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents.map(({ name, id, entryYear }) => (
              <tr
                key={id}
                className="bg-white hover:bg-sky-100 cursor-pointer"
                onClick={() => navigate(`${entryYear}/${id}`)}
              >
                <td className="p-2 text-black border-b border-gray-300">
                  <div>{name}</div>
                  <div className="text-xs text-gray-600">{id}</div>
                </td>
                <td className="p-2 text-gray-600 border-b border-gray-300 text-center">
                  {currentAcademicYear.value - entryYear}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel>
    </Page>
  );
};

export default AllStudentsPage;
