import React, { useRef, useState } from "react";
import { useCurrentUser } from "services/user";
import Button from "components/Button";
import readSheet from "services/utlities/readSheet";
import { ResolvedStudentData, StudentData } from "types";
import { DateTime } from "luxon";
import { firestore } from "services/firebaseApp";
import { writeBatch, doc } from "@firebase/firestore";

interface ImportedStudent extends Record<string, any> {
  upn: string;
  firstName: string;
  lastName: string;
  class: string;
  currentYear: string;
  entryYear: string;
  dateOfBirth: string;
  gender: string;
  sen: string;
  ethnicity: string;
}

interface ImportData {
  [year: string]: {
    [upn: string]: StudentData;
  };
}

const resolveEntryYear = (
  importedStudent: Partial<ImportedStudent>
): number | null => {
  // If a date of birth is provided
  if (importedStudent.dateOfBirth) {
    // Try and parse it
    const dateOfBirth = DateTime.fromISO(importedStudent.dateOfBirth);
    // And if it's valid
    if (dateOfBirth.isValid) {
      // Determine the entry year from it
      if (dateOfBirth.month < 9) {
        return dateOfBirth.year + 4;
      }
      // Otherwise
      return dateOfBirth.year + 5;
    }
  }
  // Otherwise
  return null;
};

const resolveName = (
  importedStudent: Partial<ImportedStudent>
): string | null => {
  let name = importedStudent.firstName;
  if (importedStudent.lastName) {
    name += ` ${importedStudent.lastName}`;
  }
  return name || null;
};

const resolveGender = (
  importedStudent: Partial<ImportedStudent>
): "male" | "female" | null => {
  const gender = `${importedStudent.gender}`.toLowerCase();
  if (gender === "male") {
    return "male";
  }
  if (gender === "female") {
    return "female";
  }
  // Otherwise
  return null;
};

const resolveIsSummerBorn = (importedStudent: Partial<ImportedStudent>) => {
  // If a date of birth is provided
  if (importedStudent.dateOfBirth) {
    // Try and parse it
    const dateOfBirth = DateTime.fromISO(importedStudent.dateOfBirth);
    // And if it's valid and in the summer
    if (dateOfBirth.isValid && dateOfBirth.month < 9 && dateOfBirth.month > 3) {
      return true;
    }
  }
  // Otherwise
  return false;
};

const resolveFlags = (
  importedStudent: Partial<ImportedStudent>
): string[] | undefined => {
  const flags: string[] = [];
  // Is this student SEN?
  if (importedStudent.sen && `${importedStudent.sen}`.toLowerCase() !== "n") {
    flags.push("SEN");
  }
  // Is this student pakistani?
  if (`${importedStudent.ethnicity}`.toLowerCase().indexOf("pakistani") > -1) {
    flags.push("Pakistani");
  }
  // Is this student summer born?
  if (resolveIsSummerBorn(importedStudent)) {
    flags.push("Summer Born");
  }
  return flags.length ? flags : undefined;
};

const resolveClass = (importedStudent: Partial<ImportedStudent>) => {
  if (importedStudent.class && importedStudent.currentYear) {
    return { [importedStudent.currentYear]: importedStudent.class };
  }
};

const resolveImportedStudent = (
  importedStudent: Partial<ImportedStudent>
): ResolvedStudentData | null => {
  // Require a UPN
  if (!importedStudent.upn) {
    return null;
  }
  // Require a name
  const name = resolveName(importedStudent);
  if (!name) {
    return null;
  }
  // Require an entry year
  const entryYear = resolveEntryYear(importedStudent);
  if (!entryYear) {
    return null;
  }
  // Resolve the students gender
  const gender = resolveGender(importedStudent);
  // Resolve the students flags
  const flags = resolveFlags(importedStudent);
  // Resolve the students class
  const classes = resolveClass(importedStudent);
  // Otherwise
  return {
    id: importedStudent.upn,
    name,
    entryYear,
    ...(gender !== null && { gender }),
    ...(flags ? { flags } : {}),
    ...(classes ? { classes } : {}),
  };
};

const resolveImportedStudents = (
  importedStudents: Partial<ImportedStudent>[]
): ResolvedStudentData[] => {
  return importedStudents
    .map(resolveImportedStudent)
    .filter((student) => student !== null) as ResolvedStudentData[];
};

interface ImportStudentsButtonProps {
  className?: string;
}

const ImportStudentsButton = function ImportStudentsButton({
  className,
}: ImportStudentsButtonProps) {
  const { school } = useCurrentUser();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [importing, setImporting] = useState(false);
  const importFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImporting(true);
      const result = await readSheet<ImportedStudent>(event.target.files[0], {
        upn: ["UPN", "UID", "ID"],
        firstName: ["First Name", "Name", "Full Name", "Full Known Name"],
        lastName: ["Last Name", "Surname"],
        class: ["Class", "Class Name"],
        currentYear: ["Year", "Current Year"],
        dateOfBirth: ["Date of Birth", "DOB"],
        entryYear: ["Entry Year", "Year"],
        gender: ["Gender"],
        sen: ["Current SEN Stage"],
        ethnicity: ["Ethnicity"],
      });
      console.log("resolved data: ", resolveImportedStudents(result));
      const importData = resolveImportedStudents(result).reduce<ImportData>(
        (current, student) => ({
          ...current,
          [student.entryYear]: {
            ...current[student.entryYear],
            [student.id]: {
              name: student.name,
              ...(student.gender && { gender: student.gender }),
              ...(student.flags && { flags: student.flags }),
              ...(student.classes && { classes: student.classes }),
            },
          },
        }),
        {}
      );
      // Prepare the batched writes
      const batch = writeBatch(firestore);
      Object.entries(importData).forEach(([year, students]) => {
        const studentsRef = doc(
          firestore,
          `schools/${school}/students/${year}`
        );
        batch.set(studentsRef, { students }, { merge: true });
      });
      // Write the batch
      await batch.commit();
      // Reset the status
      setImporting(false);
    }
  };
  const openFileSelect = () => {
    if (fileInput.current) {
      fileInput.current?.click();
    }
  };
  return (
    <label>
      <Button
        onClick={openFileSelect}
        loading={importing}
        className={className}
        variant="secondary"
      >
        Import
      </Button>
      <input type="file" onChange={importFile} hidden ref={fileInput} />
    </label>
  );
};

export default ImportStudentsButton;
