import { useThreads } from "services/hooks/useThreads";
import { useCallback } from "react";
import _ from "lodash";
import { Thread } from "types";

export const useResolveThreads = () => {
  const [threads] = useThreads();
  return useCallback(
    (ids: string[]) =>
      ids
        .map((id) => _.find(threads, { id }))
        .filter((value) => value !== undefined) as Thread[],
    [threads]
  );
};
