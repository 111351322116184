import buildWeekMatcher from "services/utlities/buildWeekMatcher";
import { FilteredTermDataResolved, TermDataResolved } from "types";
import { FilterValue } from "components/Filters/types";

const buildFilterTerm = (filters?: FilterValue[], search?: string) => {
  const weekMatcher = buildWeekMatcher(filters, search);
  return (term: TermDataResolved): FilteredTermDataResolved => ({
    ...term,
    filteredWeeks: term.orderedWeeks.filter(({ week }) => weekMatcher(week)),
  });
};

export default buildFilterTerm;
