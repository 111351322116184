import React, { createContext, useContext, useMemo } from "react";
import { Objective, RawObjective } from "types";
import { useSubjects } from "services/hooks";
import _ from "lodash";
const objectivesContext = createContext<[Objective[] | null, boolean, boolean]>(
  [null, false, false]
);
const Provider = objectivesContext.Provider;

interface ObjectivesProviderProps {
  children: React.ReactNode;
}
const ObjectivesProvider = ({ children }: ObjectivesProviderProps) => {
  const [subjects, loading, error] = useSubjects();
  const result = useMemo<[Objective[] | null, boolean, boolean]>(() => {
    if (loading) {
      return [null, true, false];
    }
    // Otherwise
    if (error || !subjects) {
      return [null, false, true];
    }
    // Otherwise
    const objectives = subjects.reduce<Objective[]>(
      (currentObjectives, subject) => {
        const resolveObjective = (objective: string | RawObjective) => {
          if (_.isString(objective)) {
            return {
              label: objective,
              group: null,
              groupName: null,
            };
          }
          // Otherwise check if the objective has a group
          if (objective.group) {
            const group = subject.groups && subject.groups[objective.group];
            if (group) {
              return {
                label: objective.label,
                group: objective.group,
                groupName: group.label,
              };
            }
          }
          // Otherwise no group
          return {
            label: objective.label,
            group: null,
            groupName: null,
          };
        };
        return [
          ...currentObjectives,
          ...Object.entries(subject.objectives || {}).reduce<Objective[]>(
            (subjectObjectives, [year, objectives]) => [
              ...subjectObjectives,
              ...Object.entries(objectives).map(([id, objective]) => ({
                id,
                ...resolveObjective(objective),
                subject: subject.id,
                subjectName: subject.name,
                year: year.substring(5),
              })),
            ],
            []
          ),
        ];
      },
      []
    );
    return [objectives, false, false];
  }, [subjects, loading, error]);
  return <Provider value={result}>{children}</Provider>;
};

const useObjectives = () => useContext(objectivesContext);

export { ObjectivesProvider, useObjectives };
