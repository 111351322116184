import React from "react";
import classNames from "classnames";

interface TableProps {
  children: React.ReactNode;
  className?: string;
}

const Table = function Table({ className, children }: TableProps) {
  return (
    <table
      className={classNames(
        "border-separate border-spacing-0",
        "print:border-collapse",
        className
      )}
    >
      {children}
    </table>
  );
};

export default Table;
