import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import ProgressBar from "components/ProgressBar";
import Dialog from "components/Dialog";
import PanelHeader from "components/Panel/PanelHeader";
import PanelBody from "components/Panel/PanelBody";
import classNames from "classnames";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import PanelFooter from "components/Panel/PanelFooter";
import Button from "components/Button";
import ProgressCircle from "components/ProgressCircle";
import { Objective, TermDataResolved } from "types";
import { useTermStats, useObjectives, CompletedObjectives } from "services";
import Toggle from "components/Controls/Toggle";
import { formatCompletedWeeks } from "services/utlities/formatCompletedWeeks";

interface ObjectiveWithStatus extends Objective {
  status: "complete" | "incomplete";
}

interface ObjectivesListProps {
  objectives: ObjectiveWithStatus[];
  completedObjectiveDetails: CompletedObjectives;
  includeCompleted: boolean;
}

const ObjectivesList = ({
  objectives,
  completedObjectiveDetails,
  includeCompleted = true,
}: ObjectivesListProps) => {
  return (
    <>
      {objectives
        .filter(({ status }) =>
          includeCompleted ? true : status === "incomplete"
        )
        .map(({ id, label, status }) => (
          <div
            key={id}
            className={classNames("flex flex-row items-center p-1", {
              "text-gray-500": status === "incomplete",
            })}
          >
            <div>
              {status === "complete" ? (
                <ProgressCircle progress={1} />
              ) : (
                <ProgressCircle progress={0} />
              )}
            </div>
            <span className="px-2 flex-1">
              {label} {formatCompletedWeeks(completedObjectiveDetails[id])}
            </span>
          </div>
        ))}
    </>
  );
};

interface ObjectivesSectionProps {
  header: string;
  objectives: ObjectiveWithStatus[];
  includeCompleted: boolean;
  children: React.ReactNode;
  subsection?: boolean;
  className?: string;
}

const ObjectivesSection = ({
  header,
  objectives,
  includeCompleted,
  children,
  subsection = false,
  className,
}: ObjectivesSectionProps) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded((current) => !current), []);
  const complete = useMemo(
    () => objectives.filter(({ status }) => status === "complete"),
    [objectives]
  );
  if (!includeCompleted && complete.length === objectives.length) {
    return null;
  }
  return (
    <div
      className={classNames(className, {
        "print:border print:border-gray-500": !subsection,
        "print:hidden": !expanded && !subsection,
      })}
    >
      <div
        className={classNames(
          "flex flex-row items-center border-b border-gray-300 h-12 sticky",
          "cursor-pointer",
          "print:static print:block print:h-auto print:p-1",
          subsection
            ? "bg-gray-50 top-12 pl-4 print:border-0 print:px-2"
            : "bg-white top-0 z-10 print:bg-gray-100 print:border-gray-500 print:border-b"
        )}
        onClick={toggle}
      >
        <div
          className={classNames("px-2 print:float-left print:p-1", {
            "print:hidden": subsection,
          })}
        >
          <ProgressCircle
            progress={complete.length / objectives.length}
            size="large"
          />
        </div>
        <div
          className={classNames(
            "flex-1 flex flex-row print:inline-block",
            subsection
              ? "print:font-semibold print:text-sky-700"
              : "font-semibold text-sky-700"
          )}
        >
          {header}
          <div
            className={classNames(
              "text-gray-700 font-normal text-sm hidden",
              subsection ? "print:inline-block pl-1" : "print:block"
            )}
          >
            {subsection && "- "}
            {complete.length}/{objectives.length} Covered Objectives
          </div>
        </div>
        <div className="px-2 print:hidden">
          {complete.length}/{objectives.length}
        </div>
        <div className="px-2 print:hidden">
          {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      <div
        className={classNames("print:bg-white print:border-0", {
          "border-b border-gray-300 bg-gray-50 pl-5 print:pl-1": subsection,
          "print:mb-2": !subsection,
          "hidden print:block": !expanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

interface ObjectiveYearStatsProps {
  year: string;
  objectives: ObjectiveWithStatus[];
  completedObjectiveDetails: CompletedObjectives;
  includeCompleted: boolean;
}

const ObjectiveYearStats = ({
  year,
  objectives,
  includeCompleted,
  completedObjectiveDetails,
}: ObjectiveYearStatsProps) => {
  return (
    <ObjectivesSection
      header={`Year ${year}`}
      objectives={objectives}
      includeCompleted={includeCompleted}
      subsection
    >
      <ObjectivesList
        objectives={objectives}
        includeCompleted={includeCompleted}
        completedObjectiveDetails={completedObjectiveDetails}
      />
    </ObjectivesSection>
  );
};

interface ObjectiveSubjectStatsProps {
  subject: string;
  year: string;
  objectives: ObjectiveWithStatus[];
  completedObjectiveDetails: CompletedObjectives;
  includeCompleted: boolean;
}

const ObjectiveSubjectStats = ({
  subject,
  year,
  objectives,
  completedObjectiveDetails,
  includeCompleted,
}: ObjectiveSubjectStatsProps) => {
  return (
    <ObjectivesSection
      header={subject}
      objectives={objectives}
      includeCompleted={includeCompleted}
      className="break-after-page"
    >
      {year === "all" ? (
        Object.entries(_.groupBy(objectives, "year")).map(
          ([year, yearObjectives]) => (
            <ObjectiveYearStats
              key={year}
              year={year}
              objectives={yearObjectives}
              includeCompleted={includeCompleted}
              completedObjectiveDetails={completedObjectiveDetails}
            />
          )
        )
      ) : (
        <ObjectivesList
          objectives={objectives}
          includeCompleted={includeCompleted}
          completedObjectiveDetails={completedObjectiveDetails}
        />
      )}
    </ObjectivesSection>
  );
};

interface ObjectivesStatsDialogProps {
  allObjectives: Objective[];
  completedObjectives: string[];
  completedObjectiveDetails: CompletedObjectives;
  year: string;
  onClose: () => unknown;
}

const ObjectivesStatsDialog = ({
  allObjectives,
  completedObjectives,
  completedObjectiveDetails,
  year,
  onClose,
}: ObjectivesStatsDialogProps) => {
  const [includeCompleted, setIncludeCompleted] = useState(true);
  const subjectObjectives = useMemo(() => {
    const groupedBySubject = _.groupBy(
      allObjectives.map<ObjectiveWithStatus>(({ id, ...others }) => ({
        id,
        ...others,
        status:
          completedObjectives.indexOf(id) > -1 ? "complete" : "incomplete",
      })),
      "subjectName"
    );
    return Object.entries(groupedBySubject).map(([subject, objectives]) => ({
      subject,
      objectives: _.orderBy(objectives, ["status", "objective"]),
    }));
  }, [allObjectives, completedObjectives]);
  return (
    <Dialog
      className="dialog"
      pageTitle={`${year === "all" ? "All Years" : `Year ${year}`} - ${
        includeCompleted ? "Objective Coverage" : "Uncovered Objectives"
      }`}
    >
      <PanelHeader className="print:hidden">
        <div className="flex-1">Year {year} - Objectives</div>
        <Toggle
          name="include-complete"
          label="Include Covered"
          checked={includeCompleted}
          onCheck={setIncludeCompleted}
          className="font-normal text-gray-700"
        />
      </PanelHeader>
      <PanelBody className="overflow-y-scroll print:overflow-visible">
        {subjectObjectives.map(({ subject, objectives }) => (
          <ObjectiveSubjectStats
            key={subject}
            subject={subject}
            year={year}
            objectives={objectives}
            includeCompleted={includeCompleted}
            completedObjectiveDetails={completedObjectiveDetails}
          />
        ))}
      </PanelBody>
      <PanelFooter className="print:hidden">
        <Button onClick={onClose}>Close</Button>
      </PanelFooter>
    </Dialog>
  );
};

interface TermStatsProps {
  terms: TermDataResolved[];
  year: string;
}

const TermStats = function TermStats({ terms, year }: TermStatsProps) {
  const [objectives] = useObjectives();
  const allObjectives = useMemo(() => {
    if (objectives) {
      if (year !== "all") {
        return objectives.filter(
          ({ year: objectiveYear }) => objectiveYear === year
        );
      }
      // Otherwise
      return objectives;
    }
    // Otherwise
    return [];
  }, [objectives, year]);
  const { completedObjectives, completedObjectiveDetails, activeThreads } =
    useTermStats(terms);
  const percentageCompleted = useMemo(
    () => completedObjectives.length / allObjectives.length,
    [allObjectives.length, completedObjectives.length]
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);
  return (
    <>
      <div className="flex flex-row mb-3 print:hidden">
        <div
          className="w-1/2 rounded-lg bg-white border border-gray-300 p-2 mr-1 cursor-pointer group-one"
          onClick={openDialog}
        >
          <div className="text-gray-600 mb-2">Objectives Covered</div>
          <ProgressBar progress={percentageCompleted} className="mb-1" />
          <div className="text-3xl text-gray-900">
            {completedObjectives.length}/{allObjectives.length}
          </div>
          <div className="text-right text-gray-900 group-one-hover:text-sky-700">
            View Details
          </div>
        </div>
        <div className="w-1/4 rounded-lg bg-white border border-gray-300 p-2 mx-1">
          <div className="text-gray-600 mb-2">Threads</div>
          <div className="text-3xl text-gray-900">{activeThreads.length}</div>
        </div>
        <div className="w-1/4 rounded-lg bg-white border border-gray-300 p-2 ml-1">
          <div className="text-gray-600 mb-2">Weeks</div>
          <div className="text-3xl text-gray-900">4</div>
        </div>
      </div>
      {dialogOpen && (
        <ObjectivesStatsDialog
          allObjectives={allObjectives}
          completedObjectives={completedObjectives}
          completedObjectiveDetails={completedObjectiveDetails}
          year={year}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

export default TermStats;
