import Page from "components/Page";
import { Subject } from "types";
import React, { useMemo } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useCachedSchoolCollection } from "services";
import {
  SideBar,
  SideBarContainer,
  SideBarContent,
  SideBarItem,
} from "components/SideBar";
import SkeletonSideBarItems from "components/Skeleton/SkeletonSideBarItems";
import _ from "lodash";
import SubjectObjectives from "./SubjectObjectives";

interface ObjectivesPageProps {
  loading: boolean;
  subjects?: Subject[];
  subject?: Subject;
}

const ObjectivesPage = ({
  subjects,
  subject,
  loading,
}: ObjectivesPageProps) => {
  const navigate = useNavigate();
  return (
    <Page title="Objectives">
      <SideBarContainer>
        <SideBar>
          {subjects && !loading ? (
            subjects.map(({ id, name }) => (
              <SideBarItem
                key={id}
                onClick={() => {
                  navigate(`/app/objectives/${id}`, { replace: true });
                }}
                selected={id === subject?.id}
              >
                {name}
              </SideBarItem>
            ))
          ) : (
            <SkeletonSideBarItems />
          )}
        </SideBar>
        <SideBarContent className="p-3">
          {subject && !loading && <SubjectObjectives subject={subject} />}
        </SideBarContent>
      </SideBarContainer>
    </Page>
  );
};

const ObjectivesPageWrapper = () => {
  const [subjects, loading] = useCachedSchoolCollection<Subject>("subjects");
  const sortedSubjects = useMemo(() => {
    if (!subjects || loading) {
      return [];
    }
    // Otherwise
    return _.sortBy(subjects, [({ name }) => name.toLowerCase()]);
  }, [subjects, loading]);
  const defaultSubjectId = sortedSubjects[0] && sortedSubjects[0].id;
  console.log("subjects: ", subjects);
  if (!subjects || loading) {
    return <ObjectivesPage loading={loading} subjects={subjects} />;
  } else if (subjects.length === 0) {
    return <div>NO SUBJECTS</div>;
  }
  // Otherwise
  return (
    <Routes>
      {subjects.map((subject) => (
        <Route
          key={subject.id}
          path={`/${subject.id}`}
          element={
            <ObjectivesPage
              loading={loading}
              subjects={sortedSubjects}
              subject={subject}
            />
          }
        />
      ))}
      <Route
        path="*"
        element={<Navigate to={defaultSubjectId} replace={true} />}
      />
    </Routes>
  );
};

export default ObjectivesPageWrapper;
