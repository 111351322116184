import { useMemo } from "react";
import _ from "lodash";
import { TermDataResolved } from "types";

export interface TermWeek {
  termNumber: number;
  weekNumber: number;
}

export interface CompletedObjectives {
  [objectiveId: string]: TermWeek[];
}

const useTermStats = function useTermStats(terms: TermDataResolved[]) {
  const completedObjectives = useMemo(() => {
    return _.uniq(
      terms?.reduce<string[]>(
        (current, { weeks }) => [
          ...current,
          ...Object.entries(weeks).reduce<string[]>(
            (termObjectives, [, { objectives: weekObjectives }]) => [
              ...termObjectives,
              ...weekObjectives,
            ],
            []
          ),
        ],
        []
      )
    );
  }, [terms]);
  const completedObjectiveDetails = useMemo(() => {
    return terms?.reduce<CompletedObjectives>(
      (current, { weeks, order, termNumber }) => {
        const orderedWeeks = order.map((weekId) => weeks[weekId]);
        orderedWeeks.forEach(({ objectives }, weekIndex) => {
          objectives.forEach((objectiveId: string) => {
            if (!current[objectiveId]) {
              current[objectiveId] = [];
            }
            current[objectiveId].push({
              termNumber: termNumber,
              weekNumber: weekIndex + 1,
            });
          });
        });
        return current;
      },
      {}
    );
  }, [terms]);
  const activeThreads = useMemo(
    () =>
      _.uniq(
        terms?.reduce<string[]>(
          (current, { weeks }) => [
            ...current,
            ...Object.entries(weeks).reduce<string[]>(
              (termThreads, [, { threads: weekThreads }]) => [
                ...termThreads,
                ...weekThreads,
              ],
              []
            ),
          ],
          []
        )
      ),
    [terms]
  );
  return useMemo(
    () => ({
      completedObjectives,
      completedObjectiveDetails,
      activeThreads,
    }),
    [completedObjectives, completedObjectiveDetails, activeThreads]
  );
};

export { useTermStats };
