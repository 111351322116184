import React, { createContext, useState } from "react";

type DialogResult<T> = Parameters<
  Pick<{ onClose: () => unknown } & T, "onClose">["onClose"]
>[0];
type DialogProps<T> = Omit<T, "onClose">;

interface DialogContext {
  openDialog: <T, P extends DialogProps<T>>(
    type: React.ComponentType<T>,
    props: P
  ) => Promise<DialogResult<T>>;
}

const dialogContext = createContext<DialogContext>({
  openDialog: () => {
    throw new Error(
      "No dialog context found, ensure a dialog context is available"
    );
  },
});

const Provider = dialogContext.Provider;

interface DialogProviderProps {
  children: React.ReactNode;
}

const DialogProvider = function DialogProvider({
  children,
}: DialogProviderProps) {
  const [dialog, setDialog] = useState<JSX.Element | null>(null);
  const context: DialogContext = {
    openDialog: <T, P extends DialogProps<T>>(
      Type: React.ComponentType<T>,
      props: P
    ): Promise<DialogResult<T>> =>
      new Promise<DialogResult<T>>((resolve) => {
        const onClose = (result: DialogResult<T>) => {
          setDialog(null);
          resolve(result);
        };
        const allProps = {
          ...props,
          onClose,
        } as unknown as T;
        setDialog(<Type {...allProps} />);
      }),
  };
  return (
    <Provider value={context}>
      {children}
      {dialog}
    </Provider>
  );
};

const useOpenDialog = () => {
  const context = React.useContext(dialogContext);
  return context.openDialog;
};

export { DialogProvider, useOpenDialog };
