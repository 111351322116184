const termNames = ["Autumn", "Spring", "Summer"];

const getTermName = (termNumber: number, short: boolean = false): string => {
  if (termNumber > 0 && termNumber <= termNames.length) {
    if (short) {
      return termNames[termNumber - 1];
    }
    // Otherwise
    return `${termNames[termNumber - 1]} Term`;
  }
  if (short) {
    return `${termNumber}`;
  }
  // Otherwise
  return `Term ${termNumber}`;
};

export default getTermName;
