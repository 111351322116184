export type YearGroup = "1" | "2" | "3" | "4" | "5" | "6";

export interface RawYear {
  id: YearGroup;
  label: string;
}

export const years: RawYear[] = [
  { id: "1", label: "Year 1" },
  { id: "2", label: "Year 2" },
  { id: "3", label: "Year 3" },
  { id: "4", label: "Year 4" },
  { id: "5", label: "Year 5" },
  { id: "6", label: "Year 6" },
];

export const toYearGroup = (year: number): YearGroup => {
  if (year < 1 || year > 6) {
    throw new Error(`Invalid year ${year}`);
  }
  return year.toString() as YearGroup;
};

export default years;
