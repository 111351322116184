import React from "react";
import { Route, Routes } from "react-router-dom";
import AllStudentsPage from "./pages/AllStudentsPage";
import StudentPage from "./pages/StudentPage";

const StudentsPage = function StudentsPage() {
  return (
    <>
      <AllStudentsPage />
      <Routes>
        <Route path="/:studentId" element={<StudentPage />} />
        <Route path="/:entryYear/:studentId" element={<StudentPage />} />
      </Routes>
    </>
  );
};

export default StudentsPage;
